export const currencySymbols = {
    'US': '$',  // United States Dollar
    'GB': '£',  // British Pound Sterling
    'EU': '€',  // Euro
    'IN': '₹',  // Indian Rupee
    'PK': '₨',  // Pakistani Rupee
    'JP': '¥',  // Japanese Yen
    'CN': '¥',  // Chinese Yuan
    'CA': '$',  // Canadian Dollar
    'AU': '$',
  };
  
  export const getCurrencySymbol = (countryCode) => {
    return currencySymbols[countryCode] || '$'; // Default to USD if country code not found
  };
  