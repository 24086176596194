import React from 'react'
import styles from './mobileNotification.module.css'

const MobileNotification =()=>{
    return(
        <div className={styles.container}>
   {/* <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div> */}
            {/* <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div> */}
            {/* <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div> */}
            {/* <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div>
            <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div>
            <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div>
            <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div>
            <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div>
            <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div> <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div>
            <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div> <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div> <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div> <div className={styles.notiItem}  >
            <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
            <div className={styles.noticontent} >
                <div>James liked your post.</div>
                <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
            </div>
            </div> */}
        </div>
    )
}


export default MobileNotification;