import React,{useState,useEffect} from 'react';
import  styles from './addpost.module.css'
import ReactQuill from 'react-quill';
import { useDispatch,useSelector } from 'react-redux';
import { addLearn } from '../../Redux/learnSlice';
import { MdOutlineSlowMotionVideo } from "react-icons/md";

const Learnpost = ()=>{
 

    const dispatch = useDispatch();
    const [content,setContent]=useState("")
    const [file, setFile] = useState()
    const [preview, setPreview] = useState()
    const [text,setText]=useState("")
      
    const loading = useSelector((state) => state.jobs.loading);
    const isSuccess = useSelector((state) => state.jobs.isSuccess);
   
useEffect(() => {
    if (!file) {
        setPreview(undefined)
        return
    }

    const objectUrl = URL.createObjectURL(file)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
}, [file])

const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
        setFile(undefined)
        return
    }

    // I've kept this example simple by using the first image instead of multiple
    setFile(e.target.files[0])
}






const handlePost = async() => {
  
    // Gather data from the component
     
    if (!text || !file) {
      alert('Please enter text and select a file before posting.');
      return;
    }
    
    const learnData = {
        text,
        file
      //   file: {
      //     originalname: file.name,
      //     location: file.location,
      //     // Add other necessary fields
      // },
        // Add other necessary fields
      };
  
    
  
      console.log(learnData); 
  
      // Dispatch the addArticle action
      await dispatch(addLearn(learnData,));
  
      setText("");
      setFile("");
  
    };
  
    useEffect(() => {
      // Check if the post was successful and show alert
      if (isSuccess) {
        alert('Post added successfully!');
        // You can also reset the form or perform other actions after successful post
      }
      console.log('isSuccess changed:', isSuccess);
    }, [isSuccess]);
  
  

   
   
   
    return(
        <div>
        {file ? (
          <div className={styles.imgPrev}>
            <img src={preview} alt="Preview" />
          </div>
        ) : (
          ''
        )}
  
        <ReactQuill
          value={text}
          onChange={(value) => setText(value)}
          style={{ height: '300px', margin: '10px', marginBottom: '50px' }}
        />
  
        {file ? (
          ''
        ) : (
          <div className={styles.addicon}>
                 <MdOutlineSlowMotionVideo className={styles.FiImage}/>
            <label htmlFor="img">Upload</label>
       
            <input
              onChange={onSelectFile}
              style={{ display: 'none' }}
              id="img"
              type="file"
              accept="vedio/*"
              
            />
          </div>
        )}
  
        <div className={styles.postBtn}>
        {loading ? (
            <div style={{ width: '30px', height: '30px' }} className="loader"></div>
          ) : (
            <button onClick={handlePost}>Post</button>
          )}
        </div>
      </div>
    
    )
}


export default Learnpost