import React from 'react';
import LibTl from '../../components/posts/lib/lib';
import styles from './lib.module.css'

const Lib=()=>{
    return(
        <div className={styles.container}>
            <LibTl/>
           
        </div>
    )
}



export default Lib