// FeedbackForm.js

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitFeedback } from '../../Redux/feedbackSlice';
import styles from '../../components/posts/blog/blog.module.css';

const FeedbackForm = () => {
  const [feedbackText, setFeedbackText] = useState('');
  const [rating, setRating] = useState(5);
  const dispatch = useDispatch();
  const feedbackStatus = useSelector((state) => state.feedback.status);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(submitFeedback({ feedbackText, rating }));
    setFeedbackText('');
    setRating(5);
  };

  return (
    <div className={styles.container}>
      <h2>Submit Feedback</h2>
      <form  style={{display:'flex', flexDirection:'column',alignItems:'self-start' ,padding:'20px'}} onSubmit={handleSubmit}>
        <textarea  

        style={{    margin:"1px"}}
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          placeholder="Enter your feedback"
          required
        />
        <label>
          Rating:
          <input
          style={{    margin:"10px"}}
            type="number"
            value={rating}
            onChange={(e) => setRating(Number(e.target.value))}
            min="1"
            max="5"
            required
          />
        </label>
        <button  style={{    margin:"5px" ,backgroundColor:"#11a8e1", color:"white" ,border:'none', height:'26px' ,borderRadius:'2rem'}} type="submit" disabled={feedbackStatus === 'loading'}>
          Submit Feedback
        </button>
      </form>
      {feedbackStatus === 'succeeded' && <p>Feedback submitted successfully!</p>}
    </div>
  );
};

export default FeedbackForm;
