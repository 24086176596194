
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../Job/job.module.css';
import { BsChatDots } from 'react-icons/bs';
import { IoMdMore,IoMdRepeat } from 'react-icons/io';
import { GrFormViewHide } from "react-icons/gr";
import { AiOutlineLike } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import PostHeader from '../postHeader';
import { useSelector,useDispatch } from 'react-redux';
import { RiBookmarkLine, RiDeleteBin5Line, RiPencilLine } from 'react-icons/ri';

import { AiOutlineRead, AiFillLike, AiOutlineComment } from 'react-icons/ai';
import { AiOutlineDelete } from "react-icons/ai";

import Modal from './modal';
import ArticleLikesModal from './articleLikeModel';
import {
    fetchArticles,
    updateArticle,
    deleteArticle,
    likeArticle,
    commentArticle,
    replyComment,
    deleteComment,
    editComment,
    likeComment,
    editReply, deleteReply, likeReply,
  } from '../../../Redux/articleSlice';

  import {
    deleteSharedArticle
  } from '../../../Redux/profileSlice';
  
  import { fetchProfile } from '../../../Redux/profileSlice';

const SharedArticles = ({ sharedArticles }) => {

    const profile = useSelector((state) => state.profiles.profiles);

    const [articles, setArticles] = useState(sharedArticles); 
    const [editingArticle, setEditingArticle] = useState(null);
    const [editedText, setEditedText] = useState('');
    const [commentText, setCommentText] = useState('');
    const [replyText, setReplyText] = useState('');
    const [replyingToCommentId, setReplyingToCommentId] = useState(null);
    const [replyingToReplyId, setReplyingToReplyId] = useState(null);
    const [openComments, setOpenComments] = useState({});
    const [openReplies, setOpenReplies] = useState({});
    const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
    const [editedCommentText, setEditedCommentText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
    const [likedUsers, setLikedUsers] = useState([])
    const [editingReplyId, setEditingReplyId] = useState(null); // State to track the reply being edited
    const [editedReplyText, setEditedReplyText] = useState('');

    const dispatch = useDispatch();
    

  

  useEffect(() => {
    dispatch(fetchArticles());
  }, [dispatch]);


  if (!sharedArticles || sharedArticles.length === 0) {
    return <div>No shared articles available.</div>;
  }

console.log(sharedArticles)



//---------------------------------------------// HandleArticle Like Function//-------------------------------------------------------//


const handleLike = (articleId) => {
    dispatch(likeArticle({ articleId }));
    dispatch(fetchProfile());
  };


  //---------------------------------------------// HandleArticle Like Function End//-------------------------------------------------------//
  
  
  
  //---------------------------------------------//  Delete Article Function //-------------------------------------------------------//
  
  
  
  const handleDelete = (articleId) => {
    const userId = profile.userId; 
    const isConfirmed = window.confirm('Are you sure you want to delete this post?');
    if (!isConfirmed) {
      return;
    }
    // Dispatch the delete comment action
    dispatch(deleteSharedArticle({ userId, articleId }))
      .then(() => {
        // Optional: Show a success message or update the UI
        console.log('deleted successfully');
        dispatch(fetchProfile()); // Refresh articles after deletion
      })
      .catch((error) => {
        // Optional: Handle error if comment deletion fails
        console.error('Error deleting comment:', error);
      });
  };



  
  //---------------------------------------------//  Delete Article Function End //-------------------------------------------------------//






  //---------------------------------------------// Edit Article Functions //-------------------------------------------------------//

  const handleSaveEdit = () => {
    dispatch(updateArticle({ id: editingArticle, articleData: { text: editedText } }))
      .then(() => {
        alert('Post edited successfully!');
        setEditingArticle(null);
        setEditedText('');
        dispatch(fetchProfile());
      })
      .catch((error) => {
        alert('Error editing post: ' + error.message);
      });
  };


  const handleEdit = (articleId, initialText) => {
    setEditingArticle(articleId);
    setEditedText(initialText);
  };

  const handleTextChange = (value) => {
    setEditedText(value);
  };
//---------------------------------------------// Edit Article Functions End //-------------------------------------------------------//

//---------------------------------------------// Add Comment Function //-------------------------------------------------------//


  
  const handleCommentSubmit = (articleId, e) => {
    e.preventDefault(); // Prevent page refresh
    if (commentText.trim() !== '') {
      dispatch(commentArticle({ articleId, text: commentText }))
        .then(() => {
          setCommentText('');
          dispatch(fetchProfile());// Fetch articles again to get updated comments
        })
        .catch((error) => {
          console.error('Error submitting comment:', error);
        });
    } else {
      console.error('Comment text cannot be empty');
    }
  };
 //---------------------------------------------// Add Comment Function  End //-------------------------------------------------------//


 //---------------------------------------------//  HandleComment Like  Function //-------------------------------------------------------//
  
  const handleLikeComment = (articleId, commentId) => {
    dispatch(likeComment({ articleId, commentId }));

  };
//---------------------------------------------//  HandleComment Like  Function End //-------------------------------------------------------//


//---------------------------------------------//  Delete Comment Function //-------------------------------------------------------//

  
  const handleDeleteComment = (articleId, commentId) => {
    console.log(articleId);
    console.log(commentId);
    // Confirm deletion
    const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
    if (!isConfirmed) {
      return;
    }
    // Dispatch the delete comment action
    dispatch(deleteComment({ articleId, commentId }))
      .then(() => {
        // Optional: Show a success message or update the UI
        console.log('Comment deleted successfully');
        dispatch(fetchProfile()); // Refresh articles after deletion
      })
      .catch((error) => {
        // Optional: Handle error if comment deletion fails
        console.error('Error deleting comment:', error);
      });
  };



//---------------------------------------------//  Delete Comment Function End //-------------------------------------------------------//


//---------------------------------------------//  Handle Edit Article Function //-------------------------------------------------------//


  const handleSaveEditComment = (articleId, commentId) => {
      dispatch(editComment({ articleId, commentId, newText: editedCommentText }))
        .then(() => {
          alert('Comment edited successfully!');
          setEditingCommentId(null);
          setEditedCommentText('');
          dispatch(fetchProfile());
        })
        .catch((error) => {
          alert('Error editing comment: ' + error.message);
        });
    };




  const handleEditComment = (commentId, initialText) => {
    setEditingCommentId(commentId);
    setEditedCommentText(initialText);
  };

  const handleTextChangeComment = (value) => {
    setEditedCommentText(value);
  };



//---------------------------------------------//  Handle Edit Article Function End //-------------------------------------------------------//






//---------------------------------------------//  Handle Reply submit Function  //-------------------------------------------------------//

const handleReplySubmit = (articleId, commentId, replyToReplyId = null, e) => {
  e.preventDefault(); // Prevent page refresh
  if (replyText.trim() !== '') {
    dispatch(replyComment({ articleId, commentId, replyId: replyToReplyId, text: replyText }))
      .then(() => {
        setReplyText('');
        dispatch(fetchArticles()); // Fetch articles again to get updated comments
      })
      .catch((error) => {
        console.error('Error submitting reply:', error);
      });
  } else {
    console.error('Reply text cannot be empty');
  }
};


//---------------------------------------------//  Handle Reply submit Function End  //-------------------------------------------------------//







//---------------------------------------------// HandleReply Like  Function Function  //-------------------------------------------------------//

                          const handleLikeReply = (articleId,commentId,replyId) => {
                         dispatch(likeReply({articleId,commentId, replyId}));
                          };

  //---------------------------------------------// HandleReply Like  Function Function End  //-------------------------------------------------------//
 
 




 //---------------------------------------------// HandleReply Delete  Function Function End  //-------------------------------------------------------//
 
                      const handleDeleteReply = (articleId,commentId,replyId) => {
                       dispatch(deleteReply({articleId,commentId, replyId }));
                       };

  //---------------------------------------------// HandleReply Delete  Function Function End  //-------------------------------------------------------//






 //---------------------------------------------// HandleEditReply  Function  //-------------------------------------------------------//

  const handleSaveEditReply = (articleId, commentId, replyId) => {
    dispatch(editReply({ articleId, commentId, replyId, newText: editedReplyText }))
      .then(() => {
        alert('Reply edited successfully!');
        setEditingReplyId(null);
        setEditedReplyText('');
        dispatch(fetchArticles()); // Fetch articles again to get updated comments
      })
      .catch((error) => {
        alert('Error editing reply: ' + error.message);
      });
  };


  const handleEditReply = (replyId, initialText) => {
    setEditingReplyId(replyId);
    setEditedReplyText(initialText);
  };



  const handleTextChangeReply = (value) => {
    setEditedReplyText(value);
  };





  // Handle Comment Close Open Toggler
  const handleToggleComments = (articleId) => {
    setOpenComments((prevState) => ({
      ...prevState,
      [articleId]: !prevState[articleId],
    }));
  };

  const handleToggleReplies = (commentId) => {
    setOpenReplies((prevOpenReplies) => ({
      ...prevOpenReplies,
      [commentId]: !prevOpenReplies[commentId],
    }));
  };


  const handleOpenModal = (likedBy) => {
    setLikedUsers(likedBy);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLikedUsers([]);
  };

  const handleOpenArticleModal = (likedBy) => {
    setLikedUsers(likedBy);
    setIsModalOpen(true);
  };

  const handleArticleCloseModal = () => {
    setIsModalArticleOpen(false);
    setLikedUsers([]);
  };





  return (


    <>





<div >
      {sharedArticles &&
        sharedArticles.map((article) => (
          <div key={article._id} className={styles.container}>
           
           {article && article.author ? (
          <PostHeader
            firstName={article.author.firstName}  // Author's first name
            lastName={article.author.lastName}    // Author's last name
            profilePicture={article.author.profilePicture}  // Author's profile picture
            icon={<AiOutlineRead size={'1rem'} />}
          />
        ) : (
          <div>Loading author details...</div>  
        )}
              

            {editingArticle === article._id ? (
              <div>
                <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
                <button onClick={handleSaveEdit}>Save</button>
              </div>
            ) : (
              <div>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: article.text }} />
                {article.file && (
                  <img src={article.file} alt="Article" style={{ width: '100%', height: 'auto' }} />
                )}
                
                       
                
                { article.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
                  <>
                    
                    <AiOutlineDelete  onClick={() => handleDelete(article._id)}/>
                    {/* <button onClick={() => handleDelete(article._id)}>Delete</button> */}
                    <TbEdit onClick={() => handleEdit(article._id, article.text)} />
                    {/* <button onClick={() => handleEdit(article._id, article.text)}>Edit</button> */}
                  
                  </>
                )}


              </div>
            )}





            <div className={styles.commentSection}>
           
{openComments[article._id] &&
  article.comments &&
  article.comments.map((comment) => (
    <div key={comment._id} className={styles.comment}>
      <div>
        <img
          alt="profile picture"
          src="https://randomuser.me/api/portraits/men/28.jpg"
          style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
        />
      </div>
      <div
        style={{
          marginLeft: '40px',
          backgroundColor: '#e6e6e6',
          borderRadius: '10px',
          padding: '10px',
        }}
      >
        <div>
          <strong>{comment.firstName} {comment.lastName}</strong>
        </div>
        <div>{comment.text}</div>
        
        {(comment.userId === profile.userId || article.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
                       <>
                          {comment.userId === profile.userId ? (
                            <>
                              
                                <RiPencilLine    onClick={() => handleEditComment(comment._id, comment.text)}
                                className={styles.editButton} />
                             
                              
                              
                                <RiDeleteBin5Line   onClick={() => handleDeleteComment(article._id, comment._id)}
                                className={styles.deleteButton} />
                             
                            </>
                          ) : (
                        <RiDeleteBin5Line    onClick={() => handleDeleteComment(article._id, comment._id)}
                              className={styles.deleteButton} />
                            
                          )}
                        </>
                      )}






        <AiFillLike size="1rem" onClick={() => handleLikeComment(article._id, comment._id)} />
        <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
       
       
       
        <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
        <button onClick={() => setReplyingToCommentId(comment._id)}>Reply</button>
        
        
        
        {comment.replies && comment.replies.length > 0 && (
          <button onClick={() => handleToggleReplies(comment._id)}>
            {openReplies[comment._id] ? 'Hide Replies' : `View Replies (${comment.replies.length})`}
          </button>
        )}
        
 
      

        {openReplies[comment._id] &&
          comment.replies &&
          comment.replies.map((reply) => (


            
            <div key={reply._id} className={styles.reply}>





              
              <div>
                <img
                  alt="profile picture"
                  src="https://randomuser.me/api/portraits/men/28.jpg"
                  style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
                />
              </div>
              <div
                style={{
                  marginLeft: '40px',
                  backgroundColor: '#e6e6e6',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
              
                  <strong>{reply.firstName} {reply.lastName}</strong>
            
                <div>{reply.text}</div>
                <AiFillLike size="1rem" onClick={() => handleLikeReply(article._id, comment._id, reply._id)} />
                              <span style={{ color: '#11a8e1' }}>{reply.likes}</span>
        

                              {(reply.userId === profile.userId || article.userId === profile.userId) && ( // Allow reply delete for reply owner or post owner
                             <>
                                {reply.userId === profile.userId ? (
                                  <>
                                    
                                      
                                    
                                      <RiPencilLine onClick={() => handleEditReply(reply._id, reply.text)}
                                      className={styles.editButton} />
                                    
                                 
                                      <RiDeleteBin5Line    onClick={() => handleDeleteReply(article._id, comment._id, reply._id)}
                                      className={styles.deleteButton} />
                                 
                                 
                                  </>
                                
                              
                              
                              ) : (
                                 
                                    <RiDeleteBin5Line  onClick={() => handleDeleteReply(article._id, comment._id, reply._id)}
                                    className={styles.deleteButton} />
                                  
                                )}
</>
                            )}


             
{editingReplyId === reply._id && (
                            <>
                              <textarea
                                value={editedReplyText}
                                onChange={(e) => handleTextChangeReply(e.target.value)}
                                className={styles.editTextArea}
                              />
                              <button onClick={() => handleSaveEditReply(article._id, comment._id, reply._id)}>Save</button>
                            </>
                          )}


              </div>
            </div>
          ))}
        
       
      
      </div>



      {replyingToCommentId === comment._id && (
          <div className={styles.replySection}>
            <input
              type="text"
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              placeholder="Write a reply..."
            />
            <button onClick={(e) => handleReplySubmit(article._id, comment._id, null, e)}>
              <AiOutlineComment size="1rem" />
              <span>Reply</span>
            </button>
          </div>
        )}







{editingCommentId && (
              <div>
                <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
                <button onClick={() => handleSaveEditComment(article._id, editingCommentId)}>Save</button>
              </div>
            )}


    </div>
  ))
}

{openComments[article._id] && (
                <div className={styles.addComment}>
                  <input
                    type="text"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    placeholder="Add a comment..."
                  />
                  <button onClick={(e) => handleCommentSubmit(article._id, e)} className={styles.commentButton}>
                    <AiOutlineComment size="1rem" />
                    <span>Comment</span>
                  </button>
                </div>
              )}


            </div>

            <div style={{ display: 'flex' }}>
              <div className={styles.intraction}>
                <div className={styles.icons}>
                  <AiOutlineLike   onClick={() => handleLike(article._id)} size="1rem" color={article.isLiked ? 'blue' : 'black'} />
                  <span style={{ color: '#11a8e1' }}>{article.likes}</span>
                  {/* <button onClick={() => handleOpenArticleModal(article.likedBy)}>View Likes</button> */}
              
                </div>

                <div className={styles.icons}>
                <GrFormViewHide   onClick={() => handleOpenArticleModal(article.likedBy)}/>
                  <span style={{ color: '#11a8e1' }}  >{article.like?.length}</span>
                </div>
                <div className={styles.icons}>
                  <BsChatDots size="1rem" onClick={() => handleToggleComments(article._id)} />
                  <span style={{ color: '#11a8e1' }}  >{article.comments?.length}</span>
                </div>
                <div className={styles.icons}>
                  <IoMdRepeat  size="1rem"   />
                  <span style={{ color: '#11a8e1' }}>{article.shares}</span>
                </div>
                <div className={styles.icons}>
                  <RiBookmarkLine size="1rem" />
                </div>
              </div>
              {/* <div className={styles.icons}>
                <IoMdMore size="1rem" />
              </div> */}
            </div>
          </div>
        ))}

      {isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
      {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}

{/* 
      <div className={styles.pagination}>
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? styles.active : ''}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div> */}


    </div>









{/* <div className={styles.container}>
      {sharedArticles &&
        sharedArticles.map((article) => (
          <div key={article._id}>
            <PostHeader     firstName={article.firstName} 
                            lastName={article.lastName}    icon={<AiOutlineRead size={'1rem'} />} />

            {editingArticle === article._id ? (
              <div>
                <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
                <button onClick={handleSaveEdit}>Save</button>
              </div>
            ) : (
              <div>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: article.text }} />
                {article.file && (
                  <img src={require(`../../../images/${article.file.name}`).default} alt="Article" style={{ width: '100%', height: 'auto' }} />
                )}

                <button onClick={() => handleDelete(article._id)}>Delete</button>
                { article.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
                  <>
                    
                    <button onClick={() => handleEdit(article._id, article.text)}>Edit</button>
                  </>
                )}

              </div>
            )}





            <div className={styles.commentSection}>
           
{openComments[article._id] &&
  article.comments &&
  article.comments.map((comment) => (
    <div key={comment._id} className={styles.comment}>
      <div>
        <img
          alt="profile picture"
          src="https://randomuser.me/api/portraits/men/28.jpg"
          style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
        />
      </div>
      <div
        style={{
          marginLeft: '40px',
          backgroundColor: '#e6e6e6',
          borderRadius: '10px',
          padding: '10px',
        }}
      >
        <div>
          <strong>{comment.firstName} {comment.lastName}</strong>
        </div>
        <div>{comment.text}</div>
        
        {(comment.userId === profile.userId || article.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
                       <>
                          {comment.userId === profile.userId ? (
                            <>
                              
                                <RiPencilLine    onClick={() => handleEditComment(comment._id, comment.text)}
                                className={styles.editButton} />
                             
                              
                              
                                <RiDeleteBin5Line   onClick={() => handleDeleteComment(article._id, comment._id)}
                                className={styles.deleteButton} />
                             
                            </>
                          ) : (
                        <RiDeleteBin5Line    onClick={() => handleDeleteComment(article._id, comment._id)}
                              className={styles.deleteButton} />
                            
                          )}
                        </>
                      )}






        <AiFillLike size="1rem" onClick={() => handleLikeComment(article._id, comment._id)} />
        <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
       
       
       
        <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
        <button onClick={() => setReplyingToCommentId(comment._id)}>Reply</button>
        
        
        
        {comment.replies && comment.replies.length > 0 && (
          <button onClick={() => handleToggleReplies(comment._id)}>
            {openReplies[comment._id] ? 'Hide Replies' : `View Replies (${comment.replies.length})`}
          </button>
        )}
        
 
      

        {openReplies[comment._id] &&
          comment.replies &&
          comment.replies.map((reply) => (


            
            <div key={reply._id} className={styles.reply}>





              
              <div>
                <img
                  alt="profile picture"
                  src="https://randomuser.me/api/portraits/men/28.jpg"
                  style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
                />
              </div>
              <div
                style={{
                  marginLeft: '40px',
                  backgroundColor: '#e6e6e6',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
              
                  <strong>{reply.firstName} {reply.lastName}</strong>
            
                <div>{reply.text}</div>
                <AiFillLike size="1rem" onClick={() => handleLikeReply(article._id, comment._id, reply._id)} />
                              <span style={{ color: '#11a8e1' }}>{reply.likes}</span>
        

                              {(reply.userId === profile.userId || article.userId === profile.userId) && ( // Allow reply delete for reply owner or post owner
                             <>
                                {reply.userId === profile.userId ? (
                                  <>
                                    
                                      
                                    
                                      <RiPencilLine onClick={() => handleEditReply(reply._id, reply.text)}
                                      className={styles.editButton} />
                                    
                                 
                                      <RiDeleteBin5Line    onClick={() => handleDeleteReply(article._id, comment._id, reply._id)}
                                      className={styles.deleteButton} />
                                 
                                 
                                  </>
                                
                              
                              
                              ) : (
                                 
                                    <RiDeleteBin5Line  onClick={() => handleDeleteReply(article._id, comment._id, reply._id)}
                                    className={styles.deleteButton} />
                                  
                                )}
</>
                            )}


             
{editingReplyId === reply._id && (
                            <>
                              <textarea
                                value={editedReplyText}
                                onChange={(e) => handleTextChangeReply(e.target.value)}
                                className={styles.editTextArea}
                              />
                              <button onClick={() => handleSaveEditReply(article._id, comment._id, reply._id)}>Save</button>
                            </>
                          )}


              </div>
            </div>
          ))}
        
       
      
      </div>



      {replyingToCommentId === comment._id && (
          <div className={styles.replySection}>
            <input
              type="text"
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              placeholder="Write a reply..."
            />
            <button onClick={(e) => handleReplySubmit(article._id, comment._id, null, e)}>
              <AiOutlineComment size="1rem" />
              <span>Reply</span>
            </button>
          </div>
        )}







{editingCommentId && (
              <div>
                <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
                <button onClick={() => handleSaveEditComment(article._id, editingCommentId)}>Save</button>
              </div>
            )}


    </div>
  ))
}

{openComments[article._id] && (
                <div className={styles.addComment}>
                  <input
                    type="text"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    placeholder="Add a comment..."
                  />
                  <button onClick={(e) => handleCommentSubmit(article._id, e)} className={styles.commentButton}>
                    <AiOutlineComment size="1rem" />
                    <span>Comment</span>
                  </button>
                </div>
              )}


            </div>

            <div style={{ display: 'flex' }}>
              <div className={styles.intraction}>
                <div className={styles.icons}>
                  <AiFillLike onClick={() => handleLike(article._id)} size="1rem" color={article.isLiked ? 'blue' : 'black'} />
                  <span style={{ color: '#11a8e1' }}>{article.likes}</span>
                  <button onClick={() => handleOpenArticleModal(article.likedBy)}>View Likes</button>
                </div>
                <div className={styles.icons}>
                  <BsChatDots size="1rem" onClick={() => handleToggleComments(article._id)} />
                  <span>{article.comments?.length}</span>
                </div>
                <div className={styles.icons}>
                  <IoMdRepeat />
                  <span style={{ color: '#11a8e1' }}>{article.shares}</span>
                </div>
                <div className={styles.icons}>
                  <RiBookmarkLine size="1rem" />
                </div>
              </div>
              <div className={styles.icons}>
                <IoMdMore size="1rem" />
              </div>
            </div>
          </div>
        ))}

      {isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
      {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}
    </div> */}

        
        
        

    
     
 </>
   
  );
};

SharedArticles.propTypes = {
  sharedArticles: PropTypes.array.isRequired,
};

export default SharedArticles;


// import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import {RiBookmarkLine,RiSuitcaseLine} from 'react-icons/ri'
// import {AiOutlineLike,AiOutlineRead} from 'react-icons/ai'
// import {IoMdRepeat,IoMdMore} from 'react-icons/io'
// import styles from '../media/media.module.css'
// import PostHeader from '../postHeader';
// import { BsChatDots } from 'react-icons/bs';

// const SharedArticles = () => {
//     // const sharedArticles = useSelector((state) => state.articles.articles);
//     // const dispatch = useDispatch();

//     const profile = useSelector((state) => state.profiles.profiles);
//     const sharedArticles = useSelector((state) => state.profiles.sharedArticles);
//     console.log(sharedArticles)
//     return (
//    <>
     
 
 
 
 
//           <div className={styles.container}>
//           <PostHeader
//            firstName={profile.firstName} 
//                                 lastName={profile.lastName}    icon={<AiOutlineRead size={'1rem'} />} 
//           />
//           <div className={styles.content}>
//               <div>machine learningEasily setup personalization to automatically optimize individual user experiences, maximizing the objectives you care about through the power of machine learning </div>
//           <img alt="civi" src="https://images.pexels.com/photos/4161619/pexels-photo-4161619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
//           </div>
//           <div style={{display:'flex'}}>

         

//           <div className={styles.intraction}>
//           <div className={styles.icons}>
//                   <AiOutlineLike size="1rem" />
//               </div>
             
//               <div className={styles.icons}>
//                   <IoMdRepeat  size="1rem" />
//               </div>
//               <div className={styles.icons}>
//                   <RiBookmarkLine  size="1rem"/>
//               </div>
//               <div className={styles.icons}>
//                   <BsChatDots />
//                   <span></span>
//                 </div>

              
//           </div>
//           <div className={styles.icons} ><IoMdMore  size="1rem" /></div>
//          </div>
          
//       </div>
 
 
// </>

//     );
// }

// export default SharedArticles;

