import React from 'react'
// import Learnpost from '../../components/addpost/learn'
import MediaTl from '../../components/posts/media/mediaTl'
import  styles from './home.module.css'


export default function Learn() {
    return (
        <div className={styles.container}>
        <MediaTl/>
      


        </div>
    )
}
