import React from 'react'
import styles from './post.module.css'
import {RiSuitcaseLine} from 'react-icons/ri'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const PostHeader =({ firstName, lastName, icon,profilePicture ,time,userId})=>{
    const profile = useSelector((state) => state.profiles.profiles);
   
    const navigate = useNavigate();
console.log(userId ,'test')

      // Handle profile navigation
  const handleProfileClick = () => {
    navigate(`/profile/${userId}`); // Redirect to profile page with userId
  };

    return(
        <div>
            <div className={styles.header}>
            <img
          alt="Profile"
          
          
          
          src={profilePicture || 'https://randomuser.me/api/portraits/men/28.jpg'}
          onClick={handleProfileClick} // Navigate on click
          style={{ cursor: 'pointer' }}
          
          // Dynamically show the updated profile picture
        />
                <div className={styles.userinfo}>
                    <div style={{fontSize:'1rem',fontWeight:600}}>{firstName} {lastName}</div>
                    <div style={{color:'#3A3A3A',fontSize:'small'}} >{time}</div>
                </div>
                <div className={styles.postIcon}>
                    {icon}
                </div>
                
            </div>
        </div>
    )
}


export default PostHeader