import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import { GrFormViewHide } from "react-icons/gr";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { MentionsInput, Mention } from 'react-mentions';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchArticles,
  updateArticle,
  deleteArticle,
  likeArticle,
  commentArticle,
  replyComment,
  deleteComment,
  editComment,
  likeComment,
  editReply, deleteReply, likeReply,shareArticle
} from '../../../Redux/articleSlice';
import { fetchNotifications } from '../../../Redux/notificationSlice';
// import { io } from 'socket.io-client'; 
import Pusher from 'pusher-js';
import styles from './blog.module.css';
import { RiBookmarkLine, RiDeleteBin5Line, RiPencilLine } from 'react-icons/ri';
import PostHeader from '../postHeader';
import { AiOutlineRead, AiFillLike, AiOutlineComment } from 'react-icons/ai';
import { IoMdRepeat, IoMdMore } from 'react-icons/io';
import { BsChatDots } from 'react-icons/bs';
import Modal from './modal';
import ArticleLikesModal from './articleLikeModel';
import { fetchAllProfiles } from '../../../Redux/profileSlice';
// import {socket , connectSocket, emitEvent} from "../../../Socket/socket"

const BlogTL = () => {
  const dispatch = useDispatch();
  // const articles = useSelector((state) => state.articles.articles);
  const { articles, status, totalPages, currentPage } = useSelector((state) => state.articles);
  const profile = useSelector((state) => state.profiles.profiles);
  const allProfiles = useSelector((state) => state.profiles.allProfiles);
  const [editingArticle, setEditingArticle] = useState(null);
  const [editedText, setEditedText] = useState('');
  const [commentText, setCommentText] = useState('');
  const [replyText, setReplyText] = useState('');
  const [replyingToCommentId, setReplyingToCommentId] = useState(null);
  const [replyingToReplyId, setReplyingToReplyId] = useState(null);
  const [openComments, setOpenComments] = useState({});
  const [openReplies, setOpenReplies] = useState({});
  const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
  const [editedCommentText, setEditedCommentText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
  const [likedUsers, setLikedUsers] = useState([])
  const [editingReplyId, setEditingReplyId] = useState(null); // State to track the reply being edited
  const [editedReplyText, setEditedReplyText] = useState('');
  // const userId = profile.userId; 
  const { userId } = useParams(); 
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const limit = 10; // Number of items per page
console.log(articles)
console.log(profile)
  

dayjs.extend(relativeTime);


console.log(profile.userId)
const handlePageChange = (newPage) => {
  setPage(newPage);
};



 // Pusher setup
 useEffect(() => {
  const pusher = new Pusher('8d13c6a59a85bd1417e7', {
    cluster: 'eu',
  });

  // Subscribe to the article channel
  const channel = pusher.subscribe('article-channel');
  // const channel2 = pusher.subscribe('mention-channel');
  channel.bind('new-mention', (newNotification) => {
    console.log('Mention Notification: ', newNotification);
    dispatch(fetchNotifications(userId)); 
    // Show real-time notification to the user
  });
  // Listen for the 'like-article' event and update notifications
  channel.bind('like-article', (newNotification) => {
    console.log('New notification received:test', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });


  channel.bind('like-comment', (newNotification) => {
    console.log('New notification received:test', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });




  // Listen for the 'new-comment' event
  channel.bind('new-comment', (newNotification) => {
    console.log('New comment notification received:', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });

  // Listen for the 'share-article' event
  channel.bind('share-article', (newNotification) => {
    console.log('New article share notification received:', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });

  channel.bind('share-article', (newNotification) => {
    console.log('New article share notification received:', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });









  // Cleanup: Unsubscribe from the channel when the component unmounts
  return () => {
    channel.unbind_all();
    channel.unsubscribe();
  };
}, [dispatch, userId]);



const users = Array.isArray(allProfiles) 
  ? allProfiles.map(user => ({
      id: user._id,
      display: `${user.firstName} ${user.lastName}`,
    })) 
  : [];

// Extract mentions in `@[displayName](userId)` format
const extractMentionedUsers = (text) => {
  
  const mentionPattern = /@\[(.*?)\]\((.*?)\)/g;
  const mentionedUsers = [];
  let match;
  while ((match = mentionPattern.exec(text)) !== null) {
    mentionedUsers.push({
      displayName: match[1],
      userId: match[2]
    });
  }
  return mentionedUsers;
};

const formatMentions = (text) => {
  return text.replace(/@\[(.*?)\]\((.*?)\)/g, '@$1'); // Replaces with @displayName
};

const handleCommentSubmit = async (articleId, e) => {
  e.preventDefault();
  const mentionedUsers = extractMentionedUsers(commentText); // Extracts mentioned users from the comment text

  console.log("Mentioned Users:", mentionedUsers);
  console.log("Comment Text:", commentText);

  if (commentText.trim() === '') {
    console.error('Comment text cannot be empty');
    return;
  }

  try {
    await dispatch(commentArticle({ articleId, text: commentText, mentionedUsers }));
    setCommentText('');
    console.log("Comment dispatched successfully:", { articleId, text: commentText, mentionedUsers });
    dispatch(fetchArticles({ page, limit }));
  } catch (error) {
    console.error('Error submitting comment:', error.message);
    if (error.response) {
      console.error("Backend response:", error.response.data);
    }
  }
};



  const handleLike = (articleId) => {
    //  const userId = profile.userId
   
    dispatch(likeArticle({ articleId }));
    dispatch(fetchArticles({ page, limit }));
  };


  //---------------------------------------------// HandleArticle Like Function End//-------------------------------------------------------//
  
  
  
  //---------------------------------------------//  Delete Article Function //-------------------------------------------------------//
  
  
  
  const handleDelete = (articleId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this post?');
    if (!isConfirmed) {
      return;
    }
    // Dispatch the delete comment action
    dispatch(deleteArticle(articleId))
      .then(() => {
        // Optional: Show a success message or update the UI
        console.log('deleted successfully');
        dispatch(fetchArticles({ page, limit })); // Refresh articles after deletion
      })
      .catch((error) => {
        // Optional: Handle error if comment deletion fails
        console.error('Error deleting comment:', error);
      });
  };



  
  //---------------------------------------------//  Delete Article Function End //-------------------------------------------------------//




  const handleProfileClick = (userId) => {
    console.log(userId); 
    if (userId) {
      navigate(`/profile/${userId}`)
      console.log(userId); // Redirect to profile page with userId
    }
  };




  //---------------------------------------------// Edit Article Functions //-------------------------------------------------------//

  const handleSaveEdit = () => {
    dispatch(updateArticle({ id: editingArticle, articleData: { text: editedText } }))
      .then(() => {
        alert('Post edited successfully!');
        setEditingArticle(null);
        setEditedText('');
        dispatch(fetchArticles({ page, limit }));
      })
      .catch((error) => {
        alert('Error editing post: ' + error.message);
      });
  };


  const handleEdit = (articleId, initialText) => {
    setEditingArticle(articleId);
    setEditedText(initialText);
  };

  const handleTextChange = (value) => {
    setEditedText(value);
  };
//---------------------------------------------// Edit Article Functions End //-------------------------------------------------------//

//---------------------------------------------// Add Comment Function //-------------------------------------------------------//

// const handleCommentSubmit = (articleId, e) => {
//   e.preventDefault();
//   const mentionedUsers = extractMentionedUsers(commentText);

//   if (commentText.trim() !== '') {
//     dispatch(commentArticle({ articleId, text: commentText, mentionedUsers }))
//       .then(() => {
//         setCommentText(''); // Clear comment input after submission
//         dispatch(fetchArticles({ page, limit })); // Fetch updated articles after comment submission
//       })
//       .catch((error) => {
//         console.error('Error submitting comment:', error);
//       });
//   }
// };
  
  // const handleCommentSubmit = (articleId, e) => {
  //   const userId = profile.userId
  //   // socket.emit('notification', { articleId, userId });
  //   e.preventDefault(); // Prevent page refresh
  //   if (commentText.trim() !== '') {
  //     dispatch(commentArticle({ articleId, text: commentText }))
  //       .then(() => {
  //         setCommentText('');
  //         dispatch(fetchArticles({ page, limit })); // Fetch articles again to get updated comments
  //       })
  //       .catch((error) => {
  //         console.error('Error submitting comment:', error);
  //       });
  //   } else {
  //     console.error('Comment text cannot be empty');
  //   }
  // };
 //---------------------------------------------// Add Comment Function  End //-------------------------------------------------------//


 //---------------------------------------------//  HandleComment Like  Function //-------------------------------------------------------//
  
  const handleLikeComment = (articleId, commentId) => {
    // socket.emit('notification', { articleId, userId });
    dispatch(likeComment({ articleId, commentId }));

  };
//---------------------------------------------//  HandleComment Like  Function End //-------------------------------------------------------//


//---------------------------------------------//  Delete Comment Function //-------------------------------------------------------//

  
  const handleDeleteComment = (articleId, commentId) => {
    console.log(articleId);
    console.log(commentId);
    // Confirm deletion
    const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
    if (!isConfirmed) {
      return;
    }
    // Dispatch the delete comment action
    dispatch(deleteComment({ articleId, commentId }))
      .then(() => {
        // Optional: Show a success message or update the UI
        console.log('Comment deleted successfully');
        dispatch(fetchArticles({ page, limit })); // Refresh articles after deletion
      })
      .catch((error) => {
        // Optional: Handle error if comment deletion fails
        console.error('Error deleting comment:', error);
      });
  };



//---------------------------------------------//  Delete Comment Function End //-------------------------------------------------------//


//---------------------------------------------//  Handle Edit Article Function //-------------------------------------------------------//


  const handleSaveEditComment = (articleId, commentId) => {
      dispatch(editComment({ articleId, commentId, newText: editedCommentText }))
        .then(() => {
          alert('Comment edited successfully!');
          setEditingCommentId(null);
          setEditedCommentText('');
          dispatch(fetchArticles({ page, limit }));
        })
        .catch((error) => {
          alert('Error editing comment: ' + error.message);
        });
    };




  const handleEditComment = (commentId, initialText) => {
    setEditingCommentId(commentId);
    setEditedCommentText(initialText);
  };

  const handleTextChangeComment = (value) => {
    setEditedCommentText(value);
  };



//---------------------------------------------//  Handle Edit Article Function End //-------------------------------------------------------//


  const handleShare = (articleId) => {
    const userId = profile.userId; // Get the user ID from the profile
    // socket.emit('notification', { articleId, userId });
    dispatch(shareArticle({ articleId, userId }));
    dispatch(fetchArticles({ page, limit }));
  };

 







  // Handle Comment Close Open Toggler
  const handleToggleComments = (articleId) => {
    setOpenComments((prevState) => ({
      ...prevState,
      [articleId]: !prevState[articleId],
    }));
  };



  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLikedUsers([]);
  };

  const handleOpenArticleModal = (likedBy) => {
    setLikedUsers(likedBy);
    setIsModalOpen(true);
  };

  const handleArticleCloseModal = () => {
    setIsModalArticleOpen(false);
    setLikedUsers([]);
  };



  useEffect(() => {
    dispatch(fetchAllProfiles());
  }, []);


  useEffect(() => {
    dispatch(fetchArticles({ page, limit }));
  }, [dispatch,page]);

  return (
    <div >
      {articles &&
        articles.map((article) => (
          <div key={article._id} className={styles.container}>
         

         {article && article.author ? (
          <PostHeader
            firstName={article.author.firstName}  // Author's first name
            lastName={article.author.lastName}    // Author's last name
            profilePicture={article.author.profilePicture}  // Author's profile picture
            icon={<AiOutlineRead size={'1rem'} />}
            userId={article.userId} 
           time={dayjs(article.createdAt).fromNow()}
          />
        ) : (
          <div>Loading author details...</div>  
        )}

            {editingArticle === article._id ? (
              <div>
                <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
                <button onClick={handleSaveEdit}>Save</button>
              </div>
            ) : (
              <div>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: article.text }} />
                {article.file && (
                  <img src={article.file} alt="Article" style={{ width: '100%', height: 'auto' }} />
                )}
                
                       

                { article.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
                  <>
                    <div className={styles.editOrDeleteIcons}>
                  <AiOutlineDelete  onClick={() => handleDelete(article._id)}/>
                    {/* <button onClick={() => handleDelete(article._id)}>Delete</button> */}
                    <TbEdit onClick={() => handleEdit(article._id, article.text)} />
                    {/* <button onClick={() => handleEdit(article._id, article.text)}>Edit</button> */}
                    </div>
                  </>
                )}


              </div>
            )}





            <div className={styles.commentSection} >
           
{openComments[article._id] &&
  article.comments &&
  article.comments.map((comment) => (
    <div key={comment._id} className={styles.comment}>
      <div >

      <img
                        src={
                          comment.commentAuthor.profilePicture ||
                          'https://randomuser.me/api/portraits/men/28.jpg'
                        }
                        onClick={() =>
                          handleProfileClick(comment.commentAuthor.userId)
                        }
                        alt={`${comment.commentAuthor.firstName}'s Profile`}
                        style={{
                          borderRadius: '100%',
                          width: '2.9rem',
                          height: '2.9rem',
                          cursor: 'pointer', // Makes it clear that it's clickable
                        }}
                      />
          
      </div>
      <div
        style={{
          // marginLeft: '40px',
          // backgroundColor: '#e6e6e6',
          borderRadius: '10px',
          padding: '10px',
          margin:'-63px -8px 6px 39px'
        }}
      >
        <div>
          <strong>{comment.commentAuthor?.firstName} {comment.commentAuthor?.lastName}</strong>
          <span style={{ marginLeft: '10px', color: '#888' }}>
                      {/* Display the relative time ago for the comment */}
                      {dayjs(comment.added).fromNow()}
                    </span>
        </div>
        <div>{formatMentions(comment.text)}</div>
        
        {(comment.userId === profile.userId || article.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
                       <>
                          {comment.userId === profile.userId ? (
                            <>
                              
                                <TbEdit    onClick={() => handleEditComment(comment._id, comment.text)}
                                className={styles.editButton} />
                             
                              
                              
                                <AiOutlineDelete   onClick={() => handleDeleteComment(article._id, comment._id)}
                                className={styles.deleteButton} />
                             
                            </>
                          ) : (
                        <AiOutlineDelete    onClick={() => handleDeleteComment(article._id, comment._id)}
                              className={styles.deleteButton} />
                            
                          )}
                        </>
                      )}






        <AiOutlineLike size="1rem" onClick={() => handleLikeComment(article._id, comment._id)} />
        <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
       
      
      </div>



{editingCommentId && (
              <div>
                <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
                <button onClick={() => handleSaveEditComment(article._id, editingCommentId)}>Save</button>
              </div>
            )}


    </div>
  ))
}

{openComments[article._id] && (
                <div className={styles.addComment}>
               <MentionsInput
  value={commentText}
  onChange={(e) => setCommentText(e.target.value)}
  placeholder="Add a comment..."
>
  <Mention
    trigger="@"
    data={users}
    displayTransform={(id, display) => `@${display}`}  // Format to `@[displayName](userId)`
  />
</MentionsInput>
              <button onClick={(e) => handleCommentSubmit(article._id, e)}>
                Comment
              </button>
                </div>
              )}


            </div>

            <div style={{ display: 'flex' }}>
              <div className={styles.intraction}>
                <div className={styles.icons}>
                  <AiOutlineLike   onClick={() => handleLike(article._id)} size="1rem" color={article.isLiked ? 'blue' : 'black'} />
                  <span style={{ color: '#11a8e1' }}>{article.likes}</span>
                  {/* <button onClick={() => handleOpenArticleModal(article.likedBy)}>View Likes</button> */}
              
                </div>

                <div className={styles.icons}>
                <GrFormViewHide   onClick={() => handleOpenArticleModal(article.likedBy)}/>
                  <span style={{ color: '#11a8e1' }}  >{article.like?.length}</span>
                </div>
                <div className={styles.icons}>
                  <BsChatDots size="1rem" onClick={() => handleToggleComments(article._id)} />
                  <span style={{ color: '#11a8e1' }}  >{article.comments?.length}</span>
                </div>
                <div className={styles.icons}>
                  <IoMdRepeat  size="1rem"  onClick={() => handleShare(article._id)}  />
                  <span style={{ color: '#11a8e1' }}>{article.shares}</span>
                </div>
                <div className={styles.icons}>
                  <RiBookmarkLine size="1rem" />
                </div>
              </div>
              {/* <div className={styles.icons}>
                <IoMdMore size="1rem" />
              </div> */}
            </div>
          </div>
        ))}

      {isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
      {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}


      <div className={styles.pagination}>
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? styles.active : ''}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>


    </div>
  );
};

export default BlogTL;



// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchArticles, updateArticle, deleteArticle, likeArticle, commentArticle, replyComment, deleteComment, editComment, likeComment } from '../../../Redux/articleSlice';

// import styles from './blog.module.css';
// import { RiBookmarkLine, RiDeleteBin5Line, RiPencilLine } from 'react-icons/ri';
// import PostHeader from '../postHeader';
// import { AiOutlineRead, AiFillLike, AiOutlineComment } from 'react-icons/ai';
// import { IoMdRepeat, IoMdMore } from 'react-icons/io';
// import { BsChatDots } from 'react-icons/bs';
// import Modal from "./modal";
// import ArticleLikesModal from './articleLikeModel';

// const BlogTL = (props) => {
//   const dispatch = useDispatch();
//   const articles = useSelector((state) => state.articles.articles);
//   const profile = useSelector((state) => state.profiles.profiles);
//   const [editingArticle, setEditingArticle] = useState(null);
//   const [editedText, setEditedText] = useState('');
//   const [commentText, setCommentText] = useState('');
//   const [replyText, setReplyText] = useState('');
//   const [replyingToCommentId, setReplyingToCommentId] = useState(null);
//   const [replyingToReplyId, setReplyingToReplyId] = useState(null);
//   const [openComments, setOpenComments] = useState({});
//   const [openReplies, setOpenReplies] = useState({});
//   const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
//   const [editedCommentText, setEditedCommentText] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
//   const [likedUsers, setLikedUsers] = useState([]);

//   const handleLikeComment = (articleId, commentId) => {
//     dispatch(likeComment({ articleId, commentId }));
//   };

//   const handleEditComment = (commentId, initialText) => {
//     setEditingCommentId(commentId);
//     setEditedCommentText(initialText);
//   };

//   const handleTextChangeComment = (value) => {
//     setEditedCommentText(value);
//   };

//   const handleReplySubmit = (articleId, commentId, replyToReplyId = null, e) => {
//     e.preventDefault(); // Prevent page refresh
//     if (replyText.trim() !== '') {
//       dispatch(replyComment({ articleId, commentId, replyId: replyToReplyId, text: replyText }))
//         .then(() => {
//           setReplyText('');
//           dispatch(fetchArticles()); // Fetch articles again to get updated comments
//         })
//         .catch((error) => {
//           console.error('Error submitting reply:', error);
//         });
//     } else {
//       console.error('Reply text cannot be empty');
//     }
//   };

//   const handleSaveEditComment = (articleId, commentId) => {
//     dispatch(editComment({ articleId, commentId, newText: editedCommentText }))
//       .then(() => {
//         alert('Comment edited successfully!');
//         setEditingCommentId(null);
//         setEditedCommentText('');
//         dispatch(fetchArticles()); // Fetch articles again to get updated comments
//       })
//       .catch((error) => {
//         alert('Error editing comment: ' + error.message);
//       });
//   };

//   // Delete Article Function
//   const handleDelete = (articleId) => {
//     const isConfirmed = window.confirm('Are you sure you want to delete this post?');
//     if (!isConfirmed) {
//       return;
//     }
//     // Dispatch the delete comment action
//     dispatch(deleteArticle(articleId))
//       .then(() => {
//         // Optional: Show a success message or update the UI
//         console.log('deleted successfully');
//         dispatch(fetchArticles()); // Refresh articles after deletion
//       })
//       .catch((error) => {
//         // Optional: Handle error if comment deletion fails
//         console.error('Error deleting comment:', error);
//       });
//   };

//   // Delete Comment Function
//   const handleDeleteComment = (articleId, commentId) => {
//     console.log(articleId);
//     console.log(commentId);
//     // Confirm deletion
//     const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
//     if (!isConfirmed) {
//       return;
//     }
//     // Dispatch the delete comment action
//     dispatch(deleteComment({ articleId, commentId }))
//       .then(() => {
//         // Optional: Show a success message or update the UI
//         console.log('Comment deleted successfully');
//         dispatch(fetchArticles()); // Refresh articles after deletion
//       })
//       .catch((error) => {
//         // Optional: Handle error if comment deletion fails
//         console.error('Error deleting comment:', error);
//       });
//   };

//   // Edit Article Functions
//   const handleEdit = (articleId, initialText) => {
//     setEditingArticle(articleId);
//     setEditedText(initialText);
//   };

//   const handleTextChange = (value) => {
//     setEditedText(value);
//   };

//   const handleSaveEdit = () => {
//     dispatch(updateArticle({ id: editingArticle, articleData: { text: editedText } }))
//       .then(() => {
//         alert('Post edited successfully!');
//         setEditingArticle(null);
//         setEditedText('');
//         dispatch(fetchArticles());
//       })
//       .catch((error) => {
//         alert('Error editing post: ' + error.message);
//       });
//   };

//   // Handle Like Function
//   const handleLike = (articleId) => {
//     dispatch(likeArticle({ articleId }));
//     dispatch(fetchArticles());
//   };

//   // Handle Comment Close Open Toggler
//   const handleToggleComments = (articleId) => {
//     setOpenComments((prevState) => ({
//       ...prevState,
//       [articleId]: !prevState[articleId],
//     }));
//   };

//   const handleToggleReplies = (commentId) => {
//     setOpenReplies((prevOpenReplies) => ({
//       ...prevOpenReplies,
//       [commentId]: !prevOpenReplies[commentId],
//     }));
//   };

//   // Add Comment Function
//   const handleCommentSubmit = (articleId, e) => {
//     e.preventDefault(); // Prevent page refresh
//     if (commentText.trim() !== '') {
//       dispatch(commentArticle({ articleId, text: commentText }))
//         .then(() => {
//           setCommentText('');
//           dispatch(fetchArticles()); // Fetch articles again to get updated comments
//         })
//         .catch((error) => {
//           console.error('Error submitting comment:', error);
//         });
//     } else {
//       console.error('Comment text cannot be empty');
//     }
//   };

//   const handleOpenModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setLikedUsers([]);
//   };

//   const handleOpenArticleModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalOpen(true);
//   };

//   const handleArticleCloseModal = () => {
//     setIsModalArticleOpen(false);
//     setLikedUsers([]);
//   };

//   useEffect(() => {
//     dispatch(fetchArticles());
//   }, [dispatch]);

//   return (
//     <div className={styles.container}>
//       {articles &&
//         articles.map((article) => (
//           <div key={article._id}>
//             <PostHeader icon={<AiOutlineRead size={'1rem'} />} />

//             {editingArticle === article._id ? (
//               <div>
//                 <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
//                 <button onClick={handleSaveEdit}>Save</button>
//               </div>
//             ) : (
//               <div>
//                 <div className={styles.content} dangerouslySetInnerHTML={{ __html: article.text }} />
//                 {article.file && (
//                   <img src={require(`../../../images/${article.file.name}`).default} alt="Article" style={{ width: '100%', height: 'auto' }} />
//                 )}
//                 <button onClick={() => handleDelete(article._id)}>Delete</button>
//                 <button onClick={() => handleEdit(article._id, article.text)}>Edit</button>
//               </div>
//             )}

//             <div className={styles.commentSection}>
//               {openComments[article._id] &&
//                 article.comments &&
//                 article.comments.map((comment) => (
//                   <div key={comment._id} className={styles.comment}>
//                     <div>
//                       <img
//                         alt="profile picture"
//                         src="https://randomuser.me/api/portraits/men/28.jpg"
//                         style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
//                       />
//                     </div>
//                     <div
//                       style={{
//                         marginLeft: '40px',
//                         backgroundColor: '#e6e6e6',
//                         borderRadius: '10px',
//                         padding: '10px',
//                       }}
//                     >
//                       <div>
//                         <strong>{comment.firstName} {comment.lastName}</strong>
//                       </div>
//                       <div>{comment.text}</div>
//                       <RiDeleteBin5Line
//                         size={20}
//                         style={{ marginTop: '10px' }}
//                         onClick={() => handleDeleteComment(article._id, comment._id)}
//                       />
//                       <RiPencilLine size={20} onClick={() => handleEditComment(comment._id, comment.text)} />
//                       <AiFillLike size="1rem" onClick={() => handleLikeComment(article._id, comment._id)} />
//                       <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
//                       <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
//                       <button onClick={() => setReplyingToCommentId(comment._id)}>Reply</button>
//                       {comment.replies && comment.replies.length > 0 && (
//                         <button onClick={() => handleToggleReplies(comment._id)}>
//                           {openReplies[comment._id] ? 'Hide Replies' : `View Replies (${comment.replies.length})`}
//                         </button>
//                       )}
//                     </div>

//                     {openReplies[comment._id] &&
//                       comment.replies &&
//                       comment.replies.map((reply) => (
//                         <div key={reply._id} className={styles.reply}>
//                           <div>
//                             <img
//                               alt="profile picture"
//                               src="https://randomuser.me/api/portraits/men/28.jpg"
//                               style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
//                             />
//                           </div>
//                           <div
//                             style={{
//                               marginLeft: '40px',
//                               backgroundColor: '#e6e6e6',
//                               borderRadius: '10px',
//                               padding: '10px',
//                             }}
//                           >
//                             <div>
//                               <strong>{reply.firstName} {reply.lastName}</strong>
//                             </div>
//                             <div>{reply.text}</div>
//                             <AiFillLike size="1rem" onClick={() => handleLikeComment(article._id, reply._id)} />
//                             <span style={{ color: '#11a8e1' }}>{reply.likes}</span>
//                             <button onClick={() => setReplyingToReplyId(reply._id)}>Reply</button>
//                           </div>
//                           {replyingToReplyId === reply._id && (
//                             <div className={styles.replySection}>
//                               <input
//                                 type="text"
//                                 value={replyText}
//                                 onChange={(e) => setReplyText(e.target.value)}
//                                 placeholder="Write a reply..."
//                               />
//                               <button onClick={(e) => handleReplySubmit(article._id, comment._id, reply._id, e)}>
//                                 <AiOutlineComment size="1rem" />
//                                 <span>Reply</span>
//                               </button>
//                             </div>
//                           )}
//                         </div>
//                       ))}

//                     {editingCommentId === comment._id && (
//                       <div>
//                         <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
//                         <button onClick={() => handleSaveEditComment(article._id, comment._id)}>Save</button>
//                       </div>
//                     )}

//                     {replyingToCommentId === comment._id && (
//                       <div className={styles.replySection}>
//                         <input
//                           type="text"
//                           value={replyText}
//                           onChange={(e) => setReplyText(e.target.value)}
//                           placeholder="Write a reply..."
//                         />
//                         <button onClick={(e) => handleReplySubmit(article._id, comment._id, null, e)}>
//                           <AiOutlineComment size="1rem" />
//                           <span>Reply</span>
//                         </button>
//                       </div>
//                     )}
//                   </div>
//                 ))}

//               {openComments[article._id] && (
//                 <div className={styles.addComment}>
//                   <input
//                     type="text"
//                     value={commentText}
//                     onChange={(e) => setCommentText(e.target.value)}
//                     placeholder="Add a comment..."
//                   />
//                   <button onClick={(e) => handleCommentSubmit(article._id, e)} className={styles.commentButton}>
//                     <AiOutlineComment size="1rem" />
//                     <span>Comment</span>
//                   </button>
//                 </div>
//               )}
//             </div>

//             <div style={{ display: 'flex' }}>
//               <div className={styles.intraction}>
//                 <div className={styles.icons}>
//                   <AiFillLike onClick={() => handleLike(article._id)} size="1rem" color={article.isLiked ? 'blue' : 'black'} />
//                   <span style={{ color: '#11a8e1' }}>{article.likes}</span>
//                   <button onClick={() => handleOpenArticleModal(article.likedBy)}>View Likes</button>
//                 </div>
//                 <div className={styles.icons}>
//                   <BsChatDots size="1rem" onClick={() => handleToggleComments(article._id)} />
//                   <span>{article.comments?.length}</span>
//                 </div>
//                 <div className={styles.icons}>
//                   <IoMdRepeat size="1rem" />
//                 </div>
//                 <div className={styles.icons}>
//                   <RiBookmarkLine size="1rem" />
//                 </div>
//               </div>
//               <div className={styles.icons}>
//                 <IoMdMore size="1rem" />
//               </div>
//             </div>
//           </div>
//         ))}

//       {isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
//       {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}
//     </div>
//   );
// };

// export default BlogTL;


// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchArticles, updateArticle, deleteArticle, likeArticle, commentArticle, replyComment,deleteComment ,editComment,likeComment} from '../../../Redux/articleSlice';

// import styles from './blog.module.css';
// import { RiBookmarkLine,RiDeleteBin5Line ,RiPencilLine} from 'react-icons/ri';
// import PostHeader from '../postHeader';
// import { AiOutlineRead, AiFillLike, AiOutlineComment } from 'react-icons/ai';
// import { IoMdRepeat, IoMdMore } from 'react-icons/io';
// import { BsChatDots } from 'react-icons/bs';
// import Modal from "./modal";
// import ArticleLikesModal from './articleLikeModel';
// const BlogTL = (props) => {
//   const dispatch = useDispatch();
//   const articles = useSelector((state) => state.articles.articles);
//   const profile = useSelector((state) => state.profiles.profiles);
//   const [editingArticle, setEditingArticle] = useState(null);
//   const [editedText, setEditedText] = useState('');
//   const [commentText, setCommentText] = useState('');
//   const [replyText, setReplyText] = useState('');
//   const [replyingToCommentId, setReplyingToCommentId] = useState(null);
//   const [openComments, setOpenComments] = useState({});
//   const [openReplies, setOpenReplies] = useState({});
//   const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
//   const [editedCommentText, setEditedCommentText] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
//   const [likedUsers, setLikedUsers] = useState([]);
 
//   const handleLikeComment = (articleId, commentId) => {
//     dispatch(likeComment({ articleId, commentId }));
//   };
 
//   const handleEditComment = (commentId, initialText) => {
//     setEditingCommentId(commentId);
//     setEditedCommentText(initialText);
//   };
  
//   const handleTextChangeComment = (value) => {
//     setEditedCommentText(value);
//   };
  


//   const handleReplySubmit = (articleId, commentId, e) => {
//     e.preventDefault(); // Prevent page refresh
//     if (replyText.trim() !== '') {
//       dispatch(replyComment({ articleId, commentId, text: replyText }))
//         .then(() => {
//           setReplyText('');
//           dispatch(fetchArticles()); // Fetch articles again to get updated comments
//         })
//         .catch((error) => {
//           console.error('Error submitting reply:', error);
//         });
//     } else {
//       console.error('Reply text cannot be empty');
//     }
//   };




//   const handleSaveEditComment = (articleId, commentId) => {
//     dispatch(editComment({ articleId, commentId, newText: editedCommentText }))
//       .then(() => {
//         alert('Comment edited successfully!');
//         setEditingCommentId(null);
//         setEditedCommentText('');
//         dispatch(fetchArticles()); // Fetch articles again to get updated comments
//       })
//       .catch((error) => {
//         alert('Error editing comment: ' + error.message);
//       });
//   };






 
//  // Delete Article Function //
 
//   const handleDelete = (articleId) => {
   
//     const isConfirmed = window.confirm('Are you sure you want to delete this post?');
//     if (!isConfirmed) {
//       return;
//     }
//     // Dispatch the delete comment action
//     dispatch(deleteArticle(articleId))
//       .then(() => {
//         // Optional: Show a success message or update the UI
//         console.log('deleted successfully');
//         dispatch(fetchArticles()); // Refresh articles after deletion
//       })
//       .catch((error) => {
//         // Optional: Handle error if comment deletion fails
//         console.error('Error  comment:', error);
//       });
   
//   };
 
//   // End Article Delete Function // 



 
 
 
 
//     // Delete Comment Function //
 
//     const handleDeleteComment = (articleId,commentId) => {
//     console.log(articleId)
//     console.log(commentId)
//     // Confirm deletion
//     const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
//     if (!isConfirmed) {
//       return;
//     }
//     // Dispatch the delete comment action
//     dispatch(deleteComment({articleId,commentId}))
//       .then(() => {
//         // Optional: Show a success message or update the UI
//         console.log('Comment deleted successfully');
//         dispatch(fetchArticles()); // Refresh articles after deletion
//       })
//       .catch((error) => {
//         // Optional: Handle error if comment deletion fails
//         console.error('Error deleting comment:', error);
//       });
//   };


//           // End Comment Function //



//         // Edit Article Functions //

//   const handleEdit = (articleId, initialText) => {
//     setEditingArticle(articleId);
//     setEditedText(initialText);
//   };

//   const handleTextChange = (value) => {
//     setEditedText(value);
//   };

//   const handleSaveEdit = () => {
//     dispatch(updateArticle({ id: editingArticle, articleData: { text: editedText } }))
//       .then((result) => {
//         alert('Post edited successfully!');
//         setEditingArticle(null);
//         setEditedText('');
//         dispatch(fetchArticles());
//       })
//       .catch((error) => {
//         alert('Error editing post: ' + error.message);
//       });
//   };


//         //  End Edit Article Functions //






//   // Handle Like Function //

//   const handleLike = (articleId) => {
    
//     dispatch(likeArticle({ articleId }));
//     dispatch(fetchArticles());
//   };

  
//   // End Like Function //
  
  



//   // Handle Comment Close Open Toggler //
  
  
//   const handleToggleComments = (articleId) => {
//     setOpenComments(prevState => ({
//       ...prevState,
//       [articleId]: !prevState[articleId]
//     }));
//   };

//     // End Handle Comment Close Open Toggler //

//     const handleToggleReplies = (commentId) => {
//       setOpenReplies((prevOpenReplies) => ({
//         ...prevOpenReplies,
//         [commentId]: !prevOpenReplies[commentId]
//       }));
//     };
  


//   // Add Comment Function //
  
//   const handleCommentSubmit = (articleId, e) => {
//     e.preventDefault(); // Prevent page refresh
//     if (commentText.trim() !== '') {
//       dispatch(commentArticle({ articleId, text: commentText }))
//         .then(() => {
//           setCommentText('');
//           dispatch(fetchArticles()); // Fetch articles again to get updated comments
//         })
//         .catch((error) => {
//           console.error('Error submitting comment:', error);
//         });
//     } else {
//       console.error('Comment text cannot be empty');
//     }
//   };

//    // End Comment Function //

//    const handleOpenModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalOpen(true);
//   };


//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setLikedUsers([]);
//   };


//   const handleOpenArticleModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalOpen(true);
  
//   }


//   const handleArticleCloseModal = () => {
//     setIsModalArticleOpen(false);
//     setLikedUsers([]);
//   };










  

//   //  const handleOpenModal = (likedBy) => {
//   //   const users = likedBy.map((userId) => {
//   //     return profile[userId]; // Access profile directly by user ID
//   //   }).filter(profile => profile); // Filter out undefined profiles
//   //   setLikedUsers(users);
//   //   setIsModalOpen(true);
//   // };
//   // const handleCloseModal = () => {
//   //   setIsModalOpen(false);
//   //   setLikedUsers([]);
//   // };



//   useEffect(() => {
//     dispatch(fetchArticles());
//   }, [dispatch]);

  
  
  
  
//   return (
//     <div className={styles.container}>
//       {articles && articles.map((article) => (
//         <div key={article._id}>
//           <PostHeader icon={<AiOutlineRead size={'1rem'} />} />

//           {editingArticle === article._id ? (
//             <div>
//               <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
//               <button onClick={handleSaveEdit}>Save</button>
//             </div>
//           ) : (
//             <div>
//               <div className={styles.content} dangerouslySetInnerHTML={{ __html: article.text }} />
//               {article.file && (
//                 <img src={require(`../../../images/${article.file.name}`).default} alt="Article" style={{ width: '100%', height: 'auto' }} />
//               )}
//               <button onClick={() => handleDelete(article._id)}>Delete</button>
//               <button onClick={() => handleEdit(article._id, article.text)}>Edit</button>
//             </div>
//           )}

//           <div className={styles.commentSection}>
//             {openComments[article._id] && article.comments && article.comments.map((comment) => (
//               <div key={comment._id} className={styles.comment}>
                 
                 
                 
//                 <div>  
//                   <img alt="profile picture"  src="https://randomuser.me/api/portraits/men/28.jpg" style={{width: "42px", borderRadius:"42px",marginTop:"10px"}} />
//                 </div>
                     
                     
//                 <div style={{ marginLeft: "40px", backgroundColor: "#e6e6e6", borderRadius: "10px", padding: "10px" }}>
//                       <div>
//                         <strong>{comment.firstName} {comment.lastName}</strong>
//                       </div>
//                       <div>{comment.text}</div>
//                       {/* <button onClick={() => handleDeleteReply(article._id, comment._id, reply._id)}>Delete Reply</button> */}
//                     </div>
                     
                     
//                 {/* <div style={{display:"-ms-inline-flexbox", marginTop:"30px" ,width:"fit-content", backgroundColor:"#4fb59330" , borderRadius:"10px"}} className={styles.commentText}>{comment.text}</div> */}

              
                
//                   <RiDeleteBin5Line size={20}  style={{marginTop:"10px"}}  onClick={() => handleDeleteComment(article._id,comment._id)}/>
//                <RiPencilLine size={20} onClick={() => handleEditComment(comment._id, comment.text)} />
//                <AiFillLike size="1rem" onClick={() => handleLikeComment(article._id,comment._id)}/>
//            <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
//            <button onClick={() => handleOpenModal(comment.likedBy)}>
//                   View Likes
//                 </button>
               
//                 <button onClick={() => setReplyingToCommentId(comment._id)}>Reply</button>

              
//                 {comment.replies && comment.replies.length > 0 && (
//                   <button onClick={() => handleToggleReplies(comment._id)}>
//                     {openReplies[comment._id] ? 'Hide Replies' : `View Replies (${comment.replies.length})`}
//                   </button>
//                 )}
                
//                 {openReplies[comment._id] && comment.replies && comment.replies.map((reply) => (
//                   <div key={reply._id} className={styles.reply}>
//                      <div>  
//                   <img alt="profile picture"  src="https://randomuser.me/api/portraits/men/28.jpg" style={{width: "42px", borderRadius:"42px",marginTop:"10px"}} />
//                 </div>
//                     <div style={{ marginLeft: "40px", backgroundColor: "#e6e6e6", borderRadius: "10px", padding: "10px" }}>
//                       <div>
//                         <strong>{reply.firstName} {reply.lastName}</strong>
//                       </div>
//                       <div>{reply.text}</div>
//                       {/* <button onClick={() => handleDeleteReply(article._id, comment._id, reply._id)}>Delete Reply</button> */}
//                     </div>
//                   </div>
//                 ))}
              


//     {editingCommentId === comment._id ? (
//       <div>
//         <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
//         <button onClick={() => handleSaveEditComment(article._id, comment._id)}>Save</button>
//       </div>
//     ) : null}


//              {replyingToCommentId === comment._id && (
//                   <div className={styles.replySection}>
//                     <input
//                       type="text"
//                       value={replyText}
//                       onChange={(e) => setReplyText(e.target.value)}
//                       placeholder="Write a reply..."
//                     />
//                     <button onClick={(e) => handleReplySubmit(article._id, comment._id, e)}>
//                       <AiOutlineComment size="1rem" />
//                       <span>Reply</span>
//                     </button>
//                   </div>
//                 )}
                


//                </div>


           
//            ))}











             
//             {openComments[article._id] && (
//               <div className={styles.addComment}>
//                 <input
//                   type="text"
//                   value={commentText}
//                   onChange={(e) => setCommentText(e.target.value)}
//                   placeholder="Add a comment..."
//                 />
//                 <button onClick={(e) => handleCommentSubmit(article._id, e)} className={styles.commentButton}>
//                   <AiOutlineComment size="1rem" />
//                   <span>Comment</span>
//                 </button>
//               </div>
//             )}
//           </div>

//           <div style={{ display: 'flex' }}>
//             <div className={styles.intraction}>
//               <div className={styles.icons} >
//                 <AiFillLike onClick={() => handleLike(article._id)} size="1rem" color={article.isLiked ? 'blue' : 'black'} />
//                 <span style={{ color: '#11a8e1' }}>{article.likes}</span>
//                 <button onClick={() => handleOpenArticleModal(article.likedBy)}>
//                   View Likes
//                 </button>
//               </div>
//               <div className={styles.icons}>
//                 <BsChatDots size="1rem" onClick={() => handleToggleComments(article._id)} />
//                 <span>{article.comments?.length}</span>
//               </div>
//               <div className={styles.icons}>
//                 <IoMdRepeat size="1rem" />
//               </div>
//               <div className={styles.icons}>
//                 <RiBookmarkLine size="1rem" />
//               </div>
//             </div>
//             <div className={styles.icons}>
//               <IoMdMore size="1rem" />
//             </div>
//           </div>
//         </div>
//       ))}


// {isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
// {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}
//     </div>
//   );
// };

// export default BlogTL;
















// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import {
//   fetchArticles,
//   updateArticle,
//   deleteArticle,
//   likeArticle,
//   commentArticle,
//   replyComment,
//   deleteComment,
//   editComment,
//   likeComment
// } from '../../../Redux/articleSlice';
// import styles from './blog.module.css';
// import { RiBookmarkLine, RiDeleteBin5Line, RiPencilLine } from 'react-icons/ri';
// import PostHeader from '../postHeader';
// import { AiOutlineRead, AiFillLike } from 'react-icons/ai';
// import Modal from "./modal";
// import ArticleLikesModal from './articleLikeModel';

// const BlogTL = (props) => {
//   const dispatch = useDispatch();
//   const articles = useSelector((state) => state.articles.articles);
//   const profile = useSelector((state) => state.profiles.profiles);
//   const [editingArticle, setEditingArticle] = useState(null);
//   const [editedText, setEditedText] = useState('');
//   const [commentText, setCommentText] = useState('');
//   const [replyText, setReplyText] = useState('');
//   const [replyingToCommentId, setReplyingToCommentId] = useState(null);
//   const [replyingToReplyId, setReplyingToReplyId] = useState(null);
//   const [openComments, setOpenComments] = useState({});
//   const [openReplies, setOpenReplies] = useState({});
//   const [editingCommentId, setEditingCommentId] = useState(null);
//   const [editedCommentText, setEditedCommentText] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
//   const [likedUsers, setLikedUsers] = useState([]);

//   const handleLikeComment = (articleId, commentId) => {
//     dispatch(likeComment({ articleId, commentId }));
//   };

//   const handleEditComment = (commentId, initialText) => {
//     setEditingCommentId(commentId);
//     setEditedCommentText(initialText);
//   };

//   const handleTextChangeComment = (value) => {
//     setEditedCommentText(value);
//   };

//   const handleReplySubmit = (articleId, commentId, replyId, e) => {
//     e.preventDefault();
//     if (replyText.trim() !== '') {
//       dispatch(replyComment({ articleId, commentId, replyId, text: replyText }))
//         .then(() => {
//           setReplyText('');
//           dispatch(fetchArticles());
//         })
//         .catch((error) => {
//           console.error('Error submitting reply:', error);
//         });
//     } else {
//       console.error('Reply text cannot be empty');
//     }
//   };

//   const handleSaveEditComment = (articleId, commentId) => {
//     dispatch(editComment({ articleId, commentId, newText: editedCommentText }))
//       .then(() => {
//         alert('Comment edited successfully!');
//         setEditingCommentId(null);
//         setEditedCommentText('');
//         dispatch(fetchArticles());
//       })
//       .catch((error) => {
//         alert('Error editing comment: ' + error.message);
//       });
//   };

//   const handleDelete = (articleId) => {
//     const isConfirmed = window.confirm('Are you sure you want to delete this post?');
//     if (!isConfirmed) {
//       return;
//     }
//     dispatch(deleteArticle(articleId))
//       .then(() => {
//         console.log('deleted successfully');
//         dispatch(fetchArticles());
//       })
//       .catch((error) => {
//         console.error('Error deleting article:', error);
//       });
//   };

//   const handleDeleteComment = (articleId, commentId) => {
//     const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
//     if (!isConfirmed) {
//       return;
//     }
//     dispatch(deleteComment({ articleId, commentId }))
//       .then(() => {
//         console.log('Comment deleted successfully');
//         dispatch(fetchArticles());
//       })
//       .catch((error) => {
//         console.error('Error deleting comment:', error);
//       });
//   };

//   const handleEdit = (articleId, initialText) => {
//     setEditingArticle(articleId);
//     setEditedText(initialText);
//   };

//   const handleTextChange = (value) => {
//     setEditedText(value);
//   };

//   const handleSaveEdit = () => {
//     dispatch(updateArticle({ id: editingArticle, articleData: { text: editedText } }))
//       .then(() => {
//         alert('Post edited successfully!');
//         setEditingArticle(null);
//         setEditedText('');
//         dispatch(fetchArticles());
//       })
//       .catch((error) => {
//         alert('Error editing post: ' + error.message);
//       });
//   };

//   const handleLike = (articleId) => {
//     dispatch(likeArticle({ articleId }));
//     dispatch(fetchArticles());
//   };

//   const handleToggleComments = (articleId) => {
//     setOpenComments(prevState => ({
//       ...prevState,
//       [articleId]: !prevState[articleId]
//     }));
//   };

//   const handleToggleReplies = (commentId) => {
//     setOpenReplies((prevOpenReplies) => ({
//       ...prevOpenReplies,
//       [commentId]: !prevOpenReplies[commentId]
//     }));
//   };

//   const handleCommentSubmit = (articleId, e) => {
//     e.preventDefault();
//     if (commentText.trim() !== '') {
//       dispatch(commentArticle({ articleId, text: commentText }))
//         .then(() => {
//           setCommentText('');
//           dispatch(fetchArticles());
//         })
//         .catch((error) => {
//           console.error('Error submitting comment:', error);
//         });
//     } else {
//       console.error('Comment text cannot be empty');
//     }
//   };

//   const handleOpenModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setLikedUsers([]);
//   };

//   const handleOpenArticleModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalArticleOpen(true);
//   };

//   const handleArticleCloseModal = () => {
//     setIsModalArticleOpen(false);
//     setLikedUsers([]);
//   };

//   const renderReplies = (articleId, commentId, replies) => {
//     return replies.map((reply) => (
//       <div key={reply._id} className={styles.reply}>
//         <div style={{ marginLeft: "40px", backgroundColor: "#e6e6e6", borderRadius: "10px", padding: "10px" }}>
//           <div>
//             <strong>{reply.firstName} {reply.lastName}</strong>
//           </div>
//           <div>{reply.text}</div>
//           <RiDeleteBin5Line size={20} style={{ marginTop: "10px" }} onClick={() => handleDeleteComment(articleId, reply._id)} />
//           <RiPencilLine size={20} onClick={() => handleEditComment(reply._id, reply.text)} />
//           <AiFillLike size="1rem" onClick={() => handleLikeComment(articleId, reply._id)} />
//           <span style={{ color: '#11a8e1' }}>{reply.likes}</span>
//           <button onClick={() => handleToggleReplies(reply._id)}>
//             {openReplies[reply._id] ? 'Hide Replies' : `View Replies (${reply.replies?.length || 0})`}
//           </button>
//           {openReplies[reply._id] && reply.replies && renderReplies(articleId, commentId, reply.replies)}
//           {replyingToReplyId === reply._id && (
//             <form onSubmit={(e) => handleReplySubmit(articleId, commentId, reply._id, e)}>
//               <input type="text" value={replyText} onChange={(e) => setReplyText(e.target.value)} />
//               <button type="submit">Reply</button>
//             </form>
//           )}
//           <button onClick={() => setReplyingToReplyId(reply._id)}>Reply</button>
//         </div>
//       </div>
//     ));
//   };

//   useEffect(() => {
//     dispatch(fetchArticles());
//   }, [dispatch]);

//   return (
//     <div className={styles.container}>
//       {articles && articles.map((article) => (
//         <div key={article._id}>
//           <PostHeader icon={<AiOutlineRead size={'1rem'} />} />
//           {editingArticle === article._id ? (
//             <div>
//               <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
//               <button onClick={handleSaveEdit}>Save</button>
//             </div>
//           ) : (
//             <div>
//               <div className={styles.content} dangerouslySetInnerHTML={{ __html: article.text }} />
//               {article.file && (
//                 <img src={require(`../../../images/${article.file.name}`).default} alt="Article" style={{ width: '100%', height: 'auto' }} />
//               )}
//               <button onClick={() => handleDelete(article._id)}>Delete</button>
//               <button onClick={() => handleEdit(article._id, article.text)}>Edit</button>
//             </div>
//           )}

//           <div className={styles.commentSection}>
//             {openComments[article._id] && article.comments && article.comments.map((comment) => (
//               <div key={comment._id} className={styles.comment}>
//                 <div style={{ marginLeft: "20px", backgroundColor: "#f2f2f2", borderRadius: "10px", padding: "10px" }}>
//                   <div>
//                     <strong>{comment.firstName} {comment.lastName}</strong>
//                   </div>
//                   <div>{comment.text}</div>
//                   <RiDeleteBin5Line size={20} style={{ marginTop: "10px" }} onClick={() => handleDeleteComment(article._id, comment._id)} />
//                   <RiPencilLine size={20} onClick={() => handleEditComment(comment._id, comment.text)} />
//                   <AiFillLike size="1rem" onClick={() => handleLikeComment(article._id, comment._id)} />
//                   <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
//                   <button onClick={() => handleToggleReplies(comment._id)}>
//                     {openReplies[comment._id] ? 'Hide Replies' : `View Replies (${comment.replies?.length || 0})`}
//                   </button>
//                   {openReplies[comment._id] && comment.replies && renderReplies(article._id, comment._id, comment.replies)}
//                   {replyingToCommentId === comment._id && (
//                     <form onSubmit={(e) => handleReplySubmit(article._id, comment._id, null, e)}>
//                       <input type="text" value={replyText} onChange={(e) => setReplyText(e.target.value)} />
//                       <button type="submit">Reply</button>
//                     </form>
//                   )}
//                   <button onClick={() => setReplyingToCommentId(comment._id)}>Reply</button>
//                 </div>
//               </div>
//             ))}

//             {editingCommentId && (
//               <div>
//                 <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
//                 <button onClick={() => handleSaveEditComment(article._id, editingCommentId)}>Save</button>
//               </div>
//             )}

//             <form onSubmit={(e) => handleCommentSubmit(article._id, e)}>
//               <input type="text" value={commentText} onChange={(e) => setCommentText(e.target.value)} />
//               <button type="submit">Comment</button>
//             </form>
//             <button onClick={() => handleToggleComments(article._id)}>
//               {openComments[article._id] ? 'Hide Comments' : 'Show Comments'}
//             </button>
//           </div>
//           <div>
//             <AiFillLike size="1.5rem" onClick={() => handleLike(article._id)} />
//             <span style={{ color: '#11a8e1' }}>{article.likes.length}</span>
//             <button onClick={() => handleOpenArticleModal(article.likes)}>Likes</button>
//           </div>
//         </div>
//       ))}
//       {isModalOpen && (
//         <Modal onClose={handleCloseModal}>
//           <ArticleLikesModal likedUsers={likedUsers} />
//         </Modal>
//       )}
//       {isModalArticleOpen && (
//         <Modal onClose={handleArticleCloseModal}>
//           <ArticleLikesModal likedUsers={likedUsers} />
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default BlogTL;


