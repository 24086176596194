import { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import styles from './search.module.css';
import Jobs from '../../pages/home/Jobs';
import Issue from '../../pages/home/issue';
import Article from '../../pages/home/article';
import Learn from '../../pages/home/learn';
import Lib from '../../pages/lib/lib';

const Search = () => {
  // State to track the active tab
  const [activeTab, setActiveTab] = useState('Jobs'); // Default tab is Jobs

  // Function to render the appropriate component based on the active tab
  const renderContent = () => {
    switch (activeTab) {
      case 'Jobs':
        return <Jobs />;
      case 'Issue':
        return <Issue />;
      case 'Article':
        return <Article />;
      case 'Learn':
        return <Learn />;
      case 'Library':
        return <Lib />;
      default:
        return <Jobs />;
    }
  };

  return (
    <>
      <div className={styles.container}>
   
        {/* <div className={styles.search}>
          <input placeholder="Search" />
          <AiOutlineSearch size={30} />
        </div> */}

        {/* Tab Navigation */}
        <div className={styles.filters}>
          {/* Jobs Tab */}
          <div
            onClick={() => setActiveTab('Jobs')}
            style={{
              backgroundColor: activeTab === 'Jobs' ? 'var(--primary)' : '',
              color: activeTab === 'Jobs' ? '#fff' : '',
              cursor: 'pointer',
            }}
          >
            Jobs
          </div>

          {/* Issue Tab */}
          <div
            onClick={() => setActiveTab('Issue')}
            style={{
              backgroundColor: activeTab === 'Issue' ? 'var(--primary)' : '',
              color: activeTab === 'Issue' ? '#fff' : '',
              cursor: 'pointer',
            }}
          >
            Issue
          </div>

          {/* Article Tab */}
          <div
            onClick={() => setActiveTab('Article')}
            style={{
              backgroundColor: activeTab === 'Article' ? 'var(--primary)' : '',
              color: activeTab === 'Article' ? '#fff' : '',
              cursor: 'pointer',
            }}
          >
            Article
          </div>

          {/* Learn Tab */}
          <div
            onClick={() => setActiveTab('Learn')}
            style={{
              backgroundColor: activeTab === 'Learn' ? 'var(--primary)' : '',
              color: activeTab === 'Learn' ? '#fff' : '',
              cursor: 'pointer',
            }}
          >
            Learn
          </div>

          {/* Library Tab */}
          <div
            onClick={() => setActiveTab('Library')}
            style={{
              backgroundColor: activeTab === 'Library' ? 'var(--primary)' : '',
              color: activeTab === 'Library' ? '#fff' : '',
              cursor: 'pointer',
            }}
          >
            Library
          </div>
        </div>
      </div>

      {/* Dynamic Content Based on Active Tab */}
      <div className={styles.container}>
        {renderContent()}
      </div>
    </>
  );
};

export default Search;


// // import  { useState } from 'react';
// import { AiOutlineSearch } from 'react-icons/ai';
// import styles from './search.module.css'
// import Jobs from '../../pages/home/Jobs';
// import Issue from '../../pages/home/issue';
// import Article from '../../pages/home/article';
// import Learn from '../../pages/home/learn';
// import Lib from '../../pages/lib/lib';
// import { Link, useLocation } from 'react-router-dom';

// const Search=()=>{

//     // const [filter,setFilter]=useState('job')


//     return(
//         <>
//         <div className={styles.container}>

     





// {/*          
//             <div className={styles.search}>
//                 <input placeholder='search' />
//                 <AiOutlineSearch size={30}/>
//             </div> */}






//             <div className={styles.filters} >

//             <Link 
//                   to="/job">
//                 <div style={{backgroundColor:'var(--primary)',color:'#fff'}} >Jobs</div>
//               </Link>


//                 <Link  to="/issue" >
//                 <div >Issue</div>
//                 </Link>
//                 <Link  to="/articles" >
//                 <div>Article</div>
//                 </Link>
//                 <Link  to="/learn" >
//                 <div>Learn</div>
//                 </Link>
//                 <Link  to="/lib" >
//                 <div>Libary</div>
//                 </Link>
           
                
                
//             </div>






//                    </div>



//    <div className={styles.Tabcont}>

// <Jobs/>

//    </div>
//    </>

//     )
// }


// export default Search