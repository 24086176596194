
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pusher from 'pusher-js'; // Import Pusher
import { fetchNotifications } from '../../Redux/notificationSlice'; // Adjust path as necessary
import styles from './Header.module.css';

const Notification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.notifications);
  const profile = useSelector((state) => state.profiles.profiles);
  const userId = profile.userId;

  // Local state to handle notifications toggle
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  useEffect(() => {
    // Initial fetch of notifications when the component loads
    dispatch(fetchNotifications(userId));

    // Initialize Pusher with the key and cluster from your Pusher dashboard
    const pusher = new Pusher('8d13c6a59a85bd1417e7', {
      cluster: 'eu', // Make sure this matches your Pusher cluster
    });

    // Subscribe to the 'article-channel' (or whatever channel name you have)
    const channel = pusher.subscribe('article-channel');

    // Listen for 'like-article' event (replace this with your actual event name)
    channel.bind('like-article', (newNotification) => {
      console.log('New notification received:', newNotification);

      // Fetch notifications again or update the state with the new notification
      dispatch(fetchNotifications(userId));
    });

    // Cleanup the subscription when the component unmounts
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [dispatch, userId]);

  return (
    <>
      <div className={styles.notification}>
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <div key={notification._id}>
              {notification.message}
            </div>
          ))
        ) : (
          <div className={styles.noNotifications}>No notifications</div>
        )}
      </div>
    </>
  );
};

export default Notification;


// // Notification.js
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchNotifications } from '../../Redux/notificationSlice'; // Adjust path as necessary
// import styles from './Header.module.css'
// import { io } from 'socket.io-client';


// const Notification = () => {
//   const dispatch = useDispatch();

//   const notifications = useSelector((state) => state.notifications.notifications);
// //   console.log(notifications[0].message)
//   const profile = useSelector((state) => state.profiles.profiles);

//   const [isNotificationOpen, setIsNotificationOpen] = useState(false);
//   const userId = profile.userId; 

//   useEffect(() => {
//     dispatch(fetchNotifications(userId));

//     const socket = io('http://localhost:7000'); // Backend URL
//     socket.emit('join', userId);

//     socket.on('notification', (newNotification) => {
//       // Fetch notifications again or update state to include new notification
//       dispatch(fetchNotifications(userId));
//     });

//     return () => {
//       socket.disconnect();
//     };
//   }, [dispatch, userId]);

//   // useEffect(() => {
//   //   const userId = profile.userId
//   //   dispatch(fetchNotifications(userId));
//   // }, [dispatch,profile.id]);


  
//   // const toggleNotifications = () => {
//   //   setIsNotificationOpen(!isNotificationOpen);
//   // };
// //   const messages = notifications.map(notification => notification.message);
//   return (
// <>

// <model className={styles.notification}>

// {notifications.length > 0 ? (
//         notifications.map((notification) => (
//           <div key={notification._id} >
//             {notification.message}
//           </div>
//         ))
//       ) : (
//         <div className={styles.noNotifications}>No notifications</div>
//       )}
// </model>
//     {/* {messages.length > 0 ? (
//       messages.map((message, index) => (
//         <div key={index} >
//           {message}
//         </div>
//       ))
//     ) : (
//       <div className={styles.noNotifications}>No notifications</div>
//     )} */}
//  </>
//   );
// };

// export default Notification;











// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { socket } from '../../Socket/socket';
// import styles from './Header.module.css'
// import axios from 'axios';
// const Notifications = () => {
//   const [notifications, setNotifications] = useState([]);
//   const profile = useSelector((state) => state.profiles.profiles);

//   useEffect(() => {
//     // Fetch initial notifications
//     const fetchNotifications = async () => {
//         try {
//             const userId = profile.userId
//           const response = await axios.get(`http://localhost:7000/api/post/notifications/${userId}`);
//           console.log(response)
//           setNotifications(response.data);
//         } catch (error) {
//           if (error.response) {
//             console.error('Server responded with a status:', error.response.status);
//             console.error('Response data:', error.response.data);
//           } else if (error.request) {
//             console.error('No response received:', error.request);
//           } else {
//             console.error('Error setting up request:', error.message);
//           }
//         }
//       };
  
//       fetchNotifications();

//     // Listen for real-time notifications
//     socket.on('notification', (notification) => {
//       setNotifications((prevNotifications) => [notification, ...prevNotifications]);
//     });

//     // Clean up the socket listener
//     return () => {
//       socket.off('notification');
//     };
//   }, [profile.userId]);

//   return (
//     <model className={styles.notification}>
      
//       {notifications.map((notification) => (
//         <div key={notification._id} className={styles.notification}>
//           {notification.message}
//         </div>
//       ))}
//    </model>
//   );
// };

// export default Notifications;





// import React from 'react';
// import styles from './Header.module.css'

// const Notification =()=>{
//     return(
//        <model className={styles.notification}>
//            <div className={styles.notiItem}  >
//             <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
//             <div className={styles.noticontent} >
//                 <div>James liked your post.</div>
//                 <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
//             </div>
//            </div>
//            <div className={styles.notiItem}  >
//             <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
//             <div className={styles.noticontent} >
//                 <div>James liked your post.</div>
//                 <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
//             </div>
//            </div> <div className={styles.notiItem}  >
//             <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
//             <div className={styles.noticontent} >
//                 <div>James liked your post.</div>
//                 <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
//             </div>
//            </div> <div className={styles.notiItem}  >
//             <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
//             <div className={styles.noticontent} >
//                 <div>James liked your post.</div>
//                 <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
//             </div>
//            </div> <div className={styles.notiItem}  >
//             <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
//             <div className={styles.noticontent} >
//                 <div>James liked your post.</div>
//                 <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
//             </div>
//            </div> <div className={styles.notiItem}  >
//             <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
//             <div className={styles.noticontent} >
//                 <div>James liked your post.</div>
//                 <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
//             </div>
//            </div> <div className={styles.notiItem}  >
//             <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
//             <div className={styles.noticontent} >
//                 <div>James liked your post.</div>
//                 <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
//             </div>
//            </div> <div className={styles.notiItem}  >
//             <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
//             <div className={styles.noticontent} >
//                 <div>James liked your post.</div>
//                 <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
//             </div>
//            </div> <div className={styles.notiItem}  >
//             <img src="https://www.pixinvent.com/materialize-material-design-admin-template/laravel/demo-4/images/user/12.jpg" /> 
//             <div className={styles.noticontent} >
//                 <div>James liked your post.</div>
//                 <div style={{color:"#464646",fontSize:'small'}} >2 min ago</div>
//             </div>
//            </div>
           
           

//        </model>
//     )
// }

// export default Notification