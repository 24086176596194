// import React, { useEffect, useState } from 'react';
// import styles from './lib.module.css'
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchLibary } from '../../../Redux/libarySlice';




// const LibTl =()=>{
//     const dispatch = useDispatch();
    
    
    
//     const libarys = useSelector((state) => state.libarys.libarys);
//    console.log(libarys) 

     
//     useEffect(() => {
//         dispatch(fetchLibary());
//       }, [dispatch]);


    
    
//     return(
//         <div className={styles.container}>
//         {libarys && libarys.map((libary)=>(

//         <div key={libary._id}>




//             <div  className={styles.col}>
//             <img src={libary.imageUrl}  />
//             <div style={{fontWeight:"700",fontSize:"1.3rem"}}>{libary.productName}</div>
//             <div></div>
//             <button>Download</button>
//            <div>

//            </div>
//             </div>
            
            
//             {/* <div  className={styles.col}>
//             <img src="https://admincontent.bimobject.com/public/productimages/858a5779-5744-4e52-8e68-877977e6d894/1db1080f-e0c9-430d-b064-28bfa643db81/503124?width=675&height=675"/>
//             <div style={{fontWeight:"700",fontSize:"1.3rem"}}>Cantilever school chair COGNITA</div>
//             <div></div>
//             <button>Download</button>
           
           
//             </div> */}
              
//             </div> 
//               )) }
//         </div>

   


//     )
// }


// export default LibTl;


// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchLibary } from '../../../Redux/libarySlice';
// import styles from './lib.module.css';

// const LibTl = () => {
//   const dispatch = useDispatch();
//   const { libarys, status, totalPages, currentPage } = useSelector((state) => state.libarys);

//   const [page, setPage] = useState(1);
//   const limit = 10; // Number of items per page

//   useEffect(() => {
//     dispatch(fetchLibary({ page, limit }));
//   }, [dispatch, page]);

//   const handlePageChange = (newPage) => {
//     setPage(newPage);
//   };

//   return (
//     <div className={styles.container}>
//       {status === 'loading' && <p>Loading...</p>}
//       {status === 'failed' && <p>Error loading data.</p>}
//       {libarys && libarys.map((libary) => (
//         <div key={libary._id} className={styles.col}>
//           <img src={libary.imageUrl} alt={libary.productName} />
//           <div style={{ fontWeight: '700', fontSize: '1.3rem' }}>{libary.productName}</div>
//           <button>Download</button>
//         </div>
//       ))}
//       <div className={styles.pagination}>
//         <button
//           onClick={() => handlePageChange(page - 1)}
//           disabled={page === 1}
//         >
//           Previous
//         </button>
//         {Array.from({ length: totalPages }, (_, index) => (
//           <button
//             key={index}
//             onClick={() => handlePageChange(index + 1)}
//             className={index + 1 === currentPage ? styles.active : ''}
//           >
//             {index + 1}
//           </button>
//         ))}
//         <button
//           onClick={() => handlePageChange(page + 1)}
//           disabled={page === totalPages}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default LibTl;



import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLibary,searchLibraries } from '../../../Redux/libarySlice';
import styles from './lib.module.css';

const LibTl = ({ showSearchBar = true }) => {
  const dispatch = useDispatch();
  const { libarys, status, totalPages, currentPage,searchResults, searchStatus, searchError  } = useSelector((state) => state.libarys);

  const [page, setPage] = useState(1);
  const limit = 10; // Number of items per page
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    dispatch(fetchLibary({ page, limit }));
  }, [dispatch, page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };




  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchLibraries(searchQuery));
  };


  return (

<>

<div className={styles.container}>
{showSearchBar && (
          <form onSubmit={handleSearch} className={styles.searchForm}>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for libraries..."
              className={styles.searchInput}
            />
            <button type="submit" className={styles.searchButton}>Search</button>
          </form>
        )}


      {searchStatus === 'loading' && <p>Loading...</p>}
      {searchStatus === 'failed' && <p>Error: {searchError}</p>}
      {searchStatus === 'succeeded' && (
        <div className={styles.resultsContainer}>
          {searchResults.length > 0 ? (
            searchResults.map((lib) => (
              <div key={lib._id} className={styles.libCard}>
                <h3 className={styles.libTitle}>{lib.productName}</h3>
                <img src={lib.imageUrl} alt={lib.productName} className={styles.libImage} />
              </div>
            ))
          ) : (
            <p>No libraries found</p>
          )}
        </div>
      )}
    </div>





    <div className={styles.container}>
      {status === 'loading' && <p>Loading...</p>}
      {status === 'failed' && <p>Error loading data.</p>}
      {libarys && libarys.map((libary) => (
        <div key={libary._id} className={styles.col}>
          <img src={libary.imageUrl} alt={libary.productName} />
          <div style={{ fontWeight: '700', fontSize: '1.3rem' }}>{libary.productName}</div>
          <button>Download</button>
        </div>
      ))}
      <div className={styles.pagination}>
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? styles.active : ''}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
    </div>
    </>
  );
};

export default LibTl;
