import React, { useEffect, useState } from 'react';
import PostHeader from '../postHeader'
import styles from '../blog/blog.module.css';
import Autosuggest from 'react-autosuggest';
import Jobapis from './jobapis';
import {IoIosCheckmarkCircleOutline,IoMdRepeat,IoMdMore} from 'react-icons/io'
import { useSelector, useDispatch } from 'react-redux';
import { BsChatDots } from 'react-icons/bs';
import { fetchJobs ,updateJob,deleteJob,likeJob,likeComment,deleteComment,editComment, commentJob,shareJob,searchJobs,searchJobsInDB

} from '../../../Redux/jobSlice';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FaEdit } from "react-icons/fa";
import { MentionsInput, Mention } from 'react-mentions';
import { AiOutlineRead, AiFillLike ,AiOutlineComment} from 'react-icons/ai';
import { RiBookmarkLine, RiDeleteBin5Line, RiPencilLine} from 'react-icons/ri';

import { MdEdit } from "react-icons/md";
import { MdDeleteSweep } from "react-icons/md";
import { io } from 'socket.io-client';
import { fetchNotifications } from '../../../Redux/notificationSlice';
import {socket , connectSocket, emitEvent} from "../../../Socket/socket"
import { IoEyeSharp } from "react-icons/io5";
import Modal from './modal';
import JobLikesModal from './jobLikeModal'
import ArticleLikesModal from '../blog/articleLikeModel'
import { getCurrencySymbol } from './currencyUtils';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { fetchAllProfiles } from '../../../Redux/profileSlice';
import Pusher from 'pusher-js';

// Autosuggest theme
const theme = {
  container: {
    position: 'relative',
  },
  input: {
    width: '240px',
    height: '30px',
    padding: '10px 20px',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: '16px',
    border: '1px solid #aaa',
    borderRadius: '4px',
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: '51px',
    width: '240px',
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: '16px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    zIndex: 2,
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px',
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
};



const JobTl= ({ showSearchBar = true })=>{
    
    
    
    
    
    const dispatch = useDispatch();
    // const jobs = useSelector((state) => state.jobs.jobs);
    const { jobs, status, totalPages, currentPage , searchResults, searchStatus, searchError } = useSelector((state) => state.jobs);
    const profile = useSelector((state) => state.profiles.profiles);
    const allProfiles = useSelector((state) => state.profiles.allProfiles);
    const [editingJob, setEditingJob] = useState(null);
    const [editedText, setEditedText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
   const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
   const [openComments, setOpenComments] = useState({});
    const [likedUsers, setLikedUsers] = useState([])
    const [commentText, setCommentText] = useState('');
    const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
  const [editedCommentText, setEditedCommentText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [suggestions, setSuggestions] = useState([]);
  const [locationTerm, setLocationTerm] = useState('');
  const [countryTerm, setCountryTerm] = useState('');
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const { userId } = useParams(); 
  const navigate = useNavigate();
  const limit = 10; // Number of items per page
    // const userId = profile.userId; 
console.log(searchResults)

    const handlePageChange = (newPage) => {
      setPage(newPage);
    };



    dayjs.extend(relativeTime);



 // Pusher setup
 useEffect(() => {
  const pusher = new Pusher('8d13c6a59a85bd1417e7', {
    cluster: 'eu',
  });

  // Subscribe to the article channel
  const channel = pusher.subscribe('job-channel');

  channel.bind('new-mention', (newNotification) => {
    console.log('Mention Notification: ', newNotification);
    dispatch(fetchNotifications(userId)); 
    // Show real-time notification to the user
  });

  // Listen for the 'like-article' event and update notifications
  channel.bind('like-job', (newNotification) => {
    console.log('New notification received:test', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });


  channel.bind('like-comment-job', (newNotification) => {
    console.log('New notification received:test', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });




  // Listen for the 'new-comment' event
  channel.bind('new-comment', (newNotification) => {
    console.log('New comment notification received:', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });

  // Listen for the 'share-article' event
  channel.bind('share-job', (newNotification) => {
    console.log('New article share notification received:', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });










  // Cleanup: Unsubscribe from the channel when the component unmounts
  return () => {
    channel.unbind_all();
    channel.unsubscribe();
  };
}, [dispatch, userId]);








    
    // useEffect(() => {
    //     dispatch(fetchNotifications(userId));
      
    //     const socket = io('http://localhost:7000'); // Backend URL
    //     socket.emit('join', userId);
      
    //     socket.on('notification', (newNotification) => {
    //       // Fetch notifications again or update state to include new notification
    //       dispatch(fetchNotifications(userId));
    //     });
      
    //     return () => {
    //       socket.disconnect();
    //     };
    //   }, [dispatch, userId]);  




      // const fetchSuggestions = async ({ value }) => {
      //   const response = await fetch(`http://localhost:7000/api/post/job-suggestions?term=${value}`);
      //   const suggestions = await response.json();
      //   setSuggestions(suggestions);
      // };
    
      // const onSuggestionsFetchRequested = ({ value }) => {
      //   fetchSuggestions({ value });
      // };
    
      // const onSuggestionsClearRequested = () => {
      //   setSuggestions([]);
      // };




      // const fetchLocationSuggestions = async ({ value }) => {
      //   const response = await fetch(`http://localhost:7000/api/post/location-suggestions?term=${value}`);
      //   const suggestions = await response.json();
      //   setLocationSuggestions(suggestions);
      // };
    


      // // const onJobSuggestionsFetchRequested = ({ value }) => {
      // //   fetchJobSuggestions({ value });
      // // };




      // const onLocationSuggestionsFetchRequested = ({ value }) => {
      //   fetchLocationSuggestions({ value });
      // };
    
   





      // const handleSearch = (e) => {
      //   e.preventDefault();
      //   dispatch(searchJobs({ term: searchTerm, location: locationTerm }));
      // };



      const handleSearch = (e) => {
        e.preventDefault();
        dispatch(searchJobsInDB({ term: searchTerm, location: locationTerm  , country: countryTerm }));
      };




      const handleProfileClick = (userId) => {
        console.log(userId); 
        if (userId) {
          navigate(`/profile/${userId}`)
          console.log(userId); // Redirect to profile page with userId
        }
      };
    


    const handleSaveEdit = () => {
        dispatch(updateJob({ id: editingJob, jobData: { text: editedText } }))
          .then(() => {
            alert('Post edited successfully!');
            setEditingJob(null);
            setEditedText('');
            dispatch(fetchJobs());
          })
          .catch((error) => {
            alert('Error editing post: ' + error.message);
          });
      };
    
    
      const handleEdit = (jobId, initialText) => {
        setEditingJob(jobId);
        setEditedText(initialText);
      };


      const handleTextChange = (value) => {
        setEditedText(value);
      };


  
      const handleDelete = (jobId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this post?');
        if (!isConfirmed) {
          return;
        }
        // Dispatch the delete comment action
        dispatch(deleteJob(jobId))
          .then(() => {
            // Optional: Show a success message or update the UI
            console.log('deleted successfully');
            dispatch(fetchJobs()); // Refresh articles after deletion
          })
          .catch((error) => {
            // Optional: Handle error if comment deletion fails
            console.error('Error deleting:', error);
          });
      };
    



      const handleLike = (jobId) => {
        const userId = profile.userId
      //  socket.emit('notification', { jobId, userId });
       dispatch(likeJob({ jobId }));
       dispatch(fetchJobs());
     };
   



     
  const handleShare = (jobId) => {
    const userId = profile.userId; // Get the user ID from the profile
    // socket.emit('notification', { jobId, userId });
    dispatch(shareJob({ jobId, userId }));
    dispatch(fetchJobs());
  };








 
    
  
     const handleOpenModal = (likedBy) => {
        setLikedUsers(likedBy);
        setIsModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setIsModalOpen(false);
        setLikedUsers([]);
      };
    
      const handleOpenJobModal = (likedBy) => {
        setLikedUsers(likedBy);
        setIsModalOpen(true);
      };
    
      const handleArticleCloseModal = () => {
        setIsModalArticleOpen(false);
        setLikedUsers([]);
      };
    

      const handleToggleComments = (articleId) => {
        setOpenComments((prevState) => ({
          ...prevState,
          [articleId]: !prevState[articleId],
        }));
      };
    




      const handleLikeComment = (jobId, commentId) => {
        // socket.emit('notification', { jobId, userId });
        dispatch(likeComment({ jobId, commentId }));
    
      };

      const users = Array.isArray(allProfiles) 
      ? allProfiles.map(user => ({
          id: user._id,
          display: `${user.firstName} ${user.lastName}`,
        })) 
      : [];
    
    // Extract mentions in `@[displayName](userId)` format
    const extractMentionedUsers = (text) => {
      
      const mentionPattern = /@\[(.*?)\]\((.*?)\)/g;
      const mentionedUsers = [];
      let match;
      while ((match = mentionPattern.exec(text)) !== null) {
        mentionedUsers.push({
          displayName: match[1],
          userId: match[2]
        });
      }
      return mentionedUsers;
    };
    
    const formatMentions = (text) => {
      return text.replace(/@\[(.*?)\]\((.*?)\)/g, '@$1'); // Replaces with @displayName
    };
    
    const handleCommentSubmit = async (jobId, e) => {
      e.preventDefault();
      const mentionedUsers = extractMentionedUsers(commentText); // Extracts mentioned users from the comment text
    
      console.log("Mentioned Users:", mentionedUsers);
      console.log("Comment Text:", commentText);
    
      if (commentText.trim() === '') {
        console.error('Comment text cannot be empty');
        return;
      }
    
      try {
        await dispatch(commentJob({ jobId, text: commentText, mentionedUsers }));
        setCommentText('');
        console.log("Comment dispatched successfully:", { jobId, text: commentText, mentionedUsers });
        dispatch(fetchJobs({ page, limit }));
      } catch (error) {
        console.error('Error submitting comment:', error.message);
        if (error.response) {
          console.error("Backend response:", error.response.data);
        }
      }
    };


      // const handleCommentSubmit = (jobId, e) => {
      //   const userId = profile.userId
      //   // socket.emit('notification', { jobId, userId });
      //   e.preventDefault(); // Prevent page refresh
      //   if (commentText.trim() !== '') {
      //     dispatch(commentJob({ jobId, text: commentText }))
      //       .then(() => {
      //         setCommentText('');
      //         dispatch(fetchJobs()); // Fetch articles again to get updated comments
      //       })
      //       .catch((error) => {
      //         console.error('Error submitting comment:', error);
      //       });
      //   } else {
      //     console.error('Comment text cannot be empty');
      //   }
      // };









      

      const handleDeleteComment = (jobId, commentId) => {
        console.log(jobId);
        console.log(commentId);
        // Confirm deletion
        const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
        if (!isConfirmed) {
          return;
        }
        // Dispatch the delete comment action
        dispatch(deleteComment({ jobId, commentId }))
          .then(() => {
            // Optional: Show a success message or update the UI
            console.log('Comment deleted successfully');
            dispatch(fetchJobs()); // Refresh articles after deletion
          })
          .catch((error) => {
            // Optional: Handle error if comment deletion fails
            console.error('Error deleting comment:', error);
          });
      };
    








      const handleSaveEditComment = (jobId, commentId) => {
        dispatch(editComment({ jobId, commentId, newText: editedCommentText }))
          .then(() => {
            alert('Comment edited successfully!');
            setEditingCommentId(null);
            setEditedCommentText('');
            dispatch(fetchJobs());
          })
          .catch((error) => {
            alert('Error editing comment: ' + error.message);
          });
      };
  
  
  
  
    const handleEditComment = (commentId, initialText) => {
      setEditingCommentId(commentId);
      setEditedCommentText(initialText);
    };
  
    const handleTextChangeComment = (value) => {
      setEditedCommentText(value);
    };
  






    const truncateDescription = (description, length) => {
      return description.length > length ? description.substring(0, length) + '...' : description;
    };


    const getSuggestionValue = suggestion => suggestion;

    const renderSuggestion = suggestion => (
      <div>
        {suggestion}
      </div>
    );



    useEffect(() => {
      dispatch(fetchAllProfiles());
    }, []);
  

    useEffect(() => {
        dispatch(fetchJobs({ page, limit }));
      }, [dispatch,page]);


      


    return(

      <>
 <div className={styles.container}>

 {/* <form onSubmit={handleSearch} className={styles.searchForm}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            placeholder: "Search for jobs...",
            value: searchTerm,
            onChange: (e, { newValue }) => setSearchTerm(newValue),
          }}
          theme={theme}
        />



        <Autosuggest
          suggestions={locationSuggestions}
          onSuggestionsFetchRequested={onLocationSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            placeholder: "Location...",
            value: locationTerm,
            onChange: (e, { newValue }) => setLocationTerm(newValue),
          }}
          theme={theme}
        />






        <button type="submit" className={styles.searchButton}>Search</button>
      </form> */}
{/* <form onSubmit={handleSearch} className={styles.searchForm}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for jobs..."
          className={styles.searchInput}
        />
        <button type="submit" className={styles.searchButton}>Search</button>
      </form> */}
{/* 
<form onSubmit={handleSearch} className={styles.searchForm}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for jobs..."
          className={styles.searchInput}
        />
        <input
          type="text"
          value={locationTerm}
          onChange={(e) => setLocationTerm(e.target.value)}
          placeholder="Location..."
          className={styles.searchInput}
        />
        <button type="submit" className={styles.searchButton}>Search</button>
      </form> */}





{showSearchBar && (
        <form onSubmit={handleSearch} className={styles.searchForm}>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search for jobs..."
            className={styles.searchInput}
          />
          <input
            type="text"
            value={locationTerm}
            onChange={(e) => setLocationTerm(e.target.value)}
            placeholder="City..."
            className={styles.searchInput}
          />


<input
            type="text"
            value={countryTerm}
            onChange={(e) => setCountryTerm(e.target.value)}
            placeholder="Country..."
            className={styles.searchInput}
          />
          <button type="submit" className={styles.searchButton}>Search</button>
        </form>
      )}


      {searchStatus === 'loading' && <p>Loading...</p>}
      {searchStatus === 'failed' && <p>Error: {searchError}</p>}
      {searchStatus === 'succeeded' && (
        <div className={styles.resultsContainer}>
          {searchResults.length > 0 ? (
            searchResults.map((job) => (

              <div key={job.id} className={styles.container}>
              <div className={styles.cardHeader}>
                <img src={job.employer_logo} alt={job.employer_name} className={styles.employerLogo} />
                <div className={styles.jobInfo}>
                  <h3 className={styles.jobTitle}>{job.job_title}</h3>
                  <p className={styles.employerName}>{job.employer_name}</p>
                </div>
              </div>
              <div className={styles.cardBody}>
          
                <div className={styles.jobDetail}>
                  <strong>City:</strong> {job.job_city}
                </div>
              
                <div className={styles.jobDetail}>
                  <strong>Employment Type:</strong> {job.job_employment_type}

                </div>

                 

                  
                <div className={styles.jobDetail}>
                <strong>Max Salary:</strong> {getCurrencySymbol(job.job_country)}{job.job_max_salary}
                </div>



                <div className={styles.jobDetail}>
                <strong>Description:</strong> {truncateDescription(job.job_description, 200)}
                </div>
               
               
               
                <div className={styles.jobDetail}>
                  <strong>Posted:</strong> {new Date(job.job_posted_at_datetime_utc).toLocaleDateString()}
                </div>
              </div>
              <a href={job.job_apply_link} target="_blank" rel="noopener noreferrer" className={styles.applyButton}>Apply</a>
            </div>
            //   <div key={job.id} className={styles.jobCard}>
            //   <div className={styles.cardHeader}>
            //     <img src={job.employer_logo} alt={job.employer_name} className={styles.employerLogo} />
            //     <div className={styles.jobInfo}>
            //       <h3 className={styles.jobTitle}>{job.job_title}</h3>
            //       <p className={styles.employerName}>{job.employer_name}</p>
            //     </div>
            //   </div>
            //   <div className={styles.cardBody}>
            //     <span className={styles.jobDetail}>{new Date(job.job_posted_at_datetime_utc).toLocaleDateString()}</span>
            //     <span className={styles.jobDetail}>{job.job_max_salary}</span>
            //     <span className={styles.jobDetail}>{job.job_city}</span>
            //     <span className={styles.jobDetail}>{job.job_publisher}</span>
            //     <span className={styles.jobDetail}>{job.job_employment_type}</span>
            //     <span  className={styles.jobDetail}>{job.job_description }</span>
            //   </div>
            //   <a href={job.job_apply_link} target="_blank" rel="noopener noreferrer" className={styles.applyButton}>Apply</a>
            // </div>
            //   <div key={job.id} className={styles.jobCard}>
            //   <h3>{job.job_title}</h3>
            //   <p>{job.employer_name}</p>
            //   <img src={job.employer_logo} alt={job.employer_name} />
              
            //   <span>{job.job_max_salary}</span>
            //   <span>{job.job_city}</span>
            
            //   <span>{job.job_employment_type}</span>
            //   <span>{job.job_description }</span>
            //   <a href={job.job_apply_link} target="_blank" rel="noopener noreferrer">Apply</a>
            //   <span>{job.job_posted_at_datetime_utc}</span>
            // </div>
            ))
          ) : (
            <p>No jobs found</p>
          )}
        </div>
      )}
</div>



     <div className='container'>

     <Jobapis/>
     </div>












        <div >




















      {jobs &&
        jobs.map((job) => (
          <div key={job._id} className={styles.container}>
            

            
         {job && job.author ? (
          <PostHeader
            firstName={job.author.firstName}  // Author's first name
            lastName={job.author.lastName}    // Author's last name
            profilePicture={job.author.profilePicture}  // Author's profile picture
            icon={<AiOutlineRead size={'1rem'} />}
            userId={job.userId} 
           time={dayjs(job.createdAt).fromNow()}
          />
        ) : (
          <div>Loading author details...</div>  
        )}



             
           
{editingJob === job._id ? (
              <div>
                <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
                <button onClick={handleSaveEdit}>Save</button>
              </div>
            ) : (
              <div>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: job.text }} />
                {job.file && (
                  <img src={job.file} alt="Jobs" style={{ width: '100%', height: 'auto' }} />
                )}
                
                { job.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
                  <>
                  
                    
                    <MdDeleteSweep onClick={() => handleDelete(job._id)} size={'1rem'} />
                    <MdEdit  onClick={() => handleEdit(job._id, job.text)} size={'1rem'}  />
                    
                  </>
                )}

              </div>
            )}






<div className={styles.commentSection}>
           
           {openComments[job._id] &&
             job.comments &&
             job.comments.map((comment) => (
               <div key={comment._id} className={styles.comment}>
                 <div>
                 <img
                        src={
                          comment.commentAuthor.profilePicture ||
                          'https://randomuser.me/api/portraits/men/28.jpg'
                        }
                        onClick={() =>
                          handleProfileClick(comment.commentAuthor.userId)
                        }
                        alt={`${comment.commentAuthor.firstName}'s Profile`}
                        style={{
                          borderRadius: '100%',
                          width: '2.9rem',
                          height: '2.9rem',
                          cursor: 'pointer', // Makes it clear that it's clickable
                        }}
                      />
                 </div>
                 <div
                   style={{
                     marginLeft: '40px',
                     backgroundColor: '#e6e6e6',
                     borderRadius: '10px',
                     padding: '10px',
                   }}
                 >
                   <div>
                  
                   <strong>{comment.commentAuthor?.firstName} {comment.commentAuthor?.lastName}</strong>
          <span style={{ marginLeft: '10px', color: '#888' }}>
                      {/* Display the relative time ago for the comment */}
                      {dayjs(comment.added).fromNow()}
                    </span>



                   </div>
                   <div>{formatMentions(comment.text)}</div>
                   
                   {(comment.userId === profile.userId || job.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
                                  <>
                                     {comment.userId === profile.userId ? (
                                       <>
                                         
                                           <RiPencilLine    onClick={() => handleEditComment(comment._id, comment.text)}
                                           className={styles.editButton} />
                                        
                                         
                                         
                                           <RiDeleteBin5Line   onClick={() => handleDeleteComment(job._id, comment._id)}
                                           className={styles.deleteButton} />
                                        
                                       </>
                                     ) : (
                                   <RiDeleteBin5Line    onClick={() => handleDeleteComment(job._id, comment._id)}
                                         className={styles.deleteButton} />
                                       
                                     )}
                                   </>
                                 )}
           
           
           
           
           
           
                   <AiFillLike size="1rem" onClick={() => handleLikeComment(job._id, comment._id)} />
                   <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
                  
                  
                  
                   <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
                
                   
                   
                   
                  
                   
             </div>
           
           
           
              
           

           
           
           {editingCommentId && (
                         <div>
                           <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
                           <button onClick={() => handleSaveEditComment(job._id, editingCommentId)}>Save</button>
                         </div>
                       )}
           
           
               </div>
             ))
           }
           
           {openComments[job._id] && (
                           <div className={styles.addComment}>
                                     <MentionsInput
  value={commentText}
  onChange={(e) => setCommentText(e.target.value)}
  placeholder="Add a comment..."
>
  <Mention
    trigger="@"
    data={users}
    displayTransform={(id, display) => `@${display}`}  // Format to `@[displayName](userId)`
  />
</MentionsInput>
                             <button onClick={(e) => handleCommentSubmit(job._id, e)} className={styles.commentButton}>
                               <AiOutlineComment size="1rem" />
                               <span>Comment</span>
                             </button>
                           </div>
                         )}
           
           
                       </div>









            <div style={{ display: 'flex' }}>
              <div className={styles.intraction}>
                <div className={styles.icons}>
                  <AiFillLike size="1rem"  onClick={() => handleLike(job._id)} />
                  <span style={{ color: '#11a8e1' }}>{job.likes}</span>
                  <IoEyeSharp onClick={() => handleOpenJobModal(job.likedBy)} />
                
                </div>
                <div className={styles.icons}>
                <BsChatDots size="1rem" onClick={() => handleToggleComments(job._id)} />
                <span>{job.comments?.length}</span>
                </div>
                <div className={styles.icons}>
                  <IoMdRepeat  size="1rem"  onClick={() => handleShare(job._id)}  />
                  <span style={{ color: '#11a8e1' }}>{job.shares}</span>
                </div>
                <div className={styles.icons}>
                  <RiBookmarkLine size="1rem" />
                </div>
              </div>
              <div className={styles.icons}>
                <IoMdMore size="1rem" />
              </div>
            </div>
          </div>
  
  ))}
{isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
{isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}


<div className={styles.pagination}>
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? styles.active : ''}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
   
    </div>
    </>
    )
}

export default JobTl