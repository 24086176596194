import React from 'react';
import styles from './blog.module.css'

const Modal = ({ likedUsers, onClose }) => {
  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>X</button>
        <h2>Liked By</h2>
        
       
       
       
       
        <ul>
          {likedUsers.map((user, index) => (
            <li key={index}>
              {user.firstName} {user.lastName}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Modal;
