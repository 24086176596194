import React, { useEffect, useState } from 'react';
import PostHeader from '../postHeader'
import styles from '../blog/blog.module.css';
import {RiBookmarkLine} from 'react-icons/ri'
import {BsChatDots} from 'react-icons/bs'
import {BiErrorCircle} from 'react-icons/bi'
import {AiOutlineLike} from 'react-icons/ai'
import {IoMdRepeat,IoMdMore} from 'react-icons/io'
import { useSelector, useDispatch } from 'react-redux';
import {deleteIssue,fetchIssues,updateIssue,likeIssue,commentIssue,editComment,deleteComment,likeComment,shareIssue,callOpenAIPrompt} from "../../../Redux/issueSlice"
import { RiDeleteBin5Line, RiPencilLine} from 'react-icons/ri';
import { MentionsInput, Mention } from 'react-mentions';
import { IoEyeSharp } from "react-icons/io5";
import { AiOutlineRead, AiFillLike ,AiOutlineComment} from 'react-icons/ai';
import { io } from 'socket.io-client';
import { MdEdit } from "react-icons/md";
import { MdDeleteSweep } from "react-icons/md";
import {socket , connectSocket, emitEvent} from "../../../Socket/socket"
import { fetchNotifications } from '../../../Redux/notificationSlice';
import ArticleLikesModal from '../blog/articleLikeModel'
import Modal from './modal';
import Pusher from 'pusher-js';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { fetchAllProfiles } from '../../../Redux/profileSlice';
const IssueTl= ({ showIssueForm = true })=>{

    const dispatch = useDispatch();
    // const issues = useSelector((state) => state.issues.issues);
    const { issues, status, totalPages, currentPage, openAIResponse, openAIStatus  } = useSelector((state) => state.issues);
    const profile = useSelector((state) => state.profiles.profiles);
    const allProfiles = useSelector((state) => state.profiles.allProfiles);
    const [editingIssue, setEditingIssue] = useState(null);
    const [editedText, setEditedText] = useState('');
    const [likedUsers, setLikedUsers] = useState([])
    const [isModalIssueOpen, setIsModalIssueOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openComments, setOpenComments] = useState({});
    const [commentText, setCommentText] = useState('');
    const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
    const [editedCommentText, setEditedCommentText] = useState('');
    const [page, setPage] = useState(1);
    const limit = 10; 
    // const userId = profile.userId; 
    const [prompt, setPrompt] = useState('');
 
    const { userId } = useParams(); 
    const navigate = useNavigate();

    dayjs.extend(relativeTime);

    const handlePageChange = (newPage) => {
      setPage(newPage);
    };


 // Pusher setup
 useEffect(() => {
  const pusher = new Pusher('8d13c6a59a85bd1417e7', {
    cluster: 'eu',
  });

  // Subscribe to the issue channel
  const channel = pusher.subscribe('issue-channel');

  channel.bind('new-mention', (newNotification) => {
    console.log('Mention Notification: ', newNotification);
    dispatch(fetchNotifications(userId)); 
    // Show real-time notification to the user
  });


  // Listen for the 'like-article' event and update notifications
  channel.bind('like-issue', (newNotification) => {
    console.log('New notification received:test', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });


  channel.bind('like-issue-comment', (newNotification) => {
    console.log('New notification received:test', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });




  // Listen for the 'new-comment' event
  channel.bind('new-comment', (newNotification) => {
    console.log('New comment notification received:', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });

  // Listen for the 'share-article' event
  channel.bind('share-issue', (newNotification) => {
    console.log('New article share notification received:', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });










  // Cleanup: Unsubscribe from the channel when the component unmounts
  return () => {
    channel.unbind_all();
    channel.unsubscribe();
  };
}, [dispatch, userId]);



    // useEffect(() => {
    //     dispatch(fetchNotifications(userId));
      
    //     const socket = io('http://localhost:7000'); // Backend URL
    //     socket.emit('join', userId);
      
    //     socket.on('notification', (newNotification) => {
    //       // Fetch notifications again or update state to include new notification
    //       dispatch(fetchNotifications(userId));
    //     });
      
    //     return () => {
    //       socket.disconnect();
    //     };
    //   }, [dispatch, userId]);  



      const getOpenAIResponseContent = (response) => {
        if (!response) return "No response";
        if (response.answer) return response.answer;
        if (response.error) return response.error;
        if (response.message) return response.message;
        if (response.response) return response.response;
        return "No valid response key found";
      };





      // useEffect(() => {
      //   if (status === 'succeeded' && openAIResponse) {
      //     setPromptResponse(openAIResponse);
         
      //   }
      // }, [openAIResponse, status]);
    



     
      const handleShare = (issueId) => {
        const userId = profile.userId; // Get the user ID from the profile
        // socket.emit('notification', { issueId, userId });
        dispatch(shareIssue({ issueId, userId }));
        dispatch(fetchIssues());
      };




      const handleLike = (issueId) => {
        const userId = profile.userId
      //  socket.emit('notification', { issueId, userId });
       dispatch(likeIssue({ issueId }));
       dispatch(fetchIssues());
     };
   


     
     const handleProfileClick = (userId) => {
      console.log(userId); 
      if (userId) {
        navigate(`/profile/${userId}`)
        console.log(userId); // Redirect to profile page with userId
      }
    };




    const users = Array.isArray(allProfiles) 
    ? allProfiles.map(user => ({
        id: user._id,
        display: `${user.firstName} ${user.lastName}`,
      })) 
    : [];
  
  // Extract mentions in `@[displayName](userId)` format
  const extractMentionedUsers = (text) => {
    
    const mentionPattern = /@\[(.*?)\]\((.*?)\)/g;
    const mentionedUsers = [];
    let match;
    while ((match = mentionPattern.exec(text)) !== null) {
      mentionedUsers.push({
        displayName: match[1],
        userId: match[2]
      });
    }
    return mentionedUsers;
  };
  
  const formatMentions = (text) => {
    return text.replace(/@\[(.*?)\]\((.*?)\)/g, '@$1'); // Replaces with @displayName
  };
  
  const handleCommentSubmit = async (issueId, e) => {
    e.preventDefault();
    const mentionedUsers = extractMentionedUsers(commentText); // Extracts mentioned users from the comment text
  
    console.log("Mentioned Users:", mentionedUsers);
    console.log("Comment Text:", commentText);
  
    if (commentText.trim() === '') {
      console.error('Comment text cannot be empty');
      return;
    }
  
    try {
      await dispatch(commentIssue({ issueId, text: commentText, mentionedUsers }));
      setCommentText('');
      console.log("Comment dispatched successfully:", { issueId, text: commentText, mentionedUsers });
      dispatch(fetchIssues({ page, limit }));
    } catch (error) {
      console.error('Error submitting comment:', error.message);
      if (error.response) {
        console.error("Backend response:", error.response.data);
      }
    }
  };




    const handleSaveEdit = () => {
        // dispatch(updateIssue({ id: editingIssue, issueData: { text: editedText } }))
        dispatch(updateIssue({ id: editingIssue, issueData: { text: editedText } }))
          .then(() => {
            alert('Post edited successfully!');
            setEditingIssue(null);
            setEditedText('');
            dispatch(fetchIssues());
          })
          .catch((error) => {
            alert('Error editing post: ' + error.message);
          });
      };
    
    
      const handleEdit = (issueId, initialText) => {
        setEditingIssue(issueId);
        setEditedText(initialText);
      };


      const handleTextChange = (value) => {
        setEditedText(value);
      };





    const handleDelete = (issueId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this post?');
        if (!isConfirmed) {
          return;
        }
        // Dispatch the delete comment action
        dispatch(deleteIssue(issueId))
          .then(() => {
            // Optional: Show a success message or update the UI
            console.log('deleted successfully');
            dispatch(fetchIssues()); // Refresh articles after deletion
          })
          .catch((error) => {
            // Optional: Handle error if comment deletion fails
            console.error('Error deleting:', error);
          });
      };






  
      const handleOpenModal = (likedBy) => {
        setLikedUsers(likedBy);
        setIsModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setIsModalOpen(false);
        setLikedUsers([]);
      };
    
      const handleOpenIssueModal = (likedBy) => {
        setLikedUsers(likedBy);
        setIsModalOpen(true);
      };
    
      const handleArticleCloseModal = () => {
        setIsModalIssueOpen(false);
        setLikedUsers([]);
      };




      const handleToggleComments = (issueId) => {
        setOpenComments((prevState) => ({
          ...prevState,
          [issueId]: !prevState[issueId],
        }));
      };
    



      
      // const handleCommentSubmit = (issueId, e) => {
      //   const userId = profile.userId
      //   // socket.emit('notification', { issueId, userId });
      //   e.preventDefault(); // Prevent page refresh
      //   if (commentText.trim() !== '') {
      //     dispatch(commentIssue({ issueId, text: commentText }))
      //       .then(() => {
      //         setCommentText('');
      //         dispatch(fetchIssues()); // Fetch articles again to get updated comments
      //       })
      //       .catch((error) => {
      //         console.error('Error submitting comment:', error);
      //       });
      //   } else {
      //     console.error('Comment text cannot be empty');
      //   }
      // };





      const handleLikeComment = (issueId, commentId) => {
        // socket.emit('notification', { issueId, userId });
        dispatch(likeComment({ issueId, commentId }));
    
      };





      

      const handleDeleteComment = (issueId, commentId) => {
        console.log(issueId);
        console.log(commentId);
        // Confirm deletion
        const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
        if (!isConfirmed) {
          return;
        }
        // Dispatch the delete comment action
        dispatch(deleteComment({ issueId, commentId }))
          .then(() => {
            // Optional: Show a success message or update the UI
            console.log('Comment deleted successfully');
            dispatch(fetchIssues()); // Refresh articles after deletion
          })
          .catch((error) => {
            // Optional: Handle error if comment deletion fails
            console.error('Error deleting comment:', error);
          });
      };
    








      const handleSaveEditComment = (issueId, commentId) => {
        dispatch(editComment({ issueId, commentId, newText: editedCommentText }))
          .then(() => {
            alert('Comment edited successfully!');
            setEditingCommentId(null);
            setEditedCommentText('');
            dispatch(fetchIssues());
          })
          .catch((error) => {
            alert('Error editing comment: ' + error.message);
          });
      };
  
  
  
  
    const handleEditComment = (commentId, initialText) => {
      setEditingCommentId(commentId);
      setEditedCommentText(initialText);
    };
  
    const handleTextChangeComment = (value) => {
      setEditedCommentText(value);
    };



    // const handlePromptSubmit = () => {
    //   if (prompt.trim() !== '') {
    //     dispatch(callOpenAIPrompt(prompt));
    //     console.log(openAIResponse)
    //   }
    // };
  

    const handlePromptSubmit = () => {
      if (prompt.trim() !== '') {
        console.log("Submitting prompt:", prompt);  // Debugging log
        dispatch(callOpenAIPrompt(prompt)).then((response) => {
          console.log("API Response received:", response);  // Debugging log
        }).catch((error) => {
          console.error("API call error:", error);  // Debugging log for errors
        });
      }
    };
    



      useEffect(() => {
        dispatch(fetchIssues({ page, limit }));
      }, [dispatch,page]);





    return(

<>
{showIssueForm && (
        <div className={styles.promptContainer}>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your Issues here..."
          />
          <button onClick={handlePromptSubmit}>Submit Issues</button>
        </div>
      )}
      {openAIStatus === 'loading' && <p>Loading...</p>}
      {showIssueForm && openAIStatus === 'succeeded' &&(
        <div className={styles.responseContainer}>
          <h3>Bimrely AI Response:</h3>
          <p>{openAIResponse}</p>
        </div>
      )}


        <div className={styles.container}>











        {issues &&
          issues.map((issue) => (
            <div key={issue._id}>
          
         {issue && issue.author ? (
          <PostHeader
            firstName={issue.author.firstName}  // Author's first name
            lastName={issue.author.lastName}    // Author's last name
            profilePicture={issue.author.profilePicture}  // Author's profile picture
            icon={<AiOutlineRead size={'1rem'} />}
            userId={issue.userId} 
            time={dayjs(issue.createdAt).fromNow()}
          />
        ) : (
          <div>Loading author details...</div>  
        )}
              
               
             
  {editingIssue === issue._id ? (
                <div>
                  <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
                  <button onClick={handleSaveEdit}>Save</button>
                </div>
              ) : (
                <div>
                  <div className={styles.content} dangerouslySetInnerHTML={{ __html: issue.text }} />
                  {issue.file && (
                    <img src={issue.file} alt="Article" style={{ width: '100%', height: 'auto' }} />
                  )}
                  
                  { issue.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
                    <>
                    
                      
                      <MdDeleteSweep onClick={() => handleDelete(issue._id)} size={'1rem'} />
                      <MdEdit  onClick={() => handleEdit(issue._id, issue.text)} size={'1rem'}  />
                      
                    </>
                  )}
  
                </div>
              )}
  
  
  
  
  



        
<div className={styles.commentSection}>
           
           {openComments[issue._id] &&
             issue.comments &&
             issue.comments.map((comment) => (
               <div key={comment._id} className={styles.comment}>
                 <div>
                 <img
                        src={
                          comment.commentAuthor.profilePicture ||
                          'https://randomuser.me/api/portraits/men/28.jpg'
                        }
                        onClick={() =>
                          handleProfileClick(comment.commentAuthor.userId)
                        }
                        alt={`${comment.commentAuthor.firstName}'s Profile`}
                        style={{
                          borderRadius: '100%',
                          width: '2.9rem',
                          height: '2.9rem',
                          cursor: 'pointer', // Makes it clear that it's clickable
                        }}
                      />
                 </div>
                 <div
                   style={{
                     marginLeft: '40px',
                     backgroundColor: '#e6e6e6',
                     borderRadius: '10px',
                     padding: '10px',
                   }}
                 >
                   <div>
                  
                  <strong>{comment.commentAuthor?.firstName} {comment.commentAuthor?.lastName}</strong>
         <span style={{ marginLeft: '10px', color: '#888' }}>
                     {/* Display the relative time ago for the comment */}
                     {dayjs(comment.added).fromNow()}
                   </span>



                  </div>
                  <div>{formatMentions(comment.text)}</div>
                   
                   {(comment.userId === profile.userId || issue.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
                                  <>
                                     {comment.userId === profile.userId ? (
                                       <>
                                         
                                           <RiPencilLine    onClick={() => handleEditComment(comment._id, comment.text)}
                                           className={styles.editButton} />
                                        
                                         
                                         
                                           <RiDeleteBin5Line   onClick={() => handleDeleteComment(issue._id, comment._id)}
                                           className={styles.deleteButton} />
                                        
                                       </>
                                     ) : (
                                   <RiDeleteBin5Line    onClick={() => handleDeleteComment(issue._id, comment._id)}
                                         className={styles.deleteButton} />
                                       
                                     )}
                                   </>
                                 )}
           
           
           
           
           
           
                   <AiFillLike size="1rem" onClick={() => handleLikeComment(issue._id, comment._id)} />
                   <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
                  
                  
                  
                   <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
                
                   
                   
                   
                  
                   
             </div>
           
           
           
              
           

           
           
           {editingCommentId && (
                         <div>
                           <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
                           <button onClick={() => handleSaveEditComment(issue._id, editingCommentId)}>Save</button>
                         </div>
                       )}
           
           
               </div>
             ))
           }
           
           {openComments[issue._id] && (
                           <div className={styles.addComment}>
                                                            <MentionsInput
  value={commentText}
  onChange={(e) => setCommentText(e.target.value)}
  placeholder="Add a comment..."
>
  <Mention
    trigger="@"
    data={users}
    displayTransform={(id, display) => `@${display}`}  // Format to `@[displayName](userId)`
  />
</MentionsInput>
                             <button onClick={(e) => handleCommentSubmit(issue._id, e)} className={styles.commentButton}>
                               <AiOutlineComment size="1rem" />
                               <span>Comment</span>
                             </button>
                           </div>
                         )}
           
           
                       </div>










  
 
  
  
  
  

  
  
              <div style={{ display: 'flex' }}>
                <div className={styles.intraction}>
                  <div className={styles.icons}>
                    <AiFillLike size="1rem"  onClick={() => handleLike(issue._id)} />
                    <span style={{ color: '#11a8e1' }}>{issue.likes}</span>
                    <IoEyeSharp   onClick={() => handleOpenIssueModal(issue.likedBy)}  />
                  
                  </div>
                  <div className={styles.icons}>
                  <BsChatDots onClick={() => handleToggleComments(issue._id)} />
                  <span>{issue.comments?.length}</span>
                  </div>
                  <div className={styles.icons}>
                    <IoMdRepeat  size="1rem"     onClick={() => handleShare(issue._id)}   />
                    <span style={{ color: '#11a8e1' }}>{issue.shares}</span>
                  </div>
                  <div className={styles.icons}>
                    <RiBookmarkLine size="1rem" />
                  </div>
                </div>
                <div className={styles.icons}>
                  <IoMdMore size="1rem" />
                </div>
              </div>
            </div>
    
    ))}

{isModalIssueOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
{isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}


<div className={styles.pagination}>
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? styles.active : ''}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>


     
      </div>
      </>
    )
}

export default IssueTl