import React, { useEffect, useState } from 'react';
import PostHeader from '../postHeader'
import styles from '../blog/blog.module.css';
import {RiBookmarkLine} from 'react-icons/ri'
import {BsChatDots} from 'react-icons/bs'
import {BiErrorCircle} from 'react-icons/bi'
import {AiOutlineLike} from 'react-icons/ai'
import {IoMdRepeat,IoMdMore} from 'react-icons/io'
import { useSelector, useDispatch } from 'react-redux';
import {deleteIssue,fetchIssues,updateIssue,likeIssue,commentIssue,editComment,deleteComment,likeComment} from "../../../Redux/issueSlice"
import {deleteSharedIssue,fetchProfile}from "../../../Redux/profileSlice"
import { RiDeleteBin5Line, RiPencilLine} from 'react-icons/ri';
import PropTypes from 'prop-types';
import { IoEyeSharp } from "react-icons/io5";
import { AiOutlineRead, AiFillLike ,AiOutlineComment} from 'react-icons/ai';
import { io } from 'socket.io-client';
import { MdEdit } from "react-icons/md";
import { MdDeleteSweep } from "react-icons/md";
import {socket , connectSocket, emitEvent} from "../../../Socket/socket"
import { fetchNotifications } from '../../../Redux/notificationSlice';
import ArticleLikesModal from '../blog/articleLikeModel'
import Modal from './modal';




const SharedIssues= ({ sharedIssues })=>{

    const dispatch = useDispatch();
    const issues = useSelector((state) => state.issues.issues);
    const profile = useSelector((state) => state.profiles.profiles);
    const [editingIssue, setEditingIssue] = useState(null);
    const [editedText, setEditedText] = useState('');
    const [likedUsers, setLikedUsers] = useState([])
    const [isModalIssueOpen, setIsModalIssueOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openComments, setOpenComments] = useState({});
    const [commentText, setCommentText] = useState('');
    const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
    const [editedCommentText, setEditedCommentText] = useState('');

    const userId = profile.userId; 





    useEffect(() => {
        dispatch(fetchIssues());
      }, [dispatch]);
    
      if (!sharedIssues || sharedIssues.length === 0) {
        return <div>No shared jobs available.</div>;
      }
    





      const handleLike = (issueId) => {
        const userId = profile.userId
      //  socket.emit('notification', { issueId, userId });
       dispatch(likeIssue({ issueId }));
       dispatch(fetchIssues());
     };
   






    const handleSaveEdit = () => {
        // dispatch(updateIssue({ id: editingIssue, issueData: { text: editedText } }))
        dispatch(updateIssue({ id: editingIssue, issueData: { text: editedText } }))
          .then(() => {
            alert('Post edited successfully!');
            setEditingIssue(null);
            setEditedText('');
            dispatch(fetchIssues());
          })
          .catch((error) => {
            alert('Error editing post: ' + error.message);
          });
      };
    
    
      const handleEdit = (issueId, initialText) => {
        setEditingIssue(issueId);
        setEditedText(initialText);
      };


      const handleTextChange = (value) => {
        setEditedText(value);
      };





    const handleDelete = (issueId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this post?');
        if (!isConfirmed) {
          return;
        }
        // Dispatch the delete comment action
        dispatch(deleteSharedIssue({ userId, issueId }))
          .then(() => {
            // Optional: Show a success message or update the UI
            console.log('deleted successfully');
            dispatch(fetchProfile()); // Refresh articles after deletion
          })
          .catch((error) => {
            // Optional: Handle error if comment deletion fails
            console.error('Error deleting:', error);
          });
      };






  
      const handleOpenModal = (likedBy) => {
        setLikedUsers(likedBy);
        setIsModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setIsModalOpen(false);
        setLikedUsers([]);
      };
    
      const handleOpenIssueModal = (likedBy) => {
        setLikedUsers(likedBy);
        setIsModalOpen(true);
      };
    
      const handleArticleCloseModal = () => {
        setIsModalIssueOpen(false);
        setLikedUsers([]);
      };




      const handleToggleComments = (issueId) => {
        setOpenComments((prevState) => ({
          ...prevState,
          [issueId]: !prevState[issueId],
        }));
      };
    



      
      const handleCommentSubmit = (issueId, e) => {
        const userId = profile.userId
        // socket.emit('notification', { issueId, userId });
        e.preventDefault(); // Prevent page refresh
        if (commentText.trim() !== '') {
          dispatch(commentIssue({ issueId, text: commentText }))
            .then(() => {
              setCommentText('');
              dispatch(fetchProfile()); // Fetch articles again to get updated comments
            })
            .catch((error) => {
              console.error('Error submitting comment:', error);
            });
        } else {
          console.error('Comment text cannot be empty');
        }
      };





      const handleLikeComment = (issueId, commentId) => {
        // socket.emit('notification', { issueId, userId });
        dispatch(likeComment({ issueId, commentId }));
    
      };





      

      const handleDeleteComment = (issueId, commentId) => {
        console.log(issueId);
        console.log(commentId);
        // Confirm deletion
        const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
        if (!isConfirmed) {
          return;
        }
        // Dispatch the delete comment action
        dispatch(deleteComment({ issueId, commentId }))
          .then(() => {
            // Optional: Show a success message or update the UI
            console.log('Comment deleted successfully');
            dispatch(fetchIssues()); // Refresh articles after deletion
          })
          .catch((error) => {
            // Optional: Handle error if comment deletion fails
            console.error('Error deleting comment:', error);
          });
      };
    








      const handleSaveEditComment = (issueId, commentId) => {
        dispatch(editComment({ issueId, commentId, newText: editedCommentText }))
          .then(() => {
            alert('Comment edited successfully!');
            setEditingCommentId(null);
            setEditedCommentText('');
            dispatch(fetchIssues());
          })
          .catch((error) => {
            alert('Error editing comment: ' + error.message);
          });
      };
  
  
  
  
    const handleEditComment = (commentId, initialText) => {
      setEditingCommentId(commentId);
      setEditedCommentText(initialText);
    };
  
    const handleTextChangeComment = (value) => {
      setEditedCommentText(value);
    };









  




    return(
        <div >
        {sharedIssues &&
          sharedIssues.map((issue) => (
            <div key={issue._id} className={styles.container}>
             
             {issue && issue.author ? (
          <PostHeader
            firstName={issue.author.firstName}  // Author's first name
            lastName={issue.author.lastName}    // Author's last name
            profilePicture={issue.author.profilePicture}  // Author's profile picture
            icon={<AiOutlineRead size={'1rem'} />}
          />
        ) : (
          <div>Loading author details...</div>  
        )}
               
             
  {editingIssue === issue._id ? (
                <div>
                  <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
                  <button onClick={handleSaveEdit}>Save</button>
                </div>
              ) : (
                <div>
                  <div className={styles.content} dangerouslySetInnerHTML={{ __html: issue.text }} />
                  {issue.file && (
                    <img src={issue.file} alt="Issue" style={{ width: '100%', height: 'auto' }} />
                  )}
                   <MdDeleteSweep onClick={() => handleDelete(issue._id)} size={'1rem'} />
                  { issue.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
                    <>
                    
                      
                     
                      <MdEdit  onClick={() => handleEdit(issue._id, issue.text)} size={'1rem'}  />
                      
                    </>
                  )}
  
                </div>
              )}
  
  
  
  
  



        
<div className={styles.commentSection}>
           
           {openComments[issue._id] &&
             issue.comments &&
             issue.comments.map((comment) => (
               <div key={comment._id} className={styles.comment}>
                 <div>
                   <img
                     alt="profile picture"
                     src="https://randomuser.me/api/portraits/men/28.jpg"
                     style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
                   />
                 </div>
                 <div
                   style={{
                     marginLeft: '40px',
                     backgroundColor: '#e6e6e6',
                     borderRadius: '10px',
                     padding: '10px',
                   }}
                 >
                   <div>
                     <strong>{comment.firstName} {comment.lastName}</strong>
                   </div>
                   <div>{comment.text}</div>
                   
                   {(comment.userId === profile.userId || issue.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
                                  <>
                                     {comment.userId === profile.userId ? (
                                       <>
                                         
                                           <RiPencilLine    onClick={() => handleEditComment(comment._id, comment.text)}
                                           className={styles.editButton} />
                                        
                                         
                                         
                                           <RiDeleteBin5Line   onClick={() => handleDeleteComment(issue._id, comment._id)}
                                           className={styles.deleteButton} />
                                        
                                       </>
                                     ) : (
                                   <RiDeleteBin5Line    onClick={() => handleDeleteComment(issue._id, comment._id)}
                                         className={styles.deleteButton} />
                                       
                                     )}
                                   </>
                                 )}
           
           
           
           
           
           
                   <AiFillLike size="1rem" onClick={() => handleLikeComment(issue._id, comment._id)} />
                   <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
                  
                  
                  
                   <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
                
                   
                   
                   
                  
                   
             </div>
           
           
           
              
           

           
           
           {editingCommentId && (
                         <div>
                           <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
                           <button onClick={() => handleSaveEditComment(issue._id, editingCommentId)}>Save</button>
                         </div>
                       )}
           
           
               </div>
             ))
           }
           
           {openComments[issue._id] && (
                           <div className={styles.addComment}>
                             <input
                               type="text"
                               value={commentText}
                               onChange={(e) => setCommentText(e.target.value)}
                               placeholder="Add a comment..."
                             />
                             <button onClick={(e) => handleCommentSubmit(issue._id, e)} className={styles.commentButton}>
                               <AiOutlineComment size="1rem" />
                               <span>Comment</span>
                             </button>
                           </div>
                         )}
           
           
                       </div>










  
 
  
  
  
  

  
  
              <div style={{ display: 'flex' }}>
                <div className={styles.intraction}>
                  <div className={styles.icons}>
                    <AiFillLike size="1rem"  onClick={() => handleLike(issue._id)} />
                    <span style={{ color: '#11a8e1' }}>{issue.likes}</span>
                    <IoEyeSharp   onClick={() => handleOpenIssueModal(issue.likedBy)}  />
                  
                  </div>
                  <div className={styles.icons}>
                  <BsChatDots onClick={() => handleToggleComments(issue._id)} />
                  <span>{issue.comments?.length}</span>
                  </div>
                  <div className={styles.icons}>
                    <IoMdRepeat  size="1rem"    />
                    <span style={{ color: '#11a8e1' }}>{issue.shares}</span>
                  </div>
                  <div className={styles.icons}>
                    <RiBookmarkLine size="1rem" />
                  </div>
                </div>
                <div className={styles.icons}>
                  <IoMdMore size="1rem" />
                </div>
              </div>
            </div>
    
    ))}

{isModalIssueOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
{isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}
     
      </div>
    )
}


SharedIssues.propTypes = {
    sharedIssues: PropTypes.array.isRequired,
  };


export default SharedIssues