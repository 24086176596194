// import React, { useState, useEffect } from 'react';
// import styles from './profile.module.css';
// import { IoIosRemoveCircleOutline } from 'react-icons/io';
// import { MdOutlineCancel } from 'react-icons/md';
// import { useSelector, useDispatch } from 'react-redux';
// import { updateProfile } from '../../Redux/profileSlice'; // Importing thunk action

// const EditProfile = (props) => {
//   const dispatch = useDispatch();
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     designation: '',
//     company: '',
//     location: '',
//     experience: [{ title: '', startDate: '', endDate: '' }],
//     education: [{ title: '', startDate: '', endDate: '' }],
//   });

//   // Redux selector to get profile details
//   const profile = useSelector((state) => state.profile.profile);

//   // useEffect to populate form fields with profile details
//   useEffect(() => {
//     if (profile) {
//       setFormData({
//         firstName: profile.firstName || '',
//         lastName: profile.lastName || '',
//         designation: profile.designation || '',
//         company: profile.company || '',
//         location: profile.location || '',
//         experience: profile.experience || [],
//         education: profile.education || [],
//       });
//     }
//   }, [profile]);

//   // Function to handle form input changes
//   const handleInputChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   // Function to handle experience change
// // Function to handle experience change
// // const handleExperienceChange = (e, index) => {
// //   const { name, value } = e.target;
// //   const updatedExperience = [...formData.experience];
// //   updatedExperience[index][name] = value;
// //   setFormData({
// //     ...formData,
// //     experience: updatedExperience,
// //   });
// // };

// // Function to handle experience change
// const handleExperienceChange = (e, index) => {
//     const { name, value } = e.target;
//     const updatedExperience = [...formData.experience];
//     updatedExperience[index] = {
//       ...updatedExperience[index],
//       [name]: value,
//     };
//     setFormData({
//       ...formData,
//       experience: updatedExperience,
//     });
//   };
  
//   // Function to remove experience
//   const removeExperience = (index) => {
//     const updatedExperience = [...formData.experience];
//     updatedExperience.splice(index, 1);
//     setFormData({
//       ...formData,
//       experience: updatedExperience,
//     });
//   };

//   // Function to add experience
//   const addExperience = () => {
//     setFormData({
//       ...formData,
//       experience: [...formData.experience, { title: '', startDate: '', endDate: '' }],
//     });
//   };




//   // Function to handle education change
//   // Function to handle education change
// // const handleEducationChange = (e, index) => {
// //     const { name, value } = e.target;
// //     const updatedEducation = [...formData.education];
// //     updatedEducation[index][name] = value;
// //     setFormData({
// //       ...formData,
// //       education: updatedEducation,
// //     });
// //   };
// const handleEducationChange = (e, index) => {
//     const { name, value } = e.target;
//     const updatedEducation = [...formData.education];
//     updatedEducation[index] = {
//       ...updatedEducation[index],
//       [name]: value,
//     };
//     setFormData({
//       ...formData,
//       education: updatedEducation,
//     });
//   };

//   // Function to remove education
//   const removeEducation = (index) => {
//     const updatedEducation = [...formData.education];
//     updatedEducation.splice(index, 1);
//     setFormData({
//       ...formData,
//       education: updatedEducation,
//     });
//   };

//   // Function to add education
//   const addEducation = () => {
//     setFormData({
//       ...formData,
//       education: [...formData.education, { title: '', startDate: '', endDate: '' }],
//     });
//   };

//   // Function to handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Dispatch updateProfile thunk action to update profile
//     dispatch(updateProfile(formData));
//     // Close modal or navigate to another page after saving
//     props.closemodal();
//   };

//   return (
//     <div className={styles.editcontainer}>
//       <modal className={styles.modal}>
//         <div className={styles.editprofileheader}>
//           <div>Edit Profile</div>
//           <MdOutlineCancel style={{ cursor: 'pointer' }} onClick={props.closemodal} size={30} />
//         </div>
//         <form onSubmit={handleSubmit}>
//           <div className={styles.inputGroup}>
//             <div>First Name</div>
//             <input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} />
//           </div>
//           <div className={styles.inputGroup}>
//             <div>Last Name</div>
//             <input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} />
//           </div>
//           <div className={styles.inputGroup}>
//             <div>Designation</div>
//             <input type="text" name="designation" value={formData.designation} onChange={handleInputChange} />
//           </div>
//           <div className={styles.inputGroup}>
//             <div>Company</div>
//             <input type="text" name="company" value={formData.company} onChange={handleInputChange} />
//           </div>
//           <div className={styles.inputGroup}>
//             <div>Location</div>
//             <input type="text" name="location" value={formData.location} onChange={handleInputChange} />
//           </div>
//           {/* Add fields for experience */}
//           <div className={styles.inputGroup}>
//             <div>Experience</div>
//             {formData.experience.map((exp, index) => (
//               <div key={index} className={styles.listInput}>
//                 <input className={styles.li1} placeholder="Title" value={exp.title} onChange={(e) => handleExperienceChange(e, index)} />
//                 <input className={styles.li2} placeholder="Start Year" value={exp.startDate} onChange={(e) => handleExperienceChange(e, index)} />
//                 <input className={styles.li2} placeholder="End Year" value={exp.endDate} onChange={(e) => handleExperienceChange(e, index)} />
//                 <IoIosRemoveCircleOutline size={20} onClick={() => removeExperience(index)} />
//               </div>
//             ))}
//             <button onClick={addExperience}>Add</button>
//           </div>
//           {/* Add fields for education */}
//           <div className={styles.inputGroup}>
//             <div>Education</div>
//             {formData.education.map((edu, index) => (
//               <div key={index} className={styles.listInput}>
//                 <input className={styles.li1} placeholder="Title" value={edu.title} onChange={(e) => handleEducationChange(e, index)} />
//                 <input className={styles.li2} placeholder="Start Year" value={edu.startDate} onChange={(e) => handleEducationChange(e, index)} />
//                 <input className={styles.li2} placeholder="End Year" value={edu.endDate} onChange={(e) => handleEducationChange(e, index)} />
//                 <IoIosRemoveCircleOutline size={20} onClick={() => removeEducation(index)} />
//               </div>
//             ))}
//             <button onClick={addEducation}>Add</button>
//           </div>
//           {/* Save button */}
//           <div className={styles.save}>
//             <button type="submit">Save</button>
//           </div>
//         </form>
//       </modal>
//     </div>
//   );
// };

// export default EditProfile;




// import React, { useState ,useEffect} from 'react';
// import styles from './profile.module.css';
// import { IoIosRemoveCircleOutline } from 'react-icons/io';
// import { MdOutlineCancel } from 'react-icons/md';
// import { useSelector, useDispatch } from 'react-redux';
// import { updateProfile } from '../../Redux/profileSlice'; // Importing thunk action

// const EditProfile = (props) => {
//   const dispatch = useDispatch();
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     designation: '',
//     company: '',
//     location: '',
//     experience: [],
//     education: [],
//   });

//   // Redux selector to get profile details
//   const profile = useSelector((state) => state.profile.profile);

//   // useEffect to populate form fields with profile details
//   useEffect(() => {
//     if (profile) {
//       setFormData({
//         firstName: profile.firstName || '',
//         lastName: profile.lastName || '',
//         designation: profile.designation || '',
//         company: profile.company || '',
//         location: profile.location || '',
//         experience: profile.experience || [],
//         education: profile.education || [],
//       });
//     }
//   }, [profile]);

//   // Function to handle form input changes
//   const handleInputChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   // Function to handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Dispatch updateProfile thunk action to update profile
//     dispatch(updateProfile(formData));
//     // Close modal or navigate to another page after saving
//     props.closemodal();
//   };

//   return (
//     <div className={styles.editcontainer}>
//       <modal className={styles.modal}>
//         <div className={styles.editprofileheader}>
//           <div>Edit Profile</div>
//           <MdOutlineCancel style={{ cursor: 'pointer' }} onClick={props.closemodal} size={30} />
//         </div>
//         <form onSubmit={handleSubmit}>
//           <div className={styles.inputGroup}>
//             <div>First Name</div>
//             <input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} />
//           </div>
//           <div className={styles.inputGroup}>
//             <div>Last Name</div>
//             <input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} />
//           </div>
//           <div className={styles.inputGroup}>
//             <div>Designation</div>
//             <input type="text" name="designation" value={formData.designation} onChange={handleInputChange} />
//           </div>
//           <div className={styles.inputGroup}>
//             <div>Company</div>
//             <input type="text" name="company" value={formData.company} onChange={handleInputChange} />
//           </div>
//           <div className={styles.inputGroup}>
//             <div>Location</div>
//             <input type="text" name="location" value={formData.location} onChange={handleInputChange} />
//           </div>
//           {/* Add fields for experience and education */}
//           {/* Save button */}
//           <div className={styles.save}>
//             <button type="submit">Save</button>
//           </div>
//         </form>
//       </modal>
//     </div>
//   );
// };

// export default EditProfile;
import React, { useState, useEffect } from 'react';
import styles from './profile.module.css';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfile } from '../../Redux/profileSlice';

const EditProfile = (props) => {
    const profile = useSelector((state) => state.profiles.profiles);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        designation: '',
        company: '',
        location: '',
        experience: [{ title: '', startDate: '', endDate: '' }],
        education: [{ title: '', startDate: '', endDate: '' }],
    });

    useEffect(() => {
        if (profile) {

            console.log(profile); // Check the structure of the profile object
        console.log(profile._id);
            setFormData({
                firstName: profile.firstName || '',
                lastName: profile.lastName || '',
                designation: profile.designation || '',
                company: profile.company || '',
                location: profile.location || '',
                experience: profile.experience || [{ title: '', startDate: '', endDate: '' }],
                education: profile.education || [{ title: '', startDate: '', endDate: '' }],
            });
        }
    }, [profile]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };


    const handleExperienceChange = (e, index) => {
    const { name, value } = e.target;
    const updatedExperience = [...formData.experience]; // Deep copy
    updatedExperience[index] = { ...updatedExperience[index], [name]: value }; // Modify the copied object
    setFormData({
        ...formData,
        experience: updatedExperience, // Set the state with the modified object
    });
};

const handleEducationChange = (e, index) => {
    const { name, value } = e.target;
    const updatedEducation = [...formData.education]; // Deep copy
    updatedEducation[index] = { ...updatedEducation[index], [name]: value }; // Modify the copied object
    setFormData({
        ...formData,
        education: updatedEducation, // Set the state with the modified object
    });
};

    // const handleExperienceChange = (e, index) => {
    //     const { name, value } = e.target;
    //     const updatedExperience = [...formData.experience];
    //     updatedExperience[index][name] = value;
    //     setFormData({
    //         ...formData,
    //         experience: updatedExperience,
    //     });
    // };

    // const handleEducationChange = (e, index) => {
    //     const { name, value } = e.target;
    //     const updatedEducation = [...formData.education];
    //     updatedEducation[index][name] = value;
    //     setFormData({
    //         ...formData,
    //         education: updatedEducation,
    //     });
    // };

    const removeExperience = (index) => {
        const updatedExperience = [...formData.experience];
        updatedExperience.splice(index, 1);
        setFormData({
            ...formData,
            experience: updatedExperience,
        });
    };

    const removeEducation = (index) => {
        const updatedEducation = [...formData.education];
        updatedEducation.splice(index, 1);
        setFormData({
            ...formData,
            education: updatedEducation,
        });
    };

    const addExperience = () => {
        setFormData({
            ...formData,
            experience: [...formData.experience, { title: '', startDate: '', endDate: '' }],
        });
    };

    const addEducation = () => {
        setFormData({
            ...formData,
            education: [...formData.education, { title: '', startDate: '', endDate: '' }],
        });
    };
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const target = e.target;
    
    //     if (target.tagName.toLowerCase() === 'button' && target.type === 'submit') {
    //         const updatedProfileData = {
    //             firstName: formData.firstName,
    //             lastName: formData.lastName,
    //             designation: formData.designation,
    //             company: formData.company,
    //             location: formData.location,
    //             experience: formData.experience,
    //             education: formData.education,
    //         };
    //         const profileId = profile._id;
    //         console.log(updatedProfileData); 
    //         console.log(profileId);
    //         dispatch(updateProfile({ profileId, profileData: updatedProfileData }));
    //     }
    // };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedProfileData = {
            
            firstName: formData.firstName,
            lastName: formData.lastName,
            designation: formData.designation,
            company: formData.company,
            location: formData.location,
            experience: formData.experience,
            education: formData.education,
        };
        const profileId = profile._id;
        console.log(updatedProfileData); 
        console.log(profileId);
        dispatch(updateProfile({ profileId, profileData: updatedProfileData }));
    };

    return (
        <div className={styles.editcontainer}>
            <modal className={styles.modal}>
                <div className={styles.editprofileheader}>
                    <div>Edit Profile</div>
                    <MdOutlineCancel style={{ cursor: 'pointer' }} onClick={props.closemodal} size={30} />
                </div>
                <form onSubmit={handleSubmit}>
                    {/* Your input fields for first name, last name, designation, company, location */}
                    <div className={styles.inputGroup}>
                        <div>First Name</div>
                        <input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} />
                    </div>
                    <div className={styles.inputGroup}>
                        <div>Last Name</div>
                        <input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} />
                    </div>
                    <div className={styles.inputGroup}>
                        <div>Designation</div>
                        <input type="text" name="designation" value={formData.designation} onChange={handleInputChange} />
                    </div>
                    <div className={styles.inputGroup}>
                        <div>Company</div>
                        <input type="text" name="company" value={formData.company} onChange={handleInputChange} />
                    </div>
                    <div className={styles.inputGroup}>
                        <div>Location</div>
                        <input type="text" name="location" value={formData.location} onChange={handleInputChange} />
                    </div>

                    {/* Experience input fields */}
                    <div className={styles.inputGroup}>
                        <div>Experience</div>
                        {formData.experience.map((exp, index) => (
                            <div key={index} className={styles.listInput}>
                                <input className={styles.li1} name="title" placeholder="Title" value={exp.title} onChange={(e) => handleExperienceChange(e, index)} />
                                <input className={styles.li2} name="startDate" placeholder="Start Year" value={exp.startDate} onChange={(e) => handleExperienceChange(e, index)} />
                                <input className={styles.li2} name="endDate" placeholder="End Year" value={exp.endDate} onChange={(e) => handleExperienceChange(e, index)} />
                                <IoIosRemoveCircleOutline size={20} onClick={() => removeExperience(index)} />
                            </div>
                        ))}
                        <button type='button' onClick={addExperience}>Add</button>
                    </div>

                    {/* Education input fields */}
                    <div className={styles.inputGroup}>
                        <div>Education</div>
                        {formData.education.map((edu, index) => (
                            <div key={index} className={styles.listInput}>
                                <input className={styles.li1} name="title" placeholder="Title" value={edu.title} onChange={(e) => handleEducationChange(e, index)} />
                                <input className={styles.li2} name="startDate" placeholder="Start Year" value={edu.startDate} onChange={(e) => handleEducationChange(e, index)} />
                                <input className={styles.li2} name="endDate" placeholder="End Year" value={edu.endDate} onChange={(e) => handleEducationChange(e, index)} />
                                <IoIosRemoveCircleOutline size={20} onClick={() => removeEducation(index)} />
                            </div>
                        ))}
                        <button type='button' onClick={addEducation}>Add</button>
                    </div>

                    {/* Save button */}
                    <div className={styles.save}>
                        <button type="submit">Save</button>
                    </div>
                </form>
            </modal>
        </div>
    );
};

export default EditProfile;

//neechay mera code hai //
// import React, { useState ,useEffect} from 'react';
// import styles from './profile.module.css';
// import { IoIosRemoveCircleOutline } from 'react-icons/io';
// import { MdOutlineCancel } from 'react-icons/md';
// import { useSelector, useDispatch } from 'react-redux';
// import { updateProfile } from '../../Redux/profileSlice'; // Importing thunk action


  
  

// const EditProfile = (props) => {
//     const profile = useSelector((state) => state.profiles.profiles);
   
    
//     const dispatch = useDispatch();
//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         designation: '',
//         company: '',
//         location: '',
//         experience: [{ title: '', startDate: '', endDate: '' }],
//         education: [{ title: '', startDate: '', endDate: '' }],
//     });



//     useEffect(() => {
//             if (profile) {
//               setFormData({
//                 firstName: profile.firstName || '',
//                 lastName: profile.lastName || '',
//                 designation: profile.designation || '',
//                 company: profile.company || '',
//                 location: profile.location || '',
//                 experience: profile.experience || [],
//                 education: profile.education || [],
//               });
//             }
//           }, [profile]);
        

//     const handleInputChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value,
//         });
//     };

//     // const handleExperienceChange = (e, index) => {
//     //     const { name, value } = e.target;
//     //     const updatedExperience = [...formData.experience];
//     //     updatedExperience[index][name] = value;
//     //     setFormData({
//     //         ...formData,
//     //         experience: updatedExperience,
//     //     });



//     // };


//     const handleExperienceChange = (e, index) => {
//         const { name, value } = e.target;
//         const updatedExperience = JSON.parse(JSON.stringify(formData.experience));
//         updatedExperience[index][name] = value;
//         setFormData({
//             ...formData,
//             experience: updatedExperience,
//         });
//     };
    

//     const handleEducationChange = (e, index) => {
//         const { name, value } = e.target;
//         const updatedEducation = JSON.parse(JSON.stringify(formData.education));
//         updatedEducation[index][name] = value;
//         setFormData({
//             ...formData,
//             education: updatedEducation,
//         });
//     };

//     const removeExperience = (index) => {
//         const updatedExperience = [...formData.experience];
//         updatedExperience.splice(index, 1);
//         setFormData({
//             ...formData,
//             experience: updatedExperience,
//         });
//     };

//     const removeEducation = (index) => {
//         const updatedEducation = [...formData.education];
//         updatedEducation.splice(index, 1);
//         setFormData({
//             ...formData,
//             education: updatedEducation,
//         });
//     };

//     const addExperience = () => {
//         setFormData({
//             ...formData,
//             experience: [...formData.experience, { title: '', startDate: '', endDate: '' }],
//         });
//     };

//     const addEducation = () => {
//         setFormData({
//             ...formData,
//             education: [...formData.education, { title: '', startDate: '', endDate: '' }],
//         });
//     };

//     // const handleSubmit = (e) => {
//     //   e.preventDefault();
//     //     // Check if profile is available
//     //     if (profile && profile.id) {
//     //       const updatedProfileData = {
//     //         firstName: formData.firstName,
//     //         lastName: formData.lastName,
//     //         designation: formData.designation,
//     //         company: formData.company,
//     //         location: formData.location,
//     //         experience: formData.experience,
//     //         education: formData.education,
//     //       };
//     //       // Dispatch updateProfile with the profile id and updated data
//     //       dispatch(updateProfile({ id: profile.id, profileData: updatedProfileData }));
//     //     } else {
//     //       console.error("Profile data is not available or missing 'id' property.");
//     //       // Handle the error appropriately, e.g., display a message to the user
//     //     }
//     //   };
      
// const handleSubmit = (e) => {
//   e.preventDefault();
//   const updatedProfileData  = {
//     firstName: formData.firstName,
//     lastName: formData.lastName,
//     designation: formData.designation,
//     company: formData.company,
//     location: formData.location,
//     experience: formData.experience,
//     education: formData.education,
//   };
//   const profileId = profile._id; 
//   console.log(profileId)
//   dispatch(updateProfile({profileId,profileData: updatedProfileData }));
// };


//     // const handleSubmit = (e) => {
//     //     e.preventDefault();
//     //     dispatch(updateProfile(formData));
//     //     console.log("testsubmit")
//     //     //     // Close modal or navigate to another page after saving
//     //         // props.closemodal();
//     // };

//     return (
//         <div className={styles.editcontainer}>
//             <modal className={styles.modal}>
//                 <div className={styles.editprofileheader}>
//                     <div>Edit Profile</div>
//                     <MdOutlineCancel style={{ cursor: 'pointer' }} onClick={props.closemodal} size={30} />
//                 </div>
//                 <form onSubmit={handleSubmit}>
//                     {/* Your input fields for first name, last name, designation, company, location */}

//                       {/* Input fields for firstName, lastName, designation, company, location */}
//                       <div className={styles.inputGroup}>
//                         <div>First Name</div>
//                         <input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} />
//                     </div>
//                     <div className={styles.inputGroup}>
//                         <div>Last Name</div>
//                         <input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} />
//                     </div>
//                     <div className={styles.inputGroup}>
//                         <div>Designation</div>
//                         <input type="text" name="designation" value={formData.designation} onChange={handleInputChange} />
//                     </div>
//                     <div className={styles.inputGroup}>
//                         <div>Company</div>
//                         <input type="text" name="company" value={formData.company} onChange={handleInputChange} />
//                     </div>
//                     <div className={styles.inputGroup}>
//                         <div>Location</div>
//                         <input type="text" name="location" value={formData.location} onChange={handleInputChange} />
//                     </div>

//                     {/* Experience input fields */}
//                     <div className={styles.inputGroup}>
//                         <div>Experience</div>
//                         {formData.experience.map((exp, index) => (
//                             <div key={index} className={styles.listInput}>
//                                 <input className={styles.li1} name="title" placeholder="Title" value={exp.title} onChange={(e) => handleExperienceChange(e, index)} />
//                                 <input className={styles.li2} name="startDate" placeholder="Start Year" value={exp.startDate} onChange={(e) => handleExperienceChange(e, index)} />
//                                 <input className={styles.li2} name="endDate" placeholder="End Year" value={exp.endDate} onChange={(e) => handleExperienceChange(e, index)} />
//                                 <IoIosRemoveCircleOutline size={20} onClick={() => removeExperience(index)} />
//                             </div>
//                         ))}
//                         <button onClick={addExperience}>Add</button>
//                     </div>

//                     {/* Education input fields */}
//                     <div className={styles.inputGroup}>
//                         <div>Education</div>
//                         {formData.education.map((edu, index) => (
//                             <div key={index} className={styles.listInput}>
//                                 <input className={styles.li1} name="title" placeholder="Title" value={edu.title} onChange={(e) => handleEducationChange(e, index)} />
//                                 <input className={styles.li2} name="startDate" placeholder="Start Year" value={edu.startDate} onChange={(e) => handleEducationChange(e, index)} />
//                                 <input className={styles.li2} name="endDate" placeholder="End Year" value={edu.endDate} onChange={(e) => handleEducationChange(e, index)} />
//                                 <IoIosRemoveCircleOutline size={20} onClick={() => removeEducation(index)} />
//                             </div>
//                         ))}
//                         <button onClick={addEducation}>Add</button>
//                     </div>

//                     {/* Save button */}
//                     <div className={styles.save}>
//                         <button type="submit">Save</button>
//                     </div>
//                 </form>
//             </modal>
//         </div>
//     );
// };

// export default EditProfile;


// import React from 'react'
// import styles from './profile.module.css'
// import {IoIosRemoveCircleOutline} from 'react-icons/io'
// import {MdOutlineCancel} from 'react-icons/md'

// const EditProfile = (props)=>{
//     return(
//         <div className={styles.editcontainer}>
//         <modal className={styles.modal} >
//            <div className={styles.editprofileheader} >
//                <div>
//                    Edit Profile
//                </div>
//                <MdOutlineCancel style={{cursor:'pointer'}} onClick={props.closemodal}  size={30} />
               

//            </div>
//             <div className={styles.inputGroup} >
//                 <div>First Name</div>
//                 <input/>
//             </div>
//             <div className={styles.inputGroup} >
//                 <div>Last Name</div>
//                 <input/>
//             </div>
//             <div className={styles.inputGroup} >
//                 <div>Designation</div>
//                 <input/>
//             </div>
//             <div className={styles.inputGroup} >
//                 <div>Company</div>
//                 <input/>
//             </div>
//             <div className={styles.inputGroup} >
//                 <div>Location</div>
//                 <input/>
//             </div>
//             <div className={styles.inputGroup} >
//                 <div>Experiance</div>
//                 <div className={styles.list}>
//                     <div>fiVeD 2 year</div>
//                     <IoIosRemoveCircleOutline size={20}/>
//                  </div>
//                  <div className={styles.list}>
//                     <div>fiVeD 2 year</div>
//                     <IoIosRemoveCircleOutline size={20}/>
//                  </div>
//                  <div className={styles.listInput}>
//                 <input className={styles.li1} placeholder="Title"/>
//                 <input className={styles.li2}  placeholder="Start Year" />
//                 <input className={styles.li2}  placeholder="End Year" />
//                 <button>Add</button>
//                 </div>
//                 </div>
//                 <div className={styles.inputGroup} >
//                 <div>Education</div>
//                 <div className={styles.list}>
//                     <div></div>
//                     <IoIosRemoveCircleOutline size={20}/>
//                  </div>
//                  <div className={styles.list}>
//                     <div>fiVeD 2 year</div>
//                     <IoIosRemoveCircleOutline size={20}/>
//                  </div>
//                  <div className={styles.listInput}>
//                 <input className={styles.li1} placeholder="Institute"/>
//                 <input className={styles.li2} placeholder="Start Year" />
//                 <input className={styles.li2} placeholder="End Year" />
//                 <button>Add</button>
//                 </div>
//                 </div>
//                 <div className={styles.save}>
//                <button >Save</button> 
//             </div>
//         </modal></div>
//     )
// }


// export default EditProfile