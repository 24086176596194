import React from 'react';

import styles from '../blog/blog.module.css';

const JobLikesModal = ({ likedUsers, onClose }) => {


  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>X</button>
        <h2>Liked By</h2>
      
        <ul>
          {likedUsers.map((user) => (
            <li key={user.userId}>{`${user.firstName} ${user.lastName}`}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default JobLikesModal;
