
import React, { useEffect, useState } from 'react';
import PostHeader from '../postHeader'
import styles from '../blog/blog.module.css';
import Autosuggest from 'react-autosuggest';
import { fetchApiJobs } from '../../../Redux/jobApiSlice';
import { getCurrencySymbol } from './currencyUtils';
import { useSelector, useDispatch } from 'react-redux';





const Jobapis = () => {



    const dispatch = useDispatch();
    const { apijobs, status, totalPages, currentPage  } = useSelector((state) => state.apijobs);
    const [page, setPage] = useState(1);
    const limit = 100; // Number of items per page



    const handlePageChange = (newPage) => {
      setPage(newPage);
    };




    const truncateDescription = (description, length) => {
        return description.length > length ? description.substring(0, length) + '...' : description;
      };



    useEffect(() => {
        dispatch(fetchApiJobs({ page, limit }));
      }, [dispatch,page]);





  return (




    
    <div className='container'>


   
       


          {apijobs ? (
            apijobs.map((job) => (

              <div key={job.id} className={styles.container}>
              <div className={styles.cardHeader}>
                <img src={job.employer_logo} alt={job.employer_name} className={styles.employerLogo} />
                <div className={styles.jobInfo}>
                  <h3 className={styles.jobTitle}>{job.job_title}</h3>
                  <p className={styles.employerName}>{job.employer_name}</p>
                </div>
              </div>
              <div className={styles.cardBody}>
          
                <div className={styles.jobDetail}>
                  <strong>City:</strong> {job.job_city}
                </div>
              
                <div className={styles.jobDetail}>
                  <strong>Employment Type:</strong> {job.job_employment_type}

                </div>

                 

                  
                <div className={styles.jobDetail}>
                <strong>Max Salary:</strong> {getCurrencySymbol(job.job_country)}{job.job_max_salary}
                </div>



                <div className={styles.jobDetail}>
                <strong>Description:</strong> {truncateDescription(job.job_description, 200)}
                </div>
               
               
               
                <div className={styles.jobDetail}>
                  <strong>Posted:</strong> {new Date(job.job_posted_at_datetime_utc).toLocaleDateString()}
                </div>
              </div>
              <a href={job.job_apply_link} target="_blank" rel="noopener noreferrer" className={styles.applyButton}>Apply</a>
            </div>
        
            ))
          ) : (
            <p>No jobs found</p>
          )}
        
      




    <>
    
    <div className={styles.pagination}>
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? styles.active : ''}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
    
    </>


















    </div>





   






  )
}

export default Jobapis