import React, { useEffect, useState } from 'react';
import PostHeader from '../postHeader'
import PropTypes from 'prop-types';
// import styles from './media.module.css'
import styles from '../blog/blog.module.css';
import {RiBookmarkLine,RiSuitcaseLine} from 'react-icons/ri'
import {BsChatDots} from 'react-icons/bs'
import {AiOutlineLike} from 'react-icons/ai'
import {IoIosCheckmarkCircleOutline,IoMdRepeat,IoMdMore} from 'react-icons/io'
import { IoEyeSharp } from "react-icons/io5";
import { useSelector, useDispatch } from 'react-redux';
import { MdEdit } from "react-icons/md";
import { MdDeleteSweep } from "react-icons/md";
import { io } from 'socket.io-client';
import { AiOutlineRead, AiFillLike ,AiOutlineComment} from 'react-icons/ai';
import {socket , connectSocket, emitEvent} from "../../../Socket/socket"
import { fetchNotifications } from '../../../Redux/notificationSlice';
import { commentLearn, deleteLearn, fetchLearns ,likeLearn, updateLearn,likeComment,deleteComment,editComment, } from '../../../Redux/learnSlice';
import { RiDeleteBin5Line, RiPencilLine} from 'react-icons/ri';
import ArticleLikesModal from '../blog/articleLikeModel';
import Modal from './modal';
import { fetchProfile,deleteSharedLearn } from '../../../Redux/profileSlice';

const SharedLearn = ({sharedLearns})=>{
    const dispatch = useDispatch();
    const learns = useSelector((state) => state.learns.learns);
    const profile = useSelector((state) => state.profiles.profiles);
    const [editingIssue, setEditingIssue] = useState(null);
    const [editedText, setEditedText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
    const [likedUsers, setLikedUsers] = useState([])
    const [openComments, setOpenComments] = useState({});
    const [commentText, setCommentText] = useState('');
    const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
    const [editedCommentText, setEditedCommentText] = useState('');
    const userId = profile.userId; 
    console.log(learns)

console.log(sharedLearns,'sharedlaern')


       
    useEffect(() => {
        dispatch(fetchLearns());
      }, [dispatch]);

      if (!sharedLearns || sharedLearns.length === 0) {
        return <div>No shared learn available.</div>;
      }

    // useEffect(() => {
    //     dispatch(fetchNotifications(userId));
      
    //     const socket = io('http://localhost:7000'); // Backend URL
    //     socket.emit('join', userId);
      
    //     socket.on('notification', (newNotification) => {
    //       // Fetch notifications again or update state to include new notification
    //       dispatch(fetchNotifications(userId));
    //     });
      
    //     return () => {
    //       socket.disconnect();
    //     };
    //   }, [dispatch, userId]);  








    const handleDelete = (learnId) => {
        const userId = profile.userId;
        const isConfirmed = window.confirm('Are you sure you want to delete this post?');
        if (!isConfirmed) {
          return;
        }
        dispatch(deleteSharedLearn({ userId, learnId }))
          .then(() => {
            console.log('deleted successfully');
            dispatch(fetchProfile());
          })
          .catch((error) => {
            console.error('Error deleting job:', error);
          });
      };

    //   const handleDelete = (learnId) => {
    //     const isConfirmed = window.confirm('Are you sure you want to delete this post?');
    //     if (!isConfirmed) {
    //       return;
    //     }
    //     // Dispatch the delete comment action
    //     dispatch(deleteSharedLearn({userId,learnId}))
    //       .then(() => {
    //         // Optional: Show a success message or update the UI
    //         console.log('deleted successfully');
    //         dispatch(fetchProfile()); // Refresh articles after deletion
    //       })
    //       .catch((error) => {
    //         // Optional: Handle error if comment deletion fails
    //         console.error('Error deleting:', error);
    //       });
    //   };






      const handleSaveEdit = () => {
        // dispatch(updateIssue({ id: editingIssue, issueData: { text: editedText } }))
        dispatch(updateLearn({ id: editingIssue, learnData: { text: editedText } }))
          .then(() => {
            alert('Post edited successfully!');
            setEditingIssue(null);
            setEditedText('');
            dispatch(fetchLearns());
          })
          .catch((error) => {
            alert('Error editing post: ' + error.message);
          });
      };
    
    
      const handleEdit = (learnId, initialText) => {
        setEditingIssue(learnId);
        setEditedText(initialText);
      };


      const handleTextChange = (value) => {
        setEditedText(value);
      };









      const handleLike = (learnId) => {
        const userId = profile.userId
      //  socket.emit('notification', { learnId, userId });
       dispatch(likeLearn({ learnId }));
       dispatch(fetchLearns());
     };
   


     
  const handleOpenModal = (likedBy) => {
    setLikedUsers(likedBy);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLikedUsers([]);
  };

  const handleOpenArticleModal = (likedBy) => {
    setLikedUsers(likedBy);
    setIsModalOpen(true);
  };



     const handleArticleCloseModal = () => {
        setIsModalArticleOpen(false);
        setLikedUsers([]);
      };
    


      const handleToggleComments = (learnId) => {
        setOpenComments((prevState) => ({
          ...prevState,
          [learnId]: !prevState[learnId],
        }));
      };





      const handleCommentSubmit = (learnId, e) => {
        const userId = profile.userId
        // socket.emit('notification', { learnId, userId });
        e.preventDefault(); // Prevent page refresh
        if (commentText.trim() !== '') {
          dispatch(commentLearn({ learnId, text: commentText }))
            .then(() => {
              setCommentText('');
              dispatch(fetchLearns()); // Fetch articles again to get updated comments
            })
            .catch((error) => {
              console.error('Error submitting comment:', error);
            });
        } else {
          console.error('Comment text cannot be empty');
        }
      };





      const handleLikeComment = (learnId, commentId) => {
        // socket.emit('notification', { learnId, userId });
        dispatch(likeComment({ learnId, commentId }));
    
      };





      

      const handleDeleteComment = (learnId, commentId) => {
        console.log(learnId);
        console.log(commentId);
        // Confirm deletion
        const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
        if (!isConfirmed) {
          return;
        }
        // Dispatch the delete comment action
        dispatch(deleteComment({ learnId, commentId }))
          .then(() => {
            // Optional: Show a success message or update the UI
            console.log('Comment deleted successfully');
            dispatch(fetchLearns()); // Refresh articles after deletion
          })
          .catch((error) => {
            // Optional: Handle error if comment deletion fails
            console.error('Error deleting comment:', error);
          });
      };
    








      const handleSaveEditComment = (learnId, commentId) => {
        dispatch(editComment({ learnId, commentId, newText: editedCommentText }))
          .then(() => {
            alert('Comment edited successfully!');
            setEditingCommentId(null);
            setEditedCommentText('');
            dispatch(fetchLearns());
          })
          .catch((error) => {
            alert('Error editing comment: ' + error.message);
          });
      };
  
  
  
  
    const handleEditComment = (commentId, initialText) => {
      setEditingCommentId(commentId);
      setEditedCommentText(initialText);
    };
  
    const handleTextChangeComment = (value) => {
      setEditedCommentText(value);
    };













 

    return(
        <div >
             {sharedLearns &&
          sharedLearns.map((learn) => (
            <div key={learn._id} className={styles.container}>
              
              {learn && learn.author ? (
          <PostHeader
            firstName={learn.author.firstName}  // Author's first name
            lastName={learn.author.lastName}    // Author's last name
            profilePicture={learn.author.profilePicture}  // Author's profile picture
            icon={<AiOutlineRead size={'1rem'} />}
          />
        ) : (
          <div>Loading author details...</div>  
        )}
  
              
               
             
  {editingIssue === learn._id ? (
                <div>
                  <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
                  <button onClick={handleSaveEdit}>Save</button>
                </div>
              ) : (
                <div>
                   

                    
                  <div className={styles.content} dangerouslySetInnerHTML={{ __html: learn.text }} />

                  {learn.file && (
                      <video width="100%" controls>
                        <source src={learn.file} alt="Article" style={{ width: '100%', height: 'auto' }} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
                )}




 

                  <span>{learn.title}</span>
                  {learn.videoId && (
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${learn.videoId}`}
                    title={learn.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
                   
     <MdDeleteSweep onClick={() => handleDelete(learn._id)} size={'1rem'} />


                  { learn.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
                    <>
                    
                      
                 
                      <MdEdit  onClick={() => handleEdit(learn._id, learn.text)} size={'1rem'}  />
                      
                    </>
                  )}
  
                </div>
              )}
  
  
  
  
         
<div className={styles.commentSection}>
           
           {openComments[learn._id] &&
             learn.comments &&
             learn.comments.map((comment) => (
               <div key={comment._id} className={styles.comment}>
                 <div>
                   <img
                     alt="profile picture"
                     src="https://randomuser.me/api/portraits/men/28.jpg"
                     style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
                   />
                 </div>
                 <div
                   style={{
                     marginLeft: '40px',
                     backgroundColor: '#e6e6e6',
                     borderRadius: '10px',
                     padding: '10px',
                   }}
                 >
                   <div>
                     <strong>{comment.firstName} {comment.lastName}</strong>
                   </div>
                   <div>{comment.text}</div>
                   
                   {(comment.userId === profile.userId || learn.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
                                  <>
                                     {comment.userId === profile.userId ? (
                                       <>
                                         
                                           <RiPencilLine    onClick={() => handleEditComment(comment._id, comment.text)}
                                           className={styles.editButton} />
                                        
                                         
                                         
                                           <RiDeleteBin5Line   onClick={() => handleDeleteComment(learn._id, comment._id)}
                                           className={styles.deleteButton} />
                                        
                                       </>
                                     ) : (
                                   <RiDeleteBin5Line    onClick={() => handleDeleteComment(learn._id, comment._id)}
                                         className={styles.deleteButton} />
                                       
                                     )}
                                   </>
                                 )}
           
           
           
           
           
           
                   <AiFillLike size="1rem" onClick={() => handleLikeComment(learn._id, comment._id)} />
                   <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
                  
                  
                  
                   <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
                
                   
                   
                   
                  
                   
             </div>
           
           
           
              
           

           
           
           {editingCommentId && (
                         <div>
                           <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
                           <button onClick={() => handleSaveEditComment(learn._id, editingCommentId)}>Save</button>
                         </div>
                       )}
           
           
               </div>
             ))
           }
           
           {openComments[learn._id] && (
                           <div className={styles.addComment}>
                             <input
                               type="text"
                               value={commentText}
                               onChange={(e) => setCommentText(e.target.value)}
                               placeholder="Add a comment..."
                             />
                             <button onClick={(e) => handleCommentSubmit(learn._id, e)} className={styles.commentButton}>
                               <AiOutlineComment size="1rem" />
                               <span>Comment</span>
                             </button>
                           </div>
                         )}
           
           
                       </div>











  
 
  
  
  
  

  
  
              <div style={{ display: 'flex' }}>
                <div className={styles.intraction}>
                  <div className={styles.icons}>
                    <AiFillLike size="1rem"  onClick={() => handleLike(learn._id)} />
                    <span style={{ color: '#11a8e1' }}>{learn.likes}</span>
                    <IoEyeSharp onClick={() => handleOpenArticleModal(learn.likedBy)} />
                  
                  </div>
                  <div className={styles.icons}>
                  <BsChatDots onClick={() => handleToggleComments(learn._id)}  />
                  <span>{learn.comments?.length}</span>
                  </div>
                  <div className={styles.icons}>
                    <IoMdRepeat  size="1rem"       />
                    {/* <span style={{ color: '#11a8e1' }}>{issue.shares}</span> */}
                  </div>
                  <div className={styles.icons}>
                    <RiBookmarkLine size="1rem" />
                  </div>
                </div>
                <div className={styles.icons}>
                  <IoMdMore size="1rem" />
                </div>
              </div>
            </div>
    
    ))}
      {isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
      {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}
      </div>
            
        
    )
}

SharedLearn.propTypes = {
    sharedLearns: PropTypes.array.isRequired,
  };


export default SharedLearn