
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../Job/job.module.css';
import { BsChatDots } from 'react-icons/bs';
import { IoMdMore, IoMdRepeat } from 'react-icons/io';

import PostHeader from '../postHeader';
import { useSelector, useDispatch } from 'react-redux';
import { RiBookmarkLine, RiDeleteBin5Line, RiPencilLine } from 'react-icons/ri';

import { AiOutlineRead, AiFillLike, AiOutlineComment } from 'react-icons/ai';

import {
  fetchJobs,
  updateJob,
  deleteJob,
  likeJob,
  commentJob,
  
  deleteComment,
  editComment,
  likeComment,
  
} from '../../../Redux/jobSlice';
import { fetchProfile,deleteSharedJobs } from '../../../Redux/profileSlice';

import Modal from './modal';
import JobLikesModal from './jobLikeModal';
// Import the share job button component

const SharedJobs = ({ sharedJobs }) => {

  const profile = useSelector((state) => state.profiles.profiles);

//   const [jobs, setJobs] = useState(sharedJobs);
  const [editingJob, setEditingJob] = useState(null);
  const [editedText, setEditedText] = useState('');
  const [commentText, setCommentText] = useState('');
  const [replyText, setReplyText] = useState('');
  const [replyingToCommentId, setReplyingToCommentId] = useState(null);
  const [replyingToReplyId, setReplyingToReplyId] = useState(null);
  const [openComments, setOpenComments] = useState({});
  const [openReplies, setOpenReplies] = useState({});
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalJobOpen, setIsModalJobOpen] = useState(false);
  const [likedUsers, setLikedUsers] = useState([]);
  const [editingReplyId, setEditingReplyId] = useState(null);
  const [editedReplyText, setEditedReplyText] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJobs());
  }, [dispatch]);

  if (!sharedJobs || sharedJobs.length === 0) {
    return <div>No shared jobs available.</div>;
  }



  try {
    console.log(JSON.stringify(sharedJobs, null, 2)); // Debugging statement
  } catch (error) {
    console.error('Error logging sharedJobs:', error);
  }






  const handleLike = (jobId) => {
    dispatch(likeJob({ jobId }));
    dispatch(fetchProfile());
  };

  const handleDelete = (jobId) => {
    const userId = profile.userId;
    const isConfirmed = window.confirm('Are you sure you want to delete this post?');
    if (!isConfirmed) {
      return;
    }
    dispatch(deleteSharedJobs({ userId, jobId }))
      .then(() => {
        console.log('deleted successfully');
        dispatch(fetchProfile());
      })
      .catch((error) => {
        console.error('Error deleting job:', error);
      });
  };

  const handleSaveEdit = () => {
    dispatch(updateJob({ id: editingJob, jobData: { text: editedText } }))
      .then(() => {
        alert('Post edited successfully!');
        setEditingJob(null);
        setEditedText('');
        dispatch(fetchProfile());
      })
      .catch((error) => {
        alert('Error editing post: ' + error.message);
      });
  };

  const handleEdit = (jobId, initialText) => {
    setEditingJob(jobId);
    setEditedText(initialText);
  };

  const handleTextChange = (value) => {
    setEditedText(value);
  };

  const handleCommentSubmit = (jobId, e) => {
    e.preventDefault();
    if (commentText.trim() !== '') {
      dispatch(commentJob({ jobId, text: commentText }))
        .then(() => {
          setCommentText('');
          dispatch(fetchProfile());
        })
        .catch((error) => {
          console.error('Error submitting comment:', error);
        });
    } else {
      console.error('Comment text cannot be empty');
    }
  };

  const handleLikeComment = (jobId, commentId) => {
    dispatch(likeComment({ jobId, commentId }));
  };

  const handleDeleteComment = (jobId, commentId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
    if (!isConfirmed) {
      return;
    }
    dispatch(deleteComment({ jobId, commentId }))
      .then(() => {
        console.log('Comment deleted successfully');
        dispatch(fetchProfile());
      })
      .catch((error) => {
        console.error('Error deleting comment:', error);
      });
  };

  const handleSaveEditComment = (jobId, commentId) => {
    dispatch(editComment({ jobId, commentId, newText: editedCommentText }))
      .then(() => {
        alert('Comment edited successfully!');
        setEditingCommentId(null);
        setEditedCommentText('');
        dispatch(fetchProfile());
      })
      .catch((error) => {
        alert('Error editing comment: ' + error.message);
      });
  };

  const handleEditComment = (commentId, initialText) => {
    setEditingCommentId(commentId);
    setEditedCommentText(initialText);
  };

  const handleTextChangeComment = (value) => {
    setEditedCommentText(value);
  };

 


  



  const handleTextChangeReply = (value) => {
    setEditedReplyText(value);
  };

  const handleToggleComments = (jobId) => {
    setOpenComments((prevState) => ({
      ...prevState,
      [jobId]: !prevState[jobId],
    }));
  };

  const handleToggleReplies = (commentId) => {
    setOpenReplies((prevOpenReplies) => ({
      ...prevOpenReplies,
      [commentId]: !prevOpenReplies[commentId],
    }));
  };

  const handleOpenModal = (likedBy) => {
    setLikedUsers(likedBy);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLikedUsers([]);
  };

  const handleOpenJobModal = (likedBy) => {
    setLikedUsers(likedBy);
    setIsModalOpen(true);
  };

  const handleJobCloseModal = () => {
    setIsModalJobOpen(false);
    setLikedUsers([]);
  };

  return (
    <div className={styles.container}>
      {sharedJobs &&
        sharedJobs.map((job) => (
          <div key={job._id}>
            
            {job && job.author ? (
          <PostHeader
            firstName={job.author.firstName}  // Author's first name
            lastName={job.author.lastName}    // Author's last name
            profilePicture={job.author.profilePicture}  // Author's profile picture
            icon={<AiOutlineRead size={'1rem'} />}
          />
        ) : (
          <div>Loading author details...</div>  
        )}
              

            {editingJob === job._id ? (
              <div>
                <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
                <button onClick={handleSaveEdit}>Save</button>
              </div>
            ) : (
              <div>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: job.text }} />
                {job.file && (
                  <img src={job.file} alt="Job" style={{ width: '100%', height: 'auto' }} />
                )}
                <button onClick={() => handleDelete(job._id)}>Delete</button>
                {job.userId === profile.userId && (
                  <>
                    <button onClick={() => handleEdit(job._id, job.text)}>Edit</button>
                  </>
                )}
              </div>
            )}

            <div className={styles.commentSection}>
              {openComments[job._id] &&
                job.comments &&
                job.comments.map((comment) => (
                  <div key={comment._id} className={styles.comment}>
                    <div>
                      <img
                        alt="profile picture"
                        src="https://randomuser.me/api/portraits/men/28.jpg"
                        style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: '40px',
                        backgroundColor: '#e6e6e6',
                        borderRadius: '10px',
                        padding: '10px',
                      }}
                    >
                      <div>
                        <strong>{comment.firstName} {comment.lastName}</strong>
                      </div>
                      <div>{comment.text}</div>
                      {(comment.userId === profile.userId || job.userId === profile.userId) && (
                        <>
                          {comment.userId === profile.userId ? (
                            <>
                              <RiPencilLine onClick={() => handleEditComment(comment._id, comment.text)} className={styles.editButton} />
                              <RiDeleteBin5Line onClick={() => handleDeleteComment(job._id, comment._id)} className={styles.deleteButton} />
                            </>
                          ) : (
                            <RiDeleteBin5Line onClick={() => handleDeleteComment(job._id, comment._id)} className={styles.deleteButton} />
                          )}
                        </>
                      )}
                      <AiFillLike size="1rem" onClick={() => handleLikeComment(job._id, comment._id)} />
                      <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
                      <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
                      <button onClick={() => setReplyingToCommentId(comment._id)}>Reply</button>
                      {comment.replies && comment.replies.length > 0 && (
                        <button onClick={() => handleToggleReplies(comment._id)}>
                          {openReplies[comment._id] ? 'Hide Replies' : `View Replies (${comment.replies.length})`}
                        </button>
                      )}
                      {openReplies[comment._id] &&
                        comment.replies &&
                        comment.replies.map((reply) => (
                          <div key={reply._id} className={styles.reply}>
                            <div>
                              <img
                                alt="profile picture"
                                src="https://randomuser.me/api/portraits/men/28.jpg"
                                style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: '40px',
                                backgroundColor: '#e6e6e6',
                                borderRadius: '10px',
                                padding: '10px',
                              }}
                            >
                              <strong>{reply.firstName} {reply.lastName}</strong>
                              <div>{reply.text}</div>
                              <AiFillLike size="1rem" />
                              <span style={{ color: '#11a8e1' }}>{reply.likes}</span>
                              {(reply.userId === profile.userId || job.userId === profile.userId) && (
                                <>
                                  {reply.userId === profile.userId ? (
                                    <>
                                      <RiPencilLine className={styles.editButton} />
                                      <RiDeleteBin5Line className={styles.deleteButton} />
                                    </>
                                  ) : (
                                    <RiDeleteBin5Line  className={styles.deleteButton} />
                                  )}
                                </>
                              )}
                              {editingReplyId === reply._id && (
                                <>
                                  <textarea value={editedReplyText} onChange={(e) => handleTextChangeReply(e.target.value)} className={styles.editTextArea} />
                                 
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                      {replyingToCommentId === comment._id && (
                        <div className={styles.replySection}>
                          <input
                            type="text"
                            value={replyText}
                            onChange={(e) => setReplyText(e.target.value)}
                            placeholder="Write a reply..."
                          />
                          <button >
                            <AiOutlineComment size="1rem" />
                            <span>Reply</span>
                          </button>
                        </div>
                      )}
                      {editingCommentId && (
                        <div>
                          <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
                          <button onClick={() => handleSaveEditComment(job._id, editingCommentId)}>Save</button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              {openComments[job._id] && (
                <div className={styles.addComment}>
                  <input
                    type="text"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    placeholder="Add a comment..."
                  />
                  <button onClick={(e) => handleCommentSubmit(job._id, e)} className={styles.commentButton}>
                    <AiOutlineComment size="1rem" />
                    <span>Comment</span>
                  </button>
                </div>
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <div className={styles.intraction}>
                <div className={styles.icons}>
                  <AiFillLike onClick={() => handleLike(job._id)} size="1rem" color={job.isLiked ? 'blue' : 'black'} />
                  <span style={{ color: '#11a8e1' }}>{job.likes}</span>
                  <button onClick={() => handleOpenJobModal(job.likedBy)}>View Likes</button>
                </div>
                <div className={styles.icons}>
                  <BsChatDots size="1rem" onClick={() => handleToggleComments(job._id)} />
                  <span>{job.comments?.length}</span>
                </div>
                <div className={styles.icons}>
                  <IoMdRepeat size="1rem"  />
                  <span style={{ color: '#11a8e1' }}>{job.shares}</span>
                </div>
                <div className={styles.icons}>
                  <RiBookmarkLine size="1rem" />
                </div>
              </div>
              <div className={styles.icons}>
                <IoMdMore size="1rem" />
              </div>
            </div>
          </div>
        ))}
      {isModalJobOpen && <JobLikesModal likedUsers={likedUsers} onClose={handleJobCloseModal} />}
      {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}
    </div>
  );
};

SharedJobs.propTypes = {
  sharedJobs: PropTypes.array.isRequired,
};

export default SharedJobs;




// import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import styles from '../blog/blog.module.css';
// import { BsChatDots } from 'react-icons/bs';
// import { IoMdMore,IoMdRepeat } from 'react-icons/io';

// import PostHeader from '../postHeader';
// import { useSelector,useDispatch } from 'react-redux';
// import { RiBookmarkLine, RiDeleteBin5Line, RiPencilLine } from 'react-icons/ri';

// import { AiOutlineRead, AiFillLike, AiOutlineComment } from 'react-icons/ai';


// import Modal from '../blog/modal';
// import ArticleLikesModal from '../blog/articleLikeModel';
// import { fetchJobs ,updateJob,deleteJob,likeJob,likeComment,deleteComment,editComment, commentJob,shareJob

// } from '../../../Redux/jobSlice';


//   import {
//     deleteSharedJobs
//   } from "../../../Redux/profileSlice"
  
//   import { fetchProfile } from '../../../Redux/profileSlice';

// const SharedJobs = ({ sharedJobs }) => {

//     const profile = useSelector((state) => state.profiles.profiles);

//     const [articles, setArticles] = useState(sharedJobs); 
//     const [editingArticle, setEditingArticle] = useState(null);
//     const [editedText, setEditedText] = useState('');
//     const [commentText, setCommentText] = useState('');
//     const [replyText, setReplyText] = useState('');
//     const [replyingToCommentId, setReplyingToCommentId] = useState(null);
//     const [replyingToReplyId, setReplyingToReplyId] = useState(null);
//     const [openComments, setOpenComments] = useState({});
//     const [openReplies, setOpenReplies] = useState({});
//     const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
//     const [editedCommentText, setEditedCommentText] = useState('');
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
//     const [likedUsers, setLikedUsers] = useState([])
//     const [editingReplyId, setEditingReplyId] = useState(null); // State to track the reply being edited
//     const [editedReplyText, setEditedReplyText] = useState('');
//     const [editingJob, setEditingJob] = useState(null);
//     const dispatch = useDispatch();
    

  

//   useEffect(() => {
//     dispatch(fetchJobs());
//   }, [dispatch]);


//   if (!sharedJobs || sharedJobs.length === 0) {
//     return <div>No shared articles available.</div>;
//   }



//   console.log(sharedJobs)


//   const handleSaveEdit = () => {
//     dispatch(updateJob({ id: editingJob, jobData: { text: editedText } }))
//       .then(() => {
//         alert('Post edited successfully!');
//         setEditingJob(null);
//         setEditedText('');
//         dispatch(fetchJobs());
//       })
//       .catch((error) => {
//         alert('Error editing post: ' + error.message);
//       });
//   };


//   const handleEdit = (jobId, initialText) => {
//     setEditingJob(jobId);
//     setEditedText(initialText);
//   };


//   const handleTextChange = (value) => {
//     setEditedText(value);
//   };



  
//   const handleDelete = (jobId) => {
//     const userId = profile.userId; 
//     const isConfirmed = window.confirm('Are you sure you want to delete this post?');
//     if (!isConfirmed) {
//       return;
//     }
//     // Dispatch the delete comment action
//     dispatch(deleteSharedJobs({ userId, jobId }))
//       .then(() => {
//         // Optional: Show a success message or update the UI
//         console.log('deleted successfully');
//         dispatch(fetchProfile()); // Refresh articles after deletion
//       })
//       .catch((error) => {
//         // Optional: Handle error if comment deletion fails
//         console.error('Error deleting comment:', error);
//       });
//   };



//   const handleLike = (jobId) => {

//    dispatch(likeJob({ jobId }));
//    dispatch(fetchJobs());
//  };




//  const handleLikeComment = (jobId, commentId) => {
    
//     dispatch(likeComment({ jobId, commentId }));

//   };






//   // Handle Comment Close Open Toggler
//   const handleToggleComments = (jobId) => {
//     setOpenComments((prevState) => ({
//       ...prevState,
//       [jobId]: !prevState[jobId],
//     }));
//   };

 


//   const handleOpenModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setLikedUsers([]);
//   };

//   const handleOpenArticleModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalOpen(true);
//   };

//   const handleArticleCloseModal = () => {
//     setIsModalArticleOpen(false);
//     setLikedUsers([]);
//   };






//   const handleCommentSubmit = (jobId, e) => {

//     e.preventDefault(); // Prevent page refresh
//     if (commentText.trim() !== '') {
//       dispatch(commentJob({ jobId, text: commentText }))
//         .then(() => {
//           setCommentText('');
//           dispatch(fetchJobs()); // Fetch articles again to get updated comments
//         })
//         .catch((error) => {
//           console.error('Error submitting comment:', error);
//         });
//     } else {
//       console.error('Comment text cannot be empty');
//     }
//   };









  

//   const handleDeleteComment = (jobId, commentId) => {
//     console.log(jobId);
//     console.log(commentId);
//     // Confirm deletion
//     const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
//     if (!isConfirmed) {
//       return;
//     }
//     // Dispatch the delete comment action
//     dispatch(deleteComment({ jobId, commentId }))
//       .then(() => {
//         // Optional: Show a success message or update the UI
//         console.log('Comment deleted successfully');
//         dispatch(fetchJobs()); // Refresh articles after deletion
//       })
//       .catch((error) => {
//         // Optional: Handle error if comment deletion fails
//         console.error('Error deleting comment:', error);
//       });
//   };









//   const handleSaveEditComment = (jobId, commentId) => {
//     dispatch(editComment({ jobId, commentId, newText: editedCommentText }))
//       .then(() => {
//         alert('Comment edited successfully!');
//         setEditingCommentId(null);
//         setEditedCommentText('');
//         dispatch(fetchJobs());
//       })
//       .catch((error) => {
//         alert('Error editing comment: ' + error.message);
//       });
//   };




// const handleEditComment = (commentId, initialText) => {
//   setEditingCommentId(commentId);
//   setEditedCommentText(initialText);
// };

// const handleTextChangeComment = (value) => {
//   setEditedCommentText(value);
// };












//   return (


//     <>



// <div className={styles.container}>
//       {sharedJobs &&
//         sharedJobs.map((job) => (
//           <div key={job._id}>
//             <PostHeader     firstName={job.firstName} 
//                             lastName={job.lastName}    icon={<AiOutlineRead size={'1rem'} />} />

//             {editingArticle === job._id ? (
//               <div>
//                 <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
//                 <button onClick={handleSaveEdit}>Save</button>
//               </div>
//             ) : (
//               <div>
//                 <div className={styles.content} dangerouslySetInnerHTML={{ __html: job.text }} />
//                 {job.file && (
//                   <img src={require(`../../../images/${job.file.name}`).default} alt="Article" style={{ width: '100%', height: 'auto' }} />
//                 )}

//                 <button onClick={() => handleDelete(job._id)}>Delete</button>
//                 { job.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
//                   <>
                    
//                     <button onClick={() => handleEdit(job._id, job.text)}>Edit</button>
//                   </>
//                 )}

//               </div>
//             )}





//             <div className={styles.commentSection}>
           
// {openComments[job._id] &&
//   job.comments &&
//   job.comments.map((comment) => (
//     <div key={comment._id} className={styles.comment}>
//       <div>
//         <img
//           alt="profile picture"
//           src="https://randomuser.me/api/portraits/men/28.jpg"
//           style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
//         />
//       </div>
//       <div
//         style={{
//           marginLeft: '40px',
//           backgroundColor: '#e6e6e6',
//           borderRadius: '10px',
//           padding: '10px',
//         }}
//       >
//         <div>
//           <strong>{comment.firstName} {comment.lastName}</strong>
//         </div>
//         <div>{comment.text}</div>
        
//         {(comment.userId === profile.userId || job.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
//                        <>
//                           {comment.userId === profile.userId ? (
//                             <>
                              
//                                 <RiPencilLine    onClick={() => handleEditComment(comment._id, comment.text)}
//                                 className={styles.editButton} />
                             
                              
                              
//                                 <RiDeleteBin5Line   onClick={() => handleDeleteComment(job._id, comment._id)}
//                                 className={styles.deleteButton} />
                             
//                             </>
//                           ) : (
//                         <RiDeleteBin5Line    onClick={() => handleDeleteComment(job._id, comment._id)}
//                               className={styles.deleteButton} />
                            
//                           )}
//                         </>
//                       )}






//         <AiFillLike size="1rem" onClick={() => handleLikeComment(job._id, comment._id)} />
//         <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
       
       
       
//         <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
//         <button onClick={() => setReplyingToCommentId(comment._id)}>Reply</button>
        
        
        
    
 
        
       
      
//       </div>



    







// {editingCommentId && (
//               <div>
//                 <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
//                 <button onClick={() => handleSaveEditComment(job._id, editingCommentId)}>Save</button>
//               </div>
//             )}


//     </div>
//   ))
// }

// {openComments[job._id] && (
//                 <div className={styles.addComment}>
//                   <input
//                     type="text"
//                     value={commentText}
//                     onChange={(e) => setCommentText(e.target.value)}
//                     placeholder="Add a comment..."
//                   />
//                   <button onClick={(e) => handleCommentSubmit(job._id, e)} className={styles.commentButton}>
//                     <AiOutlineComment size="1rem" />
//                     <span>Comment</span>
//                   </button>
//                 </div>
//               )}


//             </div>

//             <div style={{ display: 'flex' }}>
//               <div className={styles.intraction}>
//                 <div className={styles.icons}>
//                   <AiFillLike onClick={() => handleLike(job._id)} size="1rem" color={job.isLiked ? 'blue' : 'black'} />
//                   <span style={{ color: '#11a8e1' }}>{job.likes}</span>
//                   <button onClick={() => handleOpenArticleModal(job.likedBy)}>View Likes</button>
//                 </div>
//                 <div className={styles.icons}>
//                   <BsChatDots size="1rem" onClick={() => handleToggleComments(job._id)} />
//                   <span>{job.comments?.length}</span>
//                 </div>
//                 <div className={styles.icons}>
//                   <IoMdRepeat />
//                   <span style={{ color: '#11a8e1' }}>{job.shares}</span>
//                 </div>
//                 <div className={styles.icons}>
//                   <RiBookmarkLine size="1rem" />
//                 </div>
//               </div>
//               <div className={styles.icons}>
//                 <IoMdMore size="1rem" />
//               </div>
//             </div>
//           </div>
//         ))}

//       {isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
//       {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}
//     </div>

        
        
        

    
     
//  </>
   
//   );
// };

// SharedJobs.propTypes = {
//   sharedJobs: PropTypes.array.isRequired,
// };

// export default SharedJobs;























// import React, { useEffect, useState } from 'react';
// import PostHeader from '../postHeader'
// import styles from '../blog/blog.module.css';


// import {IoIosCheckmarkCircleOutline,IoMdRepeat,IoMdMore} from 'react-icons/io'
// import { useSelector, useDispatch } from 'react-redux';
// import { BsChatDots } from 'react-icons/bs';
// import { fetchJobs ,updateJob,deleteJob,likeJob,likeComment,deleteComment,editComment, commentJob

// } from '../../../Redux/jobSlice';
// import { FaEdit } from "react-icons/fa";
// import { AiOutlineRead, AiFillLike ,AiOutlineComment} from 'react-icons/ai';
// import { RiBookmarkLine, RiDeleteBin5Line, RiPencilLine} from 'react-icons/ri';
// import PropTypes from 'prop-types';
// import { MdEdit } from "react-icons/md";
// import { MdDeleteSweep } from "react-icons/md";
// import { io } from 'socket.io-client';
// import { fetchNotifications } from '../../../Redux/notificationSlice';

// import { IoEyeSharp } from "react-icons/io5";
// import Modal from './modal';
// import JobLikesModal from './jobLikeModal'
// import ArticleLikesModal from '../blog/articleLikeModel'




// const SharedJob= ({sharedJobs})=>{
    
    
    
    
    
//     const dispatch = useDispatch();

//     const profile = useSelector((state) => state.profiles.profiles);
//     const [jobs, setJobs] = useState(sharedJobs); 
//     const [editingJob, setEditingJob] = useState(null);
//     const [editedText, setEditedText] = useState('');
//     const [isModalOpen, setIsModalOpen] = useState(false);
//    const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
//    const [openComments, setOpenComments] = useState({});
//     const [likedUsers, setLikedUsers] = useState([])
//     const [commentText, setCommentText] = useState('');
//     const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
//   const [editedCommentText, setEditedCommentText] = useState('');
//     const userId = profile.userId; 



// console.log(sharedJobs)
 





//     useEffect(() => {
//         dispatch(fetchJobs());
//       }, [dispatch]);
    
    
//       if (!sharedJobs || sharedJobs.length === 0) {
//         return <div>No shared articles available.</div>;
//       }
    









//     const handleSaveEdit = () => {
//         dispatch(updateJob({ id: editingJob, jobData: { text: editedText } }))
//           .then(() => {
//             alert('Post edited successfully!');
//             setEditingJob(null);
//             setEditedText('');
//             dispatch(fetchJobs());
//           })
//           .catch((error) => {
//             alert('Error editing post: ' + error.message);
//           });
//       };
    
    
//       const handleEdit = (jobId, initialText) => {
//         setEditingJob(jobId);
//         setEditedText(initialText);
//       };


//       const handleTextChange = (value) => {
//         setEditedText(value);
//       };


  
//       const handleDelete = (jobId) => {
//         const isConfirmed = window.confirm('Are you sure you want to delete this post?');
//         if (!isConfirmed) {
//           return;
//         }
//         // Dispatch the delete comment action
//         dispatch(deleteJob(jobId))
//           .then(() => {
//             // Optional: Show a success message or update the UI
//             console.log('deleted successfully');
//             dispatch(fetchJobs()); // Refresh articles after deletion
//           })
//           .catch((error) => {
//             // Optional: Handle error if comment deletion fails
//             console.error('Error deleting:', error);
//           });
//       };
    



//       const handleLike = (jobId) => {
      
//        dispatch(likeJob({ jobId }));
//        dispatch(fetchJobs());
//      };
   





 
    
  
//      const handleOpenModal = (likedBy) => {
//         setLikedUsers(likedBy);
//         setIsModalOpen(true);
//       };
    
//       const handleCloseModal = () => {
//         setIsModalOpen(false);
//         setLikedUsers([]);
//       };
    
//       const handleOpenJobModal = (likedBy) => {
//         setLikedUsers(likedBy);
//         setIsModalOpen(true);
//       };
    
//       const handleArticleCloseModal = () => {
//         setIsModalArticleOpen(false);
//         setLikedUsers([]);
//       };
    

//       const handleToggleComments = (articleId) => {
//         setOpenComments((prevState) => ({
//           ...prevState,
//           [articleId]: !prevState[articleId],
//         }));
//       };
    




//       const handleLikeComment = (jobId, commentId) => {
       
//         dispatch(likeComment({ jobId, commentId }));
    
//       };



//       const handleCommentSubmit = (jobId, e) => {
      
//         e.preventDefault(); // Prevent page refresh
//         if (commentText.trim() !== '') {
//           dispatch(commentJob({ jobId, text: commentText }))
//             .then(() => {
//               setCommentText('');
//               dispatch(fetchJobs()); // Fetch articles again to get updated comments
//             })
//             .catch((error) => {
//               console.error('Error submitting comment:', error);
//             });
//         } else {
//           console.error('Comment text cannot be empty');
//         }
//       };









      

//       const handleDeleteComment = (jobId, commentId) => {
//         console.log(jobId);
//         console.log(commentId);
//         // Confirm deletion
//         const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
//         if (!isConfirmed) {
//           return;
//         }
//         // Dispatch the delete comment action
//         dispatch(deleteComment({ jobId, commentId }))
//           .then(() => {
//             // Optional: Show a success message or update the UI
//             console.log('Comment deleted successfully');
//             dispatch(fetchJobs()); // Refresh articles after deletion
//           })
//           .catch((error) => {
//             // Optional: Handle error if comment deletion fails
//             console.error('Error deleting comment:', error);
//           });
//       };
    








//       const handleSaveEditComment = (jobId, commentId) => {
//         dispatch(editComment({ jobId, commentId, newText: editedCommentText }))
//           .then(() => {
//             alert('Comment edited successfully!');
//             setEditingCommentId(null);
//             setEditedCommentText('');
//             dispatch(fetchJobs());
//           })
//           .catch((error) => {
//             alert('Error editing comment: ' + error.message);
//           });
//       };
  
  
  
  
//     const handleEditComment = (commentId, initialText) => {
//       setEditingCommentId(commentId);
//       setEditedCommentText(initialText);
//     };
  
//     const handleTextChangeComment = (value) => {
//       setEditedCommentText(value);
//     };
  















//     // useEffect(() => {
//     //     dispatch(fetchJobs());
//     //   }, [dispatch]);




//     return(
//         <div className={styles.container}>
//       {sharedJobs &&
//         sharedJobs.map((job) => (
//           <div key={job._id}>
//             <PostHeader     firstName={job.firstName} 
//                             lastName={job.lastName}    icon={<AiOutlineRead size={'1rem'} />} />

            
             
           
// {editingJob === job._id ? (
//               <div>
//                 <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
//                 <button onClick={handleSaveEdit}>Save</button>
//               </div>
//             ) : (
//               <div>
//                 <div className={styles.content} dangerouslySetInnerHTML={{ __html: job.text }} />
//                 {job.file && (
//                   <img src={require(`../../../images/${job.file.name}`).default} alt="Article" style={{ width: '100%', height: 'auto' }} />
//                 )}
                
//                 { job.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
//                   <>
                  
                    
//                     <MdDeleteSweep onClick={() => handleDelete(job._id)} size={'1rem'} />
//                     <MdEdit  onClick={() => handleEdit(job._id, job.text)} size={'1rem'}  />
                    
//                   </>
//                 )}

//               </div>
//             )}






// <div className={styles.commentSection}>
           
//            {openComments[job._id] &&
//              job.comments &&
//              job.comments.map((comment) => (
//                <div key={comment._id} className={styles.comment}>
//                  <div>
//                    <img
//                      alt="profile picture"
//                      src="https://randomuser.me/api/portraits/men/28.jpg"
//                      style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
//                    />
//                  </div>
//                  <div
//                    style={{
//                      marginLeft: '40px',
//                      backgroundColor: '#e6e6e6',
//                      borderRadius: '10px',
//                      padding: '10px',
//                    }}
//                  >
//                    <div>
//                      <strong>{comment.firstName} {comment.lastName}</strong>
//                    </div>
//                    <div>{comment.text}</div>
                   
//                    {(comment.userId === profile.userId || job.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
//                                   <>
//                                      {comment.userId === profile.userId ? (
//                                        <>
                                         
//                                            <RiPencilLine    onClick={() => handleEditComment(comment._id, comment.text)}
//                                            className={styles.editButton} />
                                        
                                         
                                         
//                                            <RiDeleteBin5Line   onClick={() => handleDeleteComment(job._id, comment._id)}
//                                            className={styles.deleteButton} />
                                        
//                                        </>
//                                      ) : (
//                                    <RiDeleteBin5Line    onClick={() => handleDeleteComment(job._id, comment._id)}
//                                          className={styles.deleteButton} />
                                       
//                                      )}
//                                    </>
//                                  )}
           
           
           
           
           
           
//                    <AiFillLike size="1rem" onClick={() => handleLikeComment(job._id, comment._id)} />
//                    <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
                  
                  
                  
//                    <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
                
                   
                   
                   
                  
                   
//              </div>
           
           
           
              
           

           
           
//            {editingCommentId && (
//                          <div>
//                            <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
//                            <button onClick={() => handleSaveEditComment(job._id, editingCommentId)}>Save</button>
//                          </div>
//                        )}
           
           
//                </div>
//              ))
//            }
           
//            {openComments[job._id] && (
//                            <div className={styles.addComment}>
//                              <input
//                                type="text"
//                                value={commentText}
//                                onChange={(e) => setCommentText(e.target.value)}
//                                placeholder="Add a comment..."
//                              />
//                              <button onClick={(e) => handleCommentSubmit(job._id, e)} className={styles.commentButton}>
//                                <AiOutlineComment size="1rem" />
//                                <span>Comment</span>
//                              </button>
//                            </div>
//                          )}
           
           
//                        </div>









//             <div style={{ display: 'flex' }}>
//               <div className={styles.intraction}>
//                 <div className={styles.icons}>
//                   <AiFillLike size="1rem"  onClick={() => handleLike(job._id)} />
//                   <span style={{ color: '#11a8e1' }}>{job.likes}</span>
//                   <IoEyeSharp onClick={() => handleOpenJobModal(job.likedBy)} />
                
//                 </div>
//                 <div className={styles.icons}>
//                 <BsChatDots size="1rem" onClick={() => handleToggleComments(job._id)} />
//                 <span>{job.comments?.length}</span>
//                 </div>
//                 <div className={styles.icons}>
//                   <IoMdRepeat    />
//                   <span style={{ color: '#11a8e1' }}></span>
//                 </div>
//                 <div className={styles.icons}>
//                   <RiBookmarkLine size="1rem" />
//                 </div>
//               </div>
//               <div className={styles.icons}>
//                 <IoMdMore size="1rem" />
//               </div>
//             </div>
//           </div>
  
//   ))}
// {isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
// {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}
   
//     </div>
//     )
// }



// SharedJob.propTypes = {
//     sharedJobs: PropTypes.array.isRequired,
//   };

// export default SharedJob