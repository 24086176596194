import React, { useEffect, useState } from 'react';
import PostHeader from '../postHeader'
// import styles from './media.module.css'
import styles from '../blog/blog.module.css';
import { GrFormViewHide } from "react-icons/gr";
import {RiBookmarkLine,RiSuitcaseLine} from 'react-icons/ri'
import {BsChatDots} from 'react-icons/bs'
import {AiOutlineLike} from 'react-icons/ai'
import {IoIosCheckmarkCircleOutline,IoMdRepeat,IoMdMore} from 'react-icons/io'
import { IoEyeSharp } from "react-icons/io5";
import { useSelector, useDispatch } from 'react-redux';
import { MdEdit } from "react-icons/md";
import { MdDeleteSweep } from "react-icons/md";
import { io } from 'socket.io-client';
import { AiOutlineRead, AiFillLike ,AiOutlineComment} from 'react-icons/ai';
import {socket , connectSocket, emitEvent} from "../../../Socket/socket"
import { fetchNotifications } from '../../../Redux/notificationSlice';
import { commentLearn, deleteLearn, fetchLearns ,likeLearn, updateLearn,likeComment,deleteComment,editComment,shareLearn,searchLearns} from '../../../Redux/learnSlice';
import { RiDeleteBin5Line, RiPencilLine} from 'react-icons/ri';
import ArticleLikesModal from '../blog/articleLikeModel';
import Modal from './modal';
import LazyLoad from 'react-lazyload';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { MentionsInput, Mention } from 'react-mentions';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { fetchAllProfiles } from '../../../Redux/profileSlice';

import Pusher from 'pusher-js';


const MediaTl= ()=>{
    const dispatch = useDispatch();
    // const learns = useSelector((state) => state.learns.learns);
    const [searchQuery, setSearchQuery] = useState('');
    const { learns, status, totalPages, currentPage , searchResults, searchStatus, searchError} = useSelector((state) => state.learns);
    const profile = useSelector((state) => state.profiles.profiles);
    const [editingIssue, setEditingIssue] = useState(null);
    const [editedText, setEditedText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
    const [likedUsers, setLikedUsers] = useState([])
    const [openComments, setOpenComments] = useState({});
    const [commentText, setCommentText] = useState('');
    const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
    const [editedCommentText, setEditedCommentText] = useState('');
    const [page, setPage] = useState(1);
    const allProfiles = useSelector((state) => state.profiles.allProfiles);
    const { userId } = useParams(); 
    const navigate = useNavigate();
    const limit = 1000; 
    // const userId = profile.userId; 
    console.log(learns)

    dayjs.extend(relativeTime);



 // Pusher setup
 useEffect(() => {
  const pusher = new Pusher('8d13c6a59a85bd1417e7', {
    cluster: 'eu',
  });

  // Subscribe to the issue channel
  const channel = pusher.subscribe('learn-channel');



  channel.bind('new-mention', (newNotification) => {
    console.log('Mention Notification: ', newNotification);
    dispatch(fetchNotifications(userId)); 
    // Show real-time notification to the user
  });


  // Listen for the 'like-article' event and update notifications
  channel.bind('like-learn', (newNotification) => {
    console.log('New notification received:test', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });


  channel.bind('like-learn-comment', (newNotification) => {
    console.log('New notification received:test', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });




  // Listen for the 'new-comment' event
  channel.bind('new-comment', (newNotification) => {
    console.log('New comment notification received:', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });

  // Listen for the 'share-article' event
  channel.bind('share-learn', (newNotification) => {
    console.log('New article share notification received:', newNotification);
    dispatch(fetchNotifications(userId)); // Refetch notifications
  });










  // Cleanup: Unsubscribe from the channel when the component unmounts
  return () => {
    channel.unbind_all();
    channel.unsubscribe();
  };
}, [dispatch, userId]);




    // useEffect(() => {
    //     dispatch(fetchNotifications(userId));
      
    //     const socket = io('http://localhost:7000'); // Backend URL
    //     socket.emit('join', userId);
      
    //     socket.on('notification', (newNotification) => {
    //       // Fetch notifications again or update state to include new notification
    //       dispatch(fetchNotifications(userId));
    //     });
      
    //     return () => {
    //       socket.disconnect();
    //     };
    //   }, [dispatch, userId]);  






    const handleProfileClick = (userId) => {
      console.log(userId); 
      if (userId) {
        navigate(`/profile/${userId}`)
        console.log(userId); // Redirect to profile page with userId
      }
    };
  







      const handleDelete = (learnId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this post?');
        if (!isConfirmed) {
          return;
        }
        // Dispatch the delete comment action
        dispatch(deleteLearn(learnId))
          .then(() => {
            // Optional: Show a success message or update the UI
            console.log('deleted successfully');
            dispatch(fetchLearns({ page, limit }));// Refresh articles after deletion
          })
          .catch((error) => {
            // Optional: Handle error if comment deletion fails
            console.error('Error deleting:', error);
          });
      };




      const handleSearch = (e) => {
        e.preventDefault();
        dispatch(searchLearns(searchQuery));
      };



      const handleSaveEdit = () => {
        // dispatch(updateIssue({ id: editingIssue, issueData: { text: editedText } }))
        dispatch(updateLearn({ id: editingIssue, learnData: { text: editedText } }))
          .then(() => {
            alert('Post edited successfully!');
            setEditingIssue(null);
            setEditedText('');
            dispatch(fetchLearns({ page, limit }));
          })
          .catch((error) => {
            alert('Error editing post: ' + error.message);
          });
      };
    
    
      const handleEdit = (learnId, initialText) => {
        setEditingIssue(learnId);
        setEditedText(initialText);
      };


      const handleTextChange = (value) => {
        setEditedText(value);
      };









      const handleLike = (learnId) => {
        const userId = profile.userId
      //  socket.emit('notification', { learnId, userId });
       dispatch(likeLearn({ learnId }));
       dispatch(fetchLearns({ page, limit }));
     };
   


     
  const handleOpenModal = (likedBy) => {
    setLikedUsers(likedBy);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLikedUsers([]);
  };

  const handleOpenArticleModal = (likedBy) => {
    setLikedUsers(likedBy);
    setIsModalOpen(true);
  };



     const handleArticleCloseModal = () => {
        setIsModalArticleOpen(false);
        setLikedUsers([]);
      };
    


      const handleToggleComments = (learnId) => {
        setOpenComments((prevState) => ({
          ...prevState,
          [learnId]: !prevState[learnId],
        }));
      };





      const users = Array.isArray(allProfiles) 
      ? allProfiles.map(user => ({
          id: user._id,
          display: `${user.firstName} ${user.lastName}`,
        })) 
      : [];
    
    // Extract mentions in `@[displayName](userId)` format
    const extractMentionedUsers = (text) => {
      
      const mentionPattern = /@\[(.*?)\]\((.*?)\)/g;
      const mentionedUsers = [];
      let match;
      while ((match = mentionPattern.exec(text)) !== null) {
        mentionedUsers.push({
          displayName: match[1],
          userId: match[2]
        });
      }
      return mentionedUsers;
    };
    
    const formatMentions = (text) => {
      return text.replace(/@\[(.*?)\]\((.*?)\)/g, '@$1'); // Replaces with @displayName
    };
    
    const handleCommentSubmit = async (learnId, e) => {
      e.preventDefault();
      const mentionedUsers = extractMentionedUsers(commentText); // Extracts mentioned users from the comment text
    
      console.log("Mentioned Users:", mentionedUsers);
      console.log("Comment Text:", commentText);
    
      if (commentText.trim() === '') {
        console.error('Comment text cannot be empty');
        return;
      }
    
      try {
        await dispatch(commentLearn({ learnId, text: commentText, mentionedUsers }));
        setCommentText('');
        console.log("Comment dispatched successfully:", { learnId, text: commentText, mentionedUsers });
        dispatch(fetchLearns({ page, limit }));
      } catch (error) {
        console.error('Error submitting comment:', error.message);
        if (error.response) {
          console.error("Backend response:", error.response.data);
        }
      }
    };




      const handleLikeComment = (learnId, commentId) => {
        // socket.emit('notification', { learnId, userId });
        dispatch(likeComment({ learnId, commentId }));
    
      };





      

      const handleDeleteComment = (learnId, commentId) => {
        console.log(learnId);
        console.log(commentId);
        // Confirm deletion
        const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
        if (!isConfirmed) {
          return;
        }
        // Dispatch the delete comment action
        dispatch(deleteComment({ learnId, commentId }))
          .then(() => {
            // Optional: Show a success message or update the UI
            console.log('Comment deleted successfully');
            dispatch(fetchLearns({ page, limit }));// Refresh articles after deletion
          })
          .catch((error) => {
            // Optional: Handle error if comment deletion fails
            console.error('Error deleting comment:', error);
          });
      };
    








      const handleSaveEditComment = (learnId, commentId) => {
        dispatch(editComment({ learnId, commentId, newText: editedCommentText }))
          .then(() => {
            alert('Comment edited successfully!');
            setEditingCommentId(null);
            setEditedCommentText('');
            dispatch(fetchLearns({ page, limit }));
          })
          .catch((error) => {
            alert('Error editing comment: ' + error.message);
          });
      };
  
  
  
  
    const handleEditComment = (commentId, initialText) => {
      setEditingCommentId(commentId);
      setEditedCommentText(initialText);
    };
  
    const handleTextChangeComment = (value) => {
      setEditedCommentText(value);
    };







    const handleShare = (learnId) => {
        const userId = profile.userId; // Get the user ID from the profile
        // socket.emit('notification', { learnId, userId });
        dispatch(shareLearn({ learnId, userId }));
        dispatch(fetchLearns({ page, limit }));
      };
    



      const handlePageChange = (newPage) => {
        setPage(newPage);
      };



    
    useEffect(() => {
        dispatch(fetchLearns({ page, limit }));
      }, [dispatch,page]);



    return(

<>
<div>
      <form onSubmit={handleSearch} className={styles.searchForm}>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search for learns..."
          className={styles.searchInput}
        />
        <button type="submit" className={styles.searchButton}>Search</button>
      </form>

      {searchStatus === 'loading' && <p>Loading...</p>}
      {searchStatus === 'failed' && <p>Error: {searchError}</p>}
      {searchStatus === 'succeeded' && (
        <div >
          {searchResults.length > 0 ? (
            searchResults.map((learn) => (
              <div key={learn._id} className={styles.container}>
                      



                      {learn.file && ( <LazyLoad height={200} offset={100} once>
                                            <video width="100%" controls>
                                                <source src={require(`../../../images/${learn.file.name}`).default} alt="Article" style={{ width: '100%', height: 'auto' }} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </LazyLoad>
                )}



                

<span>{learn.title}</span>
                  {learn.videoId && (
                 <LazyLoad height={200} offset={100} once>
                 <iframe
                     width="500"
                     height="315"
                     src={`https://www.youtube.com/embed/${learn.videoId}`}
                     title={learn.title}
                     frameBorder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                     allowFullScreen
                 ></iframe>
             </LazyLoad>
                )}
                                            

                      
              </div>
            ))
          ) : (
            <p>No learns found</p>
          )}
        </div>
      )}
    </div>










        
             {learns &&
          learns.map((learn) => (
            <div key={learn._id} className={styles.container}>
     
     {learn && learn.author ? (
          <PostHeader
            firstName={learn.author.firstName}  // Author's first name
            lastName={learn.author.lastName}    // Author's last name
            profilePicture={learn.author.profilePicture}  // Author's profile picture
            icon={<AiOutlineRead size={'1rem'} />}
            userId={learn.userId} 
            time={dayjs(learn.createdAt).fromNow()}
          />
        ) : (
          <div>Loading author details...</div>  
        )}

  
              
               
             
  {editingIssue === learn._id ? (
                <div>
                  <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
                  <button onClick={handleSaveEdit}>Save</button>
                </div>
              ) : (
                <div>
                   

                    
                  <div className={styles.content} dangerouslySetInnerHTML={{ __html: learn.text }} />

                  {learn.file && (
                    <LazyLoad height={200} offset={100} once>
                    <video width="100%" controls>
                        <source src={learn.file} alt="Article" style={{ width: '100%', height: 'auto' }} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </LazyLoad>
                )}






                  <span>{learn.title}</span>
                  {learn.videoId && (
                  <LazyLoad height={200} offset={100} once>
                  <iframe
                      width="500"
                      height="315"
                      src={`https://www.youtube.com/embed/${learn.videoId}`}
                      title={learn.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                  ></iframe>
              </LazyLoad>
                )}
                  
                  { learn.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
                    <>
                    
                      
                      <MdDeleteSweep onClick={() => handleDelete(learn._id)} size={'1rem'} />
                      <MdEdit  onClick={() => handleEdit(learn._id, learn.text)} size={'1rem'}  />
                      
                    </>
                  )}
  
                </div>
              )}
  
  
  
  
         
<div className={styles.commentSection}>
           
           {openComments[learn._id] &&
             learn.comments &&
             learn.comments.map((comment) => (
               <div key={comment._id} className={styles.comment}>
                 <div>
                 <img
                        src={
                          comment.commentAuthor.profilePicture ||
                          'https://randomuser.me/api/portraits/men/28.jpg'
                        }
                        onClick={() =>
                          handleProfileClick(comment.commentAuthor.userId)
                        }
                        alt={`${comment.commentAuthor.firstName}'s Profile`}
                        style={{
                          borderRadius: '100%',
                          width: '2.9rem',
                          height: '2.9rem',
                          cursor: 'pointer', // Makes it clear that it's clickable
                        }}
                      />
                 </div>
                 <div
                   style={{
                     marginLeft: '40px',
                     backgroundColor: '#e6e6e6',
                     borderRadius: '10px',
                     padding: '10px',
                   }}
                 >
                   <div>
                   <strong>{comment.commentAuthor?.firstName} {comment.commentAuthor?.lastName}</strong>
                   <span style={{ marginLeft: '10px', color: '#888' }}>
                      {/* Display the relative time ago for the comment */}
                      {dayjs(comment.added).fromNow()}
                    </span>
                 
                 
                   </div>
               
                   <div>{formatMentions(comment.text)}</div>
                   {(comment.userId === profile.userId || learn.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
                                  <>
                                     {comment.userId === profile.userId ? (
                                       <>
                                         
                                           <RiPencilLine    onClick={() => handleEditComment(comment._id, comment.text)}
                                           className={styles.editButton} />
                                        
                                         
                                         
                                           <RiDeleteBin5Line   onClick={() => handleDeleteComment(learn._id, comment._id)}
                                           className={styles.deleteButton} />
                                        
                                       </>
                                     ) : (
                                   <RiDeleteBin5Line    onClick={() => handleDeleteComment(learn._id, comment._id)}
                                         className={styles.deleteButton} />
                                       
                                     )}
                                   </>
                                 )}
           
           
           
           
           
           
                   <AiFillLike size="1rem" onClick={() => handleLikeComment(learn._id, comment._id)} />
                   <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
                  
                  
                  
                   <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
                
                   
                   
                   
                  
                   
             </div>
           
           
           
              
           

           
           
           {editingCommentId && (
                         <div>
                           <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
                           <button onClick={() => handleSaveEditComment(learn._id, editingCommentId)}>Save</button>
                         </div>
                       )}
           
           
               </div>
             ))
           }
           
           {openComments[learn._id] && (
                           <div className={styles.addComment}>
                                                              <MentionsInput
  value={commentText}
  onChange={(e) => setCommentText(e.target.value)}
  placeholder="Add a comment..."
>
  <Mention
    trigger="@"
    data={users}
    displayTransform={(id, display) => `@${display}`}  // Format to `@[displayName](userId)`
  />
</MentionsInput>
                             <button onClick={(e) => handleCommentSubmit(learn._id, e)} className={styles.commentButton}>
                               <AiOutlineComment size="1rem" />
                               <span>Comment</span>
                             </button>
                           </div>
                         )}
           
           
                       </div>











  
 
  
  
  
  

  
  
              <div style={{ display: 'flex' }}>
                <div className={styles.intraction}>
                  
                  <div className={styles.icons}>
                    <AiOutlineLike size="1rem"  onClick={() => handleLike(learn._id)} />
                    <span style={{ color: '#11a8e1' }}>{learn.likes}</span>
               
                  </div>

                  <div className={styles.icons}>
                <GrFormViewHide   onClick={() => handleOpenArticleModal(learn.likedBy)}/>
                  <span style={{ color: '#11a8e1' }}  >{learn.like?.length}</span>
                </div>

                  <div className={styles.icons}>
                  <BsChatDots onClick={() => handleToggleComments(learn._id)}  />
                  <span>{learn.comments?.length}</span>
                  </div>
                  <div className={styles.icons}>
                    <IoMdRepeat  size="1rem"   onClick={() => handleShare(learn._id)}      />
                    <span style={{ color: '#11a8e1' }}>{learn.shares}</span>
                  </div>
                  <div className={styles.icons}>
                    <RiBookmarkLine size="1rem" />
                  </div>
                </div>
                {/* <div className={styles.icons}>
                  <IoMdMore size="1rem" />
                </div> */}
              </div>


       




            </div>







    
    ))}
      {isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
      {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}
      <div className={styles.pagination}>
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? styles.active : ''}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
      
      </>    
        
    )
}

export default MediaTl






// import React, { useEffect, useState } from 'react';
// import PostHeader from '../postHeader'
// // import styles from './media.module.css'
// import styles from '../blog/blog.module.css';
// import {RiBookmarkLine,RiSuitcaseLine} from 'react-icons/ri'
// import {BsChatDots} from 'react-icons/bs'
// import {AiOutlineLike} from 'react-icons/ai'
// import {IoIosCheckmarkCircleOutline,IoMdRepeat,IoMdMore} from 'react-icons/io'
// import { IoEyeSharp } from "react-icons/io5";
// import { useSelector, useDispatch } from 'react-redux';
// import { MdEdit } from "react-icons/md";
// import { MdDeleteSweep } from "react-icons/md";
// import { io } from 'socket.io-client';
// import { AiOutlineRead, AiFillLike ,AiOutlineComment} from 'react-icons/ai';
// import {socket , connectSocket, emitEvent} from "../../../Socket/socket"
// import { fetchNotifications } from '../../../Redux/notificationSlice';
// import { commentLearn, deleteLearn, fetchLearns ,likeLearn, updateLearn,likeComment,deleteComment,editComment,shareLearn,searchLearns} from '../../../Redux/learnSlice';
// import { RiDeleteBin5Line, RiPencilLine} from 'react-icons/ri';
// import ArticleLikesModal from '../blog/articleLikeModel';
// import Modal from './modal';


// const MediaTl= ()=>{
//     const dispatch = useDispatch();
//     // const learns = useSelector((state) => state.learns.learns);
//     const [searchQuery, setSearchQuery] = useState('');
//     const { learns, status, totalPages, currentPage , searchResults, searchStatus, searchError} = useSelector((state) => state.learns);
//     const profile = useSelector((state) => state.profiles.profiles);
//     const [editingIssue, setEditingIssue] = useState(null);
//     const [editedText, setEditedText] = useState('');
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
//     const [likedUsers, setLikedUsers] = useState([])
//     const [openComments, setOpenComments] = useState({});
//     const [commentText, setCommentText] = useState('');
//     const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
//     const [editedCommentText, setEditedCommentText] = useState('');
//     const [page, setPage] = useState(1);
   
//     const limit = 1000; 
//     const userId = profile.userId; 
//     console.log(learns)

//     useEffect(() => {
//         dispatch(fetchNotifications(userId));
      
//         const socket = io('http://localhost:7000'); // Backend URL
//         socket.emit('join', userId);
      
//         socket.on('notification', (newNotification) => {
//           // Fetch notifications again or update state to include new notification
//           dispatch(fetchNotifications(userId));
//         });
      
//         return () => {
//           socket.disconnect();
//         };
//       }, [dispatch, userId]);  



//       const handleDelete = (learnId) => {
//         const isConfirmed = window.confirm('Are you sure you want to delete this post?');
//         if (!isConfirmed) {
//           return;
//         }
//         // Dispatch the delete comment action
//         dispatch(deleteLearn(learnId))
//           .then(() => {
//             // Optional: Show a success message or update the UI
//             console.log('deleted successfully');
//             dispatch(fetchLearns()); // Refresh articles after deletion
//           })
//           .catch((error) => {
//             // Optional: Handle error if comment deletion fails
//             console.error('Error deleting:', error);
//           });
//       };




//       const handleSearch = (e) => {
//         e.preventDefault();
//         dispatch(searchLearns(searchQuery));
//       };



//       const handleSaveEdit = () => {
//         // dispatch(updateIssue({ id: editingIssue, issueData: { text: editedText } }))
//         dispatch(updateLearn({ id: editingIssue, learnData: { text: editedText } }))
//           .then(() => {
//             alert('Post edited successfully!');
//             setEditingIssue(null);
//             setEditedText('');
//             dispatch(fetchLearns());
//           })
//           .catch((error) => {
//             alert('Error editing post: ' + error.message);
//           });
//       };
    
    
//       const handleEdit = (learnId, initialText) => {
//         setEditingIssue(learnId);
//         setEditedText(initialText);
//       };


//       const handleTextChange = (value) => {
//         setEditedText(value);
//       };









//       const handleLike = (learnId) => {
//         const userId = profile.userId
//        socket.emit('notification', { learnId, userId });
//        dispatch(likeLearn({ learnId }));
//        dispatch(fetchLearns());
//      };
   


     
//   const handleOpenModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setLikedUsers([]);
//   };

//   const handleOpenArticleModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalOpen(true);
//   };



//      const handleArticleCloseModal = () => {
//         setIsModalArticleOpen(false);
//         setLikedUsers([]);
//       };
    


//       const handleToggleComments = (learnId) => {
//         setOpenComments((prevState) => ({
//           ...prevState,
//           [learnId]: !prevState[learnId],
//         }));
//       };





//       const handleCommentSubmit = (learnId, e) => {
//         const userId = profile.userId
//         socket.emit('notification', { learnId, userId });
//         e.preventDefault(); // Prevent page refresh
//         if (commentText.trim() !== '') {
//           dispatch(commentLearn({ learnId, text: commentText }))
//             .then(() => {
//               setCommentText('');
//               dispatch(fetchLearns()); // Fetch articles again to get updated comments
//             })
//             .catch((error) => {
//               console.error('Error submitting comment:', error);
//             });
//         } else {
//           console.error('Comment text cannot be empty');
//         }
//       };





//       const handleLikeComment = (learnId, commentId) => {
//         socket.emit('notification', { learnId, userId });
//         dispatch(likeComment({ learnId, commentId }));
    
//       };





      

//       const handleDeleteComment = (learnId, commentId) => {
//         console.log(learnId);
//         console.log(commentId);
//         // Confirm deletion
//         const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
//         if (!isConfirmed) {
//           return;
//         }
//         // Dispatch the delete comment action
//         dispatch(deleteComment({ learnId, commentId }))
//           .then(() => {
//             // Optional: Show a success message or update the UI
//             console.log('Comment deleted successfully');
//             dispatch(fetchLearns()); // Refresh articles after deletion
//           })
//           .catch((error) => {
//             // Optional: Handle error if comment deletion fails
//             console.error('Error deleting comment:', error);
//           });
//       };
    








//       const handleSaveEditComment = (learnId, commentId) => {
//         dispatch(editComment({ learnId, commentId, newText: editedCommentText }))
//           .then(() => {
//             alert('Comment edited successfully!');
//             setEditingCommentId(null);
//             setEditedCommentText('');
//             dispatch(fetchLearns());
//           })
//           .catch((error) => {
//             alert('Error editing comment: ' + error.message);
//           });
//       };
  
  
  
  
//     const handleEditComment = (commentId, initialText) => {
//       setEditingCommentId(commentId);
//       setEditedCommentText(initialText);
//     };
  
//     const handleTextChangeComment = (value) => {
//       setEditedCommentText(value);
//     };







//     const handleShare = (learnId) => {
//         const userId = profile.userId; // Get the user ID from the profile
//         socket.emit('notification', { learnId, userId });
//         dispatch(shareLearn({ learnId, userId }));
//         dispatch(fetchLearns());
//       };
    



//       const handlePageChange = (newPage) => {
//         setPage(newPage);
//       };



    
//     useEffect(() => {
//         dispatch(fetchLearns({ page, limit }));
//       }, [dispatch,page]);



//     return(

// <>
// <div>
//       <form onSubmit={handleSearch} className={styles.searchForm}>
//         <input
//           type="text"
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//           placeholder="Search for learns..."
//           className={styles.searchInput}
//         />
//         <button type="submit" className={styles.searchButton}>Search</button>
//       </form>

//       {searchStatus === 'loading' && <p>Loading...</p>}
//       {searchStatus === 'failed' && <p>Error: {searchError}</p>}
//       {searchStatus === 'succeeded' && (
//         <div >
//           {searchResults.length > 0 ? (
//             searchResults.map((learn) => (
//               <div key={learn._id} className={styles.container}>
                      



//                       {learn.file && (
//                       <video width="100%" controls>
//                       <source src={require(`../../../images/${learn.file.name}`).default} alt="Article" style={{ width: '100%', height: 'auto' }} type="video/mp4" />
//                       Your browser does not support the video tag.
//                   </video>
//                 )}



                

// <span>{learn.title}</span>
//                   {learn.videoId && (
//                   <iframe
//                    loading="lazy"
//                     width="500"
//                     height="315"
//                     src={`https://www.youtube.com/embed/${learn.videoId}`}
//                     title={learn.title}
//                     frameBorder="0"
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                     allowFullScreen
//                   ></iframe>
//                 )}
                                            

                      
//               </div>
//             ))
//           ) : (
//             <p>No learns found</p>
//           )}
//         </div>
//       )}
//     </div>










//         <div>
//              {learns &&
//           learns.map((learn) => (
//             <div key={learn._id} className={styles.container}>
//               <PostHeader     firstName={learn.firstName} 
//                               lastName={learn.lastName}    icon={<AiOutlineRead size={'1rem'} />} />
  
              
               
             
//   {editingIssue === learn._id ? (
//                 <div>
//                   <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
//                   <button onClick={handleSaveEdit}>Save</button>
//                 </div>
//               ) : (
//                 <div>
                   

                    
//                   <div className={styles.content} dangerouslySetInnerHTML={{ __html: learn.text }} />

//                   {learn.file && (
//                       <video width="100%" controls>
//                       <source src={require(`../../../images/${learn.file.name}`).default} alt="Article" style={{ width: '100%', height: 'auto' }} type="video/mp4" />
//                       Your browser does not support the video tag.
//                   </video>
//                 )}






//                   <span>{learn.title}</span>
//                   {learn.videoId && (
//                   <iframe
//                    loading="lazy"
//                     width="500"
//                     height="315"
//                     src={`https://www.youtube.com/embed/${learn.videoId}`}
//                     title={learn.title}
//                     frameBorder="0"
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                     allowFullScreen
//                   ></iframe>
//                 )}
                  
//                   { learn.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
//                     <>
                    
                      
//                       <MdDeleteSweep onClick={() => handleDelete(learn._id)} size={'1rem'} />
//                       <MdEdit  onClick={() => handleEdit(learn._id, learn.text)} size={'1rem'}  />
                      
//                     </>
//                   )}
  
//                 </div>
//               )}
  
  
  
  
         
// <div className={styles.commentSection}>
           
//            {openComments[learn._id] &&
//              learn.comments &&
//              learn.comments.map((comment) => (
//                <div key={comment._id} className={styles.comment}>
//                  <div>
//                    <img
//                      alt="profile picture"
//                      src="https://randomuser.me/api/portraits/men/28.jpg"
//                      style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
//                    />
//                  </div>
//                  <div
//                    style={{
//                      marginLeft: '40px',
//                      backgroundColor: '#e6e6e6',
//                      borderRadius: '10px',
//                      padding: '10px',
//                    }}
//                  >
//                    <div>
//                      <strong>{comment.firstName} {comment.lastName}</strong>
//                    </div>
//                    <div>{comment.text}</div>
                   
//                    {(comment.userId === profile.userId || learn.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
//                                   <>
//                                      {comment.userId === profile.userId ? (
//                                        <>
                                         
//                                            <RiPencilLine    onClick={() => handleEditComment(comment._id, comment.text)}
//                                            className={styles.editButton} />
                                        
                                         
                                         
//                                            <RiDeleteBin5Line   onClick={() => handleDeleteComment(learn._id, comment._id)}
//                                            className={styles.deleteButton} />
                                        
//                                        </>
//                                      ) : (
//                                    <RiDeleteBin5Line    onClick={() => handleDeleteComment(learn._id, comment._id)}
//                                          className={styles.deleteButton} />
                                       
//                                      )}
//                                    </>
//                                  )}
           
           
           
           
           
           
//                    <AiFillLike size="1rem" onClick={() => handleLikeComment(learn._id, comment._id)} />
//                    <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
                  
                  
                  
//                    <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
                
                   
                   
                   
                  
                   
//              </div>
           
           
           
              
           

           
           
//            {editingCommentId && (
//                          <div>
//                            <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
//                            <button onClick={() => handleSaveEditComment(learn._id, editingCommentId)}>Save</button>
//                          </div>
//                        )}
           
           
//                </div>
//              ))
//            }
           
//            {openComments[learn._id] && (
//                            <div className={styles.addComment}>
//                              <input
//                                type="text"
//                                value={commentText}
//                                onChange={(e) => setCommentText(e.target.value)}
//                                placeholder="Add a comment..."
//                              />
//                              <button onClick={(e) => handleCommentSubmit(learn._id, e)} className={styles.commentButton}>
//                                <AiOutlineComment size="1rem" />
//                                <span>Comment</span>
//                              </button>
//                            </div>
//                          )}
           
           
//                        </div>











  
 
  
  
  
  

  
  
//               <div style={{ display: 'flex' }}>
//                 <div className={styles.intraction}>
//                   <div className={styles.icons}>
//                     <AiFillLike size="1rem"  onClick={() => handleLike(learn._id)} />
//                     <span style={{ color: '#11a8e1' }}>{learn.likes}</span>
//                     <IoEyeSharp onClick={() => handleOpenArticleModal(learn.likedBy)} />
                  
//                   </div>
//                   <div className={styles.icons}>
//                   <BsChatDots onClick={() => handleToggleComments(learn._id)}  />
//                   <span>{learn.comments?.length}</span>
//                   </div>
//                   <div className={styles.icons}>
//                     <IoMdRepeat  size="1rem"   onClick={() => handleShare(learn._id)}      />
//                     {/* <span style={{ color: '#11a8e1' }}>{issue.shares}</span> */}
//                   </div>
//                   <div className={styles.icons}>
//                     <RiBookmarkLine size="1rem" />
//                   </div>
//                 </div>
//                 <div className={styles.icons}>
//                   <IoMdMore size="1rem" />
//                 </div>
//               </div>


       




//             </div>







    
//     ))}
//       {isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
//       {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}
//       <div className={styles.pagination}>
//         <button
//           onClick={() => handlePageChange(page - 1)}
//           disabled={page === 1}
//         >
//           Previous
//         </button>
//         {Array.from({ length: totalPages }, (_, index) => (
//           <button
//             key={index}
//             onClick={() => handlePageChange(index + 1)}
//             className={index + 1 === currentPage ? styles.active : ''}
//           >
//             {index + 1}
//           </button>
//         ))}
//         <button
//           onClick={() => handlePageChange(page + 1)}
//           disabled={page === totalPages}
//         >
//           Next
//         </button>
//       </div>
//       </div>
//       </>    
        
//     )
// }

// export default MediaTl







// import React, { useEffect, useState } from 'react';
// import PostHeader from '../postHeader'
// // import styles from './media.module.css'
// import styles from '../blog/blog.module.css';
// import {RiBookmarkLine,RiSuitcaseLine} from 'react-icons/ri'
// import {BsChatDots} from 'react-icons/bs'
// import {AiOutlineLike} from 'react-icons/ai'
// import {IoIosCheckmarkCircleOutline,IoMdRepeat,IoMdMore} from 'react-icons/io'
// import { IoEyeSharp } from "react-icons/io5";
// import { useSelector, useDispatch } from 'react-redux';
// import { MdEdit } from "react-icons/md";
// import { MdDeleteSweep } from "react-icons/md";
// import { io } from 'socket.io-client';
// import { AiOutlineRead, AiFillLike ,AiOutlineComment} from 'react-icons/ai';
// import {socket , connectSocket, emitEvent} from "../../../Socket/socket"
// import { fetchNotifications } from '../../../Redux/notificationSlice';
// import { commentLearn, deleteLearn, fetchLearns ,likeLearn, updateLearn,likeComment,deleteComment,editComment,shareLearn,searchLearns} from '../../../Redux/learnSlice';
// import { RiDeleteBin5Line, RiPencilLine} from 'react-icons/ri';
// import ArticleLikesModal from '../blog/articleLikeModel';
// import Modal from './modal';


// const MediaTl= ()=>{
//     const dispatch = useDispatch();
//     // const learns = useSelector((state) => state.learns.learns);
//     const [searchQuery, setSearchQuery] = useState('');
//     const { learns, status, totalPages, currentPage , searchResults, searchStatus, searchError} = useSelector((state) => state.learns);
//     const profile = useSelector((state) => state.profiles.profiles);
//     const [editingIssue, setEditingIssue] = useState(null);
//     const [editedText, setEditedText] = useState('');
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [isModalArticleOpen, setIsModalArticleOpen] = useState(false);
//     const [likedUsers, setLikedUsers] = useState([])
//     const [openComments, setOpenComments] = useState({});
//     const [commentText, setCommentText] = useState('');
//     const [editingCommentId, setEditingCommentId] = useState(null); // State to track the comment being edited
//     const [editedCommentText, setEditedCommentText] = useState('');
//     const [page, setPage] = useState(1);
   
//     const limit = 1000; 
//     const userId = profile.userId; 
//     console.log(learns)

//     useEffect(() => {
//         dispatch(fetchNotifications(userId));
      
//         const socket = io('http://localhost:7000'); // Backend URL
//         socket.emit('join', userId);
      
//         socket.on('notification', (newNotification) => {
//           // Fetch notifications again or update state to include new notification
//           dispatch(fetchNotifications(userId));
//         });
      
//         return () => {
//           socket.disconnect();
//         };
//       }, [dispatch, userId]);  



//       const handleDelete = (learnId) => {
//         const isConfirmed = window.confirm('Are you sure you want to delete this post?');
//         if (!isConfirmed) {
//           return;
//         }
//         // Dispatch the delete comment action
//         dispatch(deleteLearn(learnId))
//           .then(() => {
//             // Optional: Show a success message or update the UI
//             console.log('deleted successfully');
//             dispatch(fetchLearns()); // Refresh articles after deletion
//           })
//           .catch((error) => {
//             // Optional: Handle error if comment deletion fails
//             console.error('Error deleting:', error);
//           });
//       };




//       const handleSearch = (e) => {
//         e.preventDefault();
//         dispatch(searchLearns(searchQuery));
//       };



//       const handleSaveEdit = () => {
//         // dispatch(updateIssue({ id: editingIssue, issueData: { text: editedText } }))
//         dispatch(updateLearn({ id: editingIssue, learnData: { text: editedText } }))
//           .then(() => {
//             alert('Post edited successfully!');
//             setEditingIssue(null);
//             setEditedText('');
//             dispatch(fetchLearns());
//           })
//           .catch((error) => {
//             alert('Error editing post: ' + error.message);
//           });
//       };
    
    
//       const handleEdit = (learnId, initialText) => {
//         setEditingIssue(learnId);
//         setEditedText(initialText);
//       };


//       const handleTextChange = (value) => {
//         setEditedText(value);
//       };









//       const handleLike = (learnId) => {
//         const userId = profile.userId
//        socket.emit('notification', { learnId, userId });
//        dispatch(likeLearn({ learnId }));
//        dispatch(fetchLearns());
//      };
   


     
//   const handleOpenModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setLikedUsers([]);
//   };

//   const handleOpenArticleModal = (likedBy) => {
//     setLikedUsers(likedBy);
//     setIsModalOpen(true);
//   };



//      const handleArticleCloseModal = () => {
//         setIsModalArticleOpen(false);
//         setLikedUsers([]);
//       };
    


//       const handleToggleComments = (learnId) => {
//         setOpenComments((prevState) => ({
//           ...prevState,
//           [learnId]: !prevState[learnId],
//         }));
//       };





//       const handleCommentSubmit = (learnId, e) => {
//         const userId = profile.userId
//         socket.emit('notification', { learnId, userId });
//         e.preventDefault(); // Prevent page refresh
//         if (commentText.trim() !== '') {
//           dispatch(commentLearn({ learnId, text: commentText }))
//             .then(() => {
//               setCommentText('');
//               dispatch(fetchLearns()); // Fetch articles again to get updated comments
//             })
//             .catch((error) => {
//               console.error('Error submitting comment:', error);
//             });
//         } else {
//           console.error('Comment text cannot be empty');
//         }
//       };





//       const handleLikeComment = (learnId, commentId) => {
//         socket.emit('notification', { learnId, userId });
//         dispatch(likeComment({ learnId, commentId }));
    
//       };





      

//       const handleDeleteComment = (learnId, commentId) => {
//         console.log(learnId);
//         console.log(commentId);
//         // Confirm deletion
//         const isConfirmed = window.confirm('Are you sure you want to delete this comment?');
//         if (!isConfirmed) {
//           return;
//         }
//         // Dispatch the delete comment action
//         dispatch(deleteComment({ learnId, commentId }))
//           .then(() => {
//             // Optional: Show a success message or update the UI
//             console.log('Comment deleted successfully');
//             dispatch(fetchLearns()); // Refresh articles after deletion
//           })
//           .catch((error) => {
//             // Optional: Handle error if comment deletion fails
//             console.error('Error deleting comment:', error);
//           });
//       };
    








//       const handleSaveEditComment = (learnId, commentId) => {
//         dispatch(editComment({ learnId, commentId, newText: editedCommentText }))
//           .then(() => {
//             alert('Comment edited successfully!');
//             setEditingCommentId(null);
//             setEditedCommentText('');
//             dispatch(fetchLearns());
//           })
//           .catch((error) => {
//             alert('Error editing comment: ' + error.message);
//           });
//       };
  
  
  
  
//     const handleEditComment = (commentId, initialText) => {
//       setEditingCommentId(commentId);
//       setEditedCommentText(initialText);
//     };
  
//     const handleTextChangeComment = (value) => {
//       setEditedCommentText(value);
//     };







//     const handleShare = (learnId) => {
//         const userId = profile.userId; // Get the user ID from the profile
//         socket.emit('notification', { learnId, userId });
//         dispatch(shareLearn({ learnId, userId }));
//         dispatch(fetchLearns());
//       };
    



//       const handlePageChange = (newPage) => {
//         setPage(newPage);
//       };



    
//     useEffect(() => {
//         dispatch(fetchLearns({ page, limit }));
//       }, [dispatch,page]);



//     return(

// <>
// <div className={styles.container}>
//       <form onSubmit={handleSearch} className={styles.searchForm}>
//         <input
//           type="text"
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//           placeholder="Search for learns..."
//           className={styles.searchInput}
//         />
//         <button type="submit" className={styles.searchButton}>Search</button>
//       </form>

//       {searchStatus === 'loading' && <p>Loading...</p>}
//       {searchStatus === 'failed' && <p>Error: {searchError}</p>}
//       {searchStatus === 'succeeded' && (
//         <div className={styles.resultsContainer}>
//           {searchResults.length > 0 ? (
//             searchResults.map((learn) => (
//               <div key={learn._id} className={styles.learnCard}>
                      



//                       {learn.file && (
//                       <video width="100%" controls>
//                       <source src={require(`../../../images/${learn.file.name}`).default} alt="Article" style={{ width: '100%', height: 'auto' }} type="video/mp4" />
//                       Your browser does not support the video tag.
//                   </video>
//                 )}



                

// <span>{learn.title}</span>
//                   {learn.videoId && (
//                   <iframe
//                     width="560"
//                     height="315"
//                     src={`https://www.youtube.com/embed/${learn.videoId}`}
//                     title={learn.title}
//                     frameBorder="0"
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                     allowFullScreen
//                   ></iframe>
//                 )}
                                            

                      
//               </div>
//             ))
//           ) : (
//             <p>No learns found</p>
//           )}
//         </div>
//       )}
//     </div>










//         <div className={styles.container}>
//              {learns &&
//           learns.map((learn) => (
//             <div key={learn._id}>
//               <PostHeader     firstName={learn.firstName} 
//                               lastName={learn.lastName}    icon={<AiOutlineRead size={'1rem'} />} />
  
              
               
             
//   {editingIssue === learn._id ? (
//                 <div>
//                   <textarea value={editedText} onChange={(e) => handleTextChange(e.target.value)} />
//                   <button onClick={handleSaveEdit}>Save</button>
//                 </div>
//               ) : (
//                 <div>
                   

                    
//                   <div className={styles.content} dangerouslySetInnerHTML={{ __html: learn.text }} />

//                   {learn.file && (
//                       <video width="100%" controls>
//                       <source src={require(`../../../images/${learn.file.name}`).default} alt="Article" style={{ width: '100%', height: 'auto' }} type="video/mp4" />
//                       Your browser does not support the video tag.
//                   </video>
//                 )}






//                   <span>{learn.title}</span>
//                   {learn.videoId && (
//                   <iframe
//                     width="560"
//                     height="315"
//                     src={`https://www.youtube.com/embed/${learn.videoId}`}
//                     title={learn.title}
//                     frameBorder="0"
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                     allowFullScreen
//                   ></iframe>
//                 )}
                  
//                   { learn.userId === profile.userId && ( // Only show edit and delete buttons if the user is the author
//                     <>
                    
                      
//                       <MdDeleteSweep onClick={() => handleDelete(learn._id)} size={'1rem'} />
//                       <MdEdit  onClick={() => handleEdit(learn._id, learn.text)} size={'1rem'}  />
                      
//                     </>
//                   )}
  
//                 </div>
//               )}
  
  
  
  
         
// <div className={styles.commentSection}>
           
//            {openComments[learn._id] &&
//              learn.comments &&
//              learn.comments.map((comment) => (
//                <div key={comment._id} className={styles.comment}>
//                  <div>
//                    <img
//                      alt="profile picture"
//                      src="https://randomuser.me/api/portraits/men/28.jpg"
//                      style={{ width: '42px', borderRadius: '42px', marginTop: '10px' }}
//                    />
//                  </div>
//                  <div
//                    style={{
//                      marginLeft: '40px',
//                      backgroundColor: '#e6e6e6',
//                      borderRadius: '10px',
//                      padding: '10px',
//                    }}
//                  >
//                    <div>
//                      <strong>{comment.firstName} {comment.lastName}</strong>
//                    </div>
//                    <div>{comment.text}</div>
                   
//                    {(comment.userId === profile.userId || learn.userId === profile.userId) && ( // Allow comment delete for comment owner or post owner
//                                   <>
//                                      {comment.userId === profile.userId ? (
//                                        <>
                                         
//                                            <RiPencilLine    onClick={() => handleEditComment(comment._id, comment.text)}
//                                            className={styles.editButton} />
                                        
                                         
                                         
//                                            <RiDeleteBin5Line   onClick={() => handleDeleteComment(learn._id, comment._id)}
//                                            className={styles.deleteButton} />
                                        
//                                        </>
//                                      ) : (
//                                    <RiDeleteBin5Line    onClick={() => handleDeleteComment(learn._id, comment._id)}
//                                          className={styles.deleteButton} />
                                       
//                                      )}
//                                    </>
//                                  )}
           
           
           
           
           
           
//                    <AiFillLike size="1rem" onClick={() => handleLikeComment(learn._id, comment._id)} />
//                    <span style={{ color: '#11a8e1' }}>{comment.likes}</span>
                  
                  
                  
//                    <button onClick={() => handleOpenModal(comment.likedBy)}>View Likes</button>
                
                   
                   
                   
                  
                   
//              </div>
           
           
           
              
           

           
           
//            {editingCommentId && (
//                          <div>
//                            <textarea value={editedCommentText} onChange={(e) => handleTextChangeComment(e.target.value)} />
//                            <button onClick={() => handleSaveEditComment(learn._id, editingCommentId)}>Save</button>
//                          </div>
//                        )}
           
           
//                </div>
//              ))
//            }
           
//            {openComments[learn._id] && (
//                            <div className={styles.addComment}>
//                              <input
//                                type="text"
//                                value={commentText}
//                                onChange={(e) => setCommentText(e.target.value)}
//                                placeholder="Add a comment..."
//                              />
//                              <button onClick={(e) => handleCommentSubmit(learn._id, e)} className={styles.commentButton}>
//                                <AiOutlineComment size="1rem" />
//                                <span>Comment</span>
//                              </button>
//                            </div>
//                          )}
           
           
//                        </div>











  
 
  
  
  
  

  
  
//               <div style={{ display: 'flex' }}>
//                 <div className={styles.intraction}>
//                   <div className={styles.icons}>
//                     <AiFillLike size="1rem"  onClick={() => handleLike(learn._id)} />
//                     <span style={{ color: '#11a8e1' }}>{learn.likes}</span>
//                     <IoEyeSharp onClick={() => handleOpenArticleModal(learn.likedBy)} />
                  
//                   </div>
//                   <div className={styles.icons}>
//                   <BsChatDots onClick={() => handleToggleComments(learn._id)}  />
//                   <span>{learn.comments?.length}</span>
//                   </div>
//                   <div className={styles.icons}>
//                     <IoMdRepeat  size="1rem"   onClick={() => handleShare(learn._id)}      />
//                     {/* <span style={{ color: '#11a8e1' }}>{issue.shares}</span> */}
//                   </div>
//                   <div className={styles.icons}>
//                     <RiBookmarkLine size="1rem" />
//                   </div>
//                 </div>
//                 <div className={styles.icons}>
//                   <IoMdMore size="1rem" />
//                 </div>
//               </div>


       




//             </div>







    
//     ))}
//       {isModalArticleOpen && <ArticleLikesModal likedUsers={likedUsers} onClose={handleArticleCloseModal} />}
//       {isModalOpen && <Modal likedUsers={likedUsers} onClose={handleCloseModal} />}
//       <div className={styles.pagination}>
//         <button
//           onClick={() => handlePageChange(page - 1)}
//           disabled={page === 1}
//         >
//           Previous
//         </button>
//         {Array.from({ length: totalPages }, (_, index) => (
//           <button
//             key={index}
//             onClick={() => handlePageChange(index + 1)}
//             className={index + 1 === currentPage ? styles.active : ''}
//           >
//             {index + 1}
//           </button>
//         ))}
//         <button
//           onClick={() => handlePageChange(page + 1)}
//           disabled={page === totalPages}
//         >
//           Next
//         </button>
//       </div>
//       </div>
//       </>    
        
//     )
// }

// export default MediaTl